<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
  >
    <path
      id="noun-record-1072562"
      d="M600,60C302.4,60,60,302.4,60,600s242.4,540,540,540,540-242.4,540-540S897.6,60,600,60Zm0,984c-244.8,0-444-199.2-444-444S355.2,156,600,156s444,199.2,444,444S844.8,1044,600,1044ZM804,600A204,204,0,1,1,600,396,203.828,203.828,0,0,1,804,600Z"
      transform="translate(-60 -60)"
    />
  </svg>
</template>

<script>
export default {
  name: "RecordIcon",
};
</script>

<style scoped></style>
