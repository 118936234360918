<template>
  <div
    class="absolute bottom-1 left-2 z-10 flex items-center gap-1 rounded-md bg-gray-50 px-2"
    title="Changes by other users/devices will not be synced. Retrying in a moment ...">
    <span class="h-3 w-3 animate-pulse rounded-full bg-planning-red" />
    <span>Disconnected</span>
  </div>
</template>

<style scoped></style>
