<template>
  <div
    class="flex h-screen flex-col"
    :class="{ 'border-0 border-warning': bcEnvironment !== 'LIVE' }"
  >
    <div
      v-if="bcEnvironment !== 'LIVE'"
      class="w-full bg-warning text-center text-lg font-bold"
    >
      {{ bcEnvironment }}
    </div>
    <div
      v-if="
        userPersist.linkedResource &&
        userPersist.actualResource &&
        userPersist.linkedResource.no !== userPersist.actualResource.no
      "
      class="w-full bg-warning text-center text-lg font-bold"
    >
      {{ `Changed to other resource: ${userPersist.linkedResource.name}` }}
    </div>
    <PermissionOverlay v-if="idleStore.showOverlay">
      <h1>
        Allow access to 'device use' and 'notifications' to enable idle
        detection.
      </h1>
      <p>
        By clicking the Allow button in the popup on top, you allow the
        HR app to know when you are not interacting with the PC.
      </p>
      <p>
        By doing so, the HR app helps you to track time when you were away.
        This is only stored locally.
      </p>

      <h2 class="mt-4">
        No popup? Check the top right of the address bar for a blocked popup
        icon and allow
      </h2>
    </PermissionOverlay>
    <idleOverlay></idleOverlay>

    <notifications
      position="bottom right"
      classes="!bg-error rounded m-1 p-4 text-white"
    />

    <notifications
      group="info"
      position="bottom right"
      classes="!bg-primary rounded m-1 p-4 text-white"
    />

    <!-- Disclosure is a wrapper for toggling a DisclosurePanel using a DisclosureButton -->
    <TheMainMenu />

    <main
      class="relative flex flex-1 overflow-hidden"
      :class="{
        'md:p-4 lg:p-6': router?.currentRoute?.value?.path === '/open-tasks',
        'lg:p-2': router?.currentRoute?.value?.path === '/committed-tasks',
        'lg:p-4': router?.currentRoute?.value?.path === '/errors',
      }"
    >
      <div class="flex w-full" v-show="userStore.routerLoading">
        <div
          class="flex flex-grow items-center justify-center gap-3 p-4 text-center text-gray-500"
        >
          <spinner color="#CCC" :size="16" />
          <p>Loading ...</p>
        </div>
      </div>
      <router-view
        :key="`${bcPersist.selectedPlanningCompany}${userStore.updateKey}`"
      />
    </main>
    <reload-prompt />
    <websocket-prompt v-if="!userStore.wsConnected" />
  </div>
  <div
    class="absolute left-0 top-0 z-rotate-device hidden h-screen w-screen incompatible-screen:block"
  >
    <div
      class="flex h-full w-full flex-col items-center justify-center gap-2 rounded-lg bg-gray-200 p-16"
    >
      <RefreshIcon class="w-20 text-primary" />
      <h1 class="text-2xl font-normal text-gray-600">Please rotate</h1>
      <h2 class="mb-4 text-xl font-normal text-gray-600">
        or use a different device
      </h2>
    </div>
  </div>
</template>

<script setup>
import TheMainMenu from "@/components/TheMainMenu.vue";
import PermissionOverlay from "@/components/PermissionOverlay.vue";
import IdleOverlay from "@/components/IdleOverlay.vue";
import { bcEnvironment } from "@/../version.json";

import { RefreshIcon } from "@heroicons/vue/outline";
import { useIdleStore } from "@/stores/idle";
import { useBcPersistStore } from "@/stores/bcPersist.js";
import { useUserStore } from "@/stores/user.js";
import { onBeforeUnmount, onMounted } from "vue";
import { initUnloadWarning } from "@/utils/beforeUnloadWarning.js";
import ReloadPrompt from "@/components/ReloadPrompt.vue";
import { useUserPersistStore } from "./stores/userPersist";
import WebsocketPrompt from "@/components/WebsocketPrompt.vue";
import router from "./router/router";

const idleStore = useIdleStore();
const bcPersist = useBcPersistStore();
const userStore = useUserStore();
const userPersist = useUserPersistStore();

onMounted(() => {
  idleStore.initMessages();
  initUnloadWarning(userStore, bcPersist);
});
</script>
