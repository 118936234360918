<template>
  <div
    class="but-input input-time input-time--active"
    title="Stop recording to edit."
  >
    {{ secondsToFormattedTime(totalSeconds) }}
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed, onBeforeUnmount, ref } from "vue";
import { secondsToFormattedTime } from "@/utils/Utils.js";

const props = defineProps(["quantity", "beginTime"]);
const currentTime = ref(Date.now());

const timeInterval = setInterval(() => {
  currentTime.value = Date.now();
}, 1000);

const startTime = computed(() => {
  return new Date(props.beginTime).getTime();
});

const totalSeconds = computed(() => {
  return (currentTime.value - startTime.value) / 1000 + props.quantity * 3600;
});

onBeforeUnmount(() => {
  clearTimeout(timeInterval);
});
</script>
