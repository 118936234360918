<template>
  <teleport to="body">
    <div
      class="absolute left-0 top-0 z-user-menu h-screen w-screen bg-gray-transparent"
      @click="overlayPressed"
      @keydown="keyPressed"
      id="overlay"
      tabindex="1"
    >
      <div
        class="absolute right-1/2 top-1/2 z-10 z-user-menu w-full max-w-2xl -translate-y-1/2 translate-x-1/2 transform px-2"
      >
        <div
          class="relative z-user-menu overflow-hidden rounded-lg bg-gray-50 p-2 shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="absolute right-2 top-2 h-6 w-6 cursor-pointer">
            <XIcon
              class="text-gray-500 hover:text-primary"
              @click="emits('onClose')"
            />
          </div>
          <div class="flex w-full items-center justify-center px-10 pb-2 pt-2">
            <div class="relative flex w-fit flex-col items-center px-7">
              <ChevronDownIcon
                class="absolute right-0 top-0 m-1 h-5 w-5 flex-shrink-0 cursor-pointer hover:text-primary"
                :class="{ 'rotate-180': !showProjectInfo }"
                aria-hidden="true"
                v-if="
                  [
                    project.projectManager,
                    project.endingDate,
                    project.contactName1,
                    project.contactName1,
                  ].some((x) => x !== '' && x !== '0001-01-01')
                "
                @click="showProjectInfo = !showProjectInfo"
              />
              <div class="flex w-full items-center justify-center text-center">
                <CompanyFlagIcon
                  :company-guid="project.companyGuid"
                ></CompanyFlagIcon>
                <div class="pl-1">
                  {{ project.no ? project.no : project.jobNo }}
                </div>
                <div class="pl-2">
                  {{ project.billToName }}
                </div>
              </div>
              <div
                v-if="project.jobDescription"
                class="flex w-full items-center justify-center text-center"
                v-html="replaceBcBreaks(project.jobDescription)"
              ></div>
              <div class="flex items-center gap-2" v-if="showProjectInfo">
                <div class="w-fit">
                  <p v-if="project.projectManager">Project Manager:</p>
                  <p v-if="project.endingDate !== '0001-01-01'">Deadline:</p>
                  <p v-if="project.contactName1">Contact 1:</p>
                  <p v-if="project.contactName2">Contact 2:</p>
                </div>
                <div>
                  <p v-if="project.projectManager">
                    {{ project.projectManager }}
                  </p>
                  <p v-if="project.endingDate !== '0001-01-01'">
                    {{ formatIsoDate(project.endingDate) }}
                  </p>
                  <p v-if="project.contactName1">
                    {{ project.contactName1 }}
                  </p>
                  <p v-if="project.contactName2">
                    {{ project.contactName2 }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mb-4 flex w-full items-center border-b-1 border-gray-300 pt-2"
          >
            <div
              v-if="projectHasQuotedLines"
              @click="
                selectedTabIndex = 0;
                focusInputField();
              "
              :class="
                selectedTabIndex === 0 ? 'border-primary' : 'border-transparent'
              "
              class="w-1/2 cursor-pointer border-b-4 pb-2 text-center"
            >
              Quoted
            </div>
            <div
              @click="
                selectedTabIndex = 1;
                focusInputField();
              "
              :class="
                !(selectedTabIndex !== 1 && projectHasQuotedLines)
                  ? 'border-primary'
                  : 'border-transparent'
              "
              class="w-1/2 cursor-pointer border-b-4 pb-2 text-center"
            >
              Non-quoted
            </div>
          </div>
          <div
            v-if="!props.project?.jobPlanningLines"
            class="flex h-40 w-full items-center justify-center"
          >
            <Spinner :size="40" color="black" />
          </div>
          <div v-else class="flex h-10 w-full items-center bg-gray-50">
            <input
              class="but-input input-reset my-1 flex-grow py-1 text-sm"
              ref="inputField"
              :value="filterString"
              placeholder="Filter"
              @input="
                filterString = $event.target.value;
                selectedItemIndex = 0;
              "
              type="search"
            />
            <p class="mr-2 w-fit pl-2">Booked only</p>
            <bryntumCheckbox
              class="ml-2"
              color="b-orange"
              :value="String(onlyBooked)"
              @change="onlyBooked = $event.value"
            ></bryntumCheckbox>
          </div>
          <transition appear name="fade">
            <table
              v-if="project.hasOwnProperty('jobPlanningLines')"
              class="flex-no-wrap tasks flex flex-col divide-y divide-gray-300"
            >
              <thead class="table-header-group divide-y divide-gray-400">
                <tr
                  class="sticky top-0 z-data-table-header flex h-10 w-full bg-gray-100 text-xs"
                >
                  <th
                    class="relative flex-grow px-2 py-2 text-left font-normal text-gray-900"
                  >
                    Label
                  </th>
                  <th
                    class="relative w-24 px-2 py-2 text-right font-normal text-gray-900"
                  >
                    Booked
                  </th>
                  <th
                    v-if="selectedTabIndex === 0 && projectHasQuotedLines"
                    class="relative w-24 px-2 py-2 text-right font-normal text-gray-900"
                  >
                    Quoted
                  </th>
                </tr>
              </thead>
              <tbody
                ref="tableBody"
                class="max-h-128 w-full flex-1 divide-y divide-gray-200 overflow-y-auto bg-white"
              >
                <transition-group name="fade" appear>
                  <tr
                    v-for="(row, rowIndex) in displayedData"
                    class="stable-row bold group relative flex w-full"
                    :key="rowIndex"
                    :class="{
                      'arrowSelectedRow bg-primary-100':
                        rowIndex === selectedItemIndex,
                      'even:bg-gray-50': rowIndex !== selectedItemIndex,
                      'selectedRow border-l-4 !border-l-primary':
                        isSelected(row),
                      'collapsable hover:bg-primary-200': row.type !== 'Head',
                      'cursor-pointer':
                        row.type === 'Head' ||
                        (inTaskList && row.type !== 'Head'),
                      hidden: row.hidden,
                    }"
                  >
                    <td
                      v-if="!(projectHasQuotedLines && selectedTabIndex === 0)"
                      class="flex items-center justify-center"
                    >
                      <div class="ml-1 h-5 w-5" title="Favorite">
                        <StarIcon
                          class="cursor-pointer"
                          :class="
                            userPersist.userSettings.taskFavorites.includes(
                              row.no
                            )
                              ? 'text-bryntum group-hover:text-primary-600'
                              : 'text-transparent group-hover:text-gray-500'
                          "
                          @click.prevent="toggleFavorite($event, row.no)"
                        />
                      </div>
                    </td>
                    <td
                      class="flex flex-grow px-2 py-2 text-left text-gray-900"
                      :class="{
                        'font-bold': row.type === 'Head',
                        '!pl-8 font-normal': row.type === 'Item',
                      }"
                      @click="onLineClicked(row)"
                    >
                      <ChevronDownIcon
                        v-if="row.type === 'Head'"
                        class="h-5 w-5"
                        :class="
                          row.collapse
                            ? '-rotate-90 duration-300'
                            : 'rotate-0 duration-300'
                        "
                      />
                      {{ row.description || "No Description" }}
                      <!--                          {{ row.lineNo }}-->
                      <!--                        {{ selectedTask.jobPlanningLineLineNo }}-->
                    </td>
                    <td
                      class="w-24 flex-shrink-0 px-2 py-2 text-right"
                      :class="{
                        'text-valid':
                          row.workedTotal <= row.quantity &&
                          row.workedTotal !== 0,
                        'text-error': row.workedTotal > row.quantity,
                        'font-bold': row.type === 'Head',
                        'font-normal': row.type === 'Item',
                      }"
                      @click="onLineClicked(row)"
                    >
                      {{
                        row.type === "Head"
                          ? formatQuantity(row.workedTotal, "HOUR")
                          : formatQuantity(
                              row.workedTotal,
                              bcPersistStore.getBaseUnitFromTaskNo(
                                row.no,
                                props.project.companyGuid
                              )
                            )
                      }}
                    </td>
                    <td
                      v-if="selectedTabIndex === 0 && projectHasQuotedLines"
                      class="w-24 flex-shrink-0 px-2 py-2 text-right text-gray-900"
                      :class="{
                        'font-bold': row.type === 'Head',
                        'font-normal': row.type === 'Item',
                      }"
                      @click="onLineClicked(row)"
                    >
                      {{
                        row.type === "Head"
                          ? formatQuantity(row.quantity, "HOUR")
                          : formatQuantity(
                              row.quantity,
                              bcPersistStore.getBaseUnitFromTaskNo(
                                row.no,
                                props.project.companyGuid
                              )
                            )
                      }}
                    </td>
                  </tr>
                </transition-group>
              </tbody>
            </table>
          </transition>
          <div class="flex w-full flex-col border-t-1 px-2 pt-2">
            <div
              class="my-1 mr-4 w-full rounded-full bg-gray-300"
              v-if="selectedTabIndex === 0"
            >
              <div
                class="rounded-full p-0.5 text-center font-medium leading-none text-white"
                :style="`width: ${Math.min(projectCompletion, 100)}%`"
                :class="{
                  'bg-valid': projectCompletion < 80,
                  'bg-primary-600':
                    projectCompletion >= 80 && projectCompletion <= 100,
                  'bg-error': projectCompletion > 100,
                }"
              >
                {{ projectCompletion }}%
              </div>
            </div>
            <div class="flex">
              <span v-if="selectedTabIndex === 0" class="w-1/3 text-start"
                >{{ formatQuantity(quotedTotal.worked, "HOUR") }} worked</span
              >
              <span v-else class="w-full text-end"
                >{{ formatQuantity(notQuotedTotal, "HOUR") }} non-quoted
                worked</span
              >
              <span
                v-if="
                  selectedTabIndex === 0 &&
                  quotedTotal.total >= quotedTotal.worked
                "
                class="w-1/3 text-center"
                >{{
                  formatQuantity(quotedTotal.total - quotedTotal.worked, "HOUR")
                }}
                remaining</span
              >
              <span v-else-if="selectedTabIndex === 0" class="w-1/3 text-center"
                >{{
                  formatQuantity(
                    -(quotedTotal.total - quotedTotal.worked),
                    "HOUR"
                  )
                }}
                over quoted</span
              >
              <span v-if="selectedTabIndex === 0" class="w-1/3 text-end"
                >{{ formatQuantity(quotedTotal.total, "HOUR") }} quoted</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped lang="scss">
.tasks {
  height: 300px;
}
</style>

<script setup>
import { ChevronDownIcon, XIcon, StarIcon } from "@heroicons/vue/solid";
import { computed, ref, nextTick, watch, onMounted } from "vue";
import { useBcPersistStore } from "@/stores/bcPersist.js";
import { BryntumCheckbox } from "@bryntum/scheduler-vue-3";
import {
  formatQuantity,
  replaceBcBreaks,
  formatIsoDate,
} from "@/utils/Utils.js";
import { useUserPersistStore } from "@/stores/userPersist.js";

const bcPersistStore = useBcPersistStore();
const userPersist = useUserPersistStore();
const emits = defineEmits(["lineClicked", "onClose"]);
const props = defineProps({
  project: {
    type: Object,
  },
  inTaskList: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selectedTask: {
    type: Object,
  },
});

const selectedTabIndex = ref(0);
const filterString = ref("");
const onlyBooked = ref(false);
const showProjectInfo = ref(false);
const excludedActions = ["AC1000", "AC2000", "AC8000", "AC8500"]; //don't show these items

const projectHasQuotedLines = computed(() => {
  return quotedPlanningLines.value.length > 0;
});
const displayedData = computed(() => {
  if (selectedTabIndex.value === 0 && projectHasQuotedLines.value) {
    return quotedPlanningLines.value.filter((x) => {
      return onlyBooked.value === true
        ? (x.description
            .toLowerCase()
            .includes(filterString.value.toLowerCase()) &&
            x.workedTotal > 0) ||
            x.type === "Head"
        : x.description
            .toLowerCase()
            .includes(filterString.value.toLowerCase()) || x.type === "Head";
    });
  } else {
    return notQuotedPlanningLines.value.filter((x) => {
      // console.log("x", x);
      return onlyBooked.value === true
        ? x.description
            .toLowerCase()
            .includes(filterString.value.toLowerCase()) &&
            x.workedTotal > 0 &&
            !excludedActions.includes(x.no)
        : x.description
            .toLowerCase()
            .includes(filterString.value.toLowerCase()) &&
            !excludedActions.includes(x.no);
    });
  }
});

const projectCompletion = computed(() => {
  if (quotedTotal.value.worked && quotedTotal.value.total) {
    return Math.round(
      (quotedTotal.value.worked / quotedTotal.value.total) * 100
    );
  } else {
    return 0;
  }
});

const notQuotedTotal = computed(() => {
  let worked = 0;
  notQuotedPlanningLines.value.map((x) => {
    if (
      bcPersistStore.getBaseUnitFromTaskNo(x.no, props.project.companyGuid) ===
      "HOUR"
    ) {
      worked += x.workedTotal;
    }
  });
  return worked;
});

const quotedTotal = computed(() => {
  let total = 0;
  let worked = 0;
  quotedPlanningLines.value.map((x) => {
    if (
      bcPersistStore.getBaseUnitFromTaskNo(x.no, props.project.companyGuid) ===
        "HOUR" &&
      x.type !== "Head"
    ) {
      total += x.quantity;
      worked += x.workedTotal;
    }
  });
  return { total, worked };
});

const quotedPlanningLines = computed(() => {
  return planningLines.value.filter((x) => x.jobTaskNo !== "NOTQ");
});
const notQuotedPlanningLines = computed(() => {
  const fullList = bcPersistStore.itemsForCompany(props.project.companyGuid);
  const nonQList = planningLines.value.filter((x) => x.jobTaskNo === "NOTQ");
  for (let i = 0; i < fullList.length; i++) {
    const result = nonQList.find((x) => x.no === fullList[i].no);
    if (result) {
      fullList[i].workedTotal = result.workedTotal;
    } else {
      fullList[i].workedTotal = 0;
    }
  }
  return bcPersistStore
    .itemsForCompany(props.project.companyGuid)
    .sort(favoriteSort);
});

function favoriteSort(a, b) {
  const favA = userPersist.userSettings.taskFavorites.indexOf(a.no);
  const favB = userPersist.userSettings.taskFavorites.indexOf(b.no);

  let comparison = 0;

  if (favA < favB) {
    favA === -1 ? (comparison = 1) : (comparison = -1);
  } else if (favA > favB) {
    favB === -1 ? (comparison = -1) : (comparison = 1);
  }
  return comparison;
}

const planningLines = computed(() => {
  if (!props.project.jobPlanningLines) {
    return [];
  }
  const billable = props.project.jobPlanningLines.filter((x) => {
    return x.lineType === "Billable";
  });

  const headers =
    props.project?.jobTasks?.filter((i) => {
      return i.jobTaskNo !== "NOTQ";
    }) || [];

  headers.forEach((h) => ((h.type = "Head"), (h.collapse = false)));

  for (let i = 0; i < billable.length; i++) {
    let workedTotal = 0;
    const budget = props.project.jobPlanningLines.filter(
      (x) =>
        x.attachedToLineNoPTE === billable[i].lineNo &&
        x.lineType === "Budget" &&
        x.jobTaskNo === billable[i].jobTaskNo
    );
    for (let j = 0; j < budget.length; j++) {
      workedTotal += budget[j].quantity;
    }
    billable[i].workedTotal = workedTotal;
  }

  headers.map((x) => {
    let worked = 0,
      total = 0;
    billable
      .filter((y) => y.jobTaskNo === x.jobTaskNo)
      .map((z) => {
        if (
          bcPersistStore.getBaseUnitFromTaskNo(
            z.no,
            props.project.companyGuid
          ) === "HOUR"
        ) {
          worked += z.workedTotal;
          total += z.quantity;
        }
      });
    x.workedTotal = worked;
    x.quantity = total;
  });
  const r = [...headers, ...billable];

  r.sort((a, b) =>
    a.jobTaskNo + a.type > b.jobTaskNo + b.type
      ? 1
      : b.jobTaskNo + b.type > a.jobTaskNo + a.type
      ? -1
      : 0
  );
  return r;
});

const fetchingData = ref(false);

function isSelected(row) {
  if (selectedTabIndex.value === 0) {
    return (
      props.selectedTask &&
      props.selectedTask.itemNo === row.no &&
      props.selectedTask.jobPlanningLineLineNo === row.lineNo
    );
  } else {
    return props.selectedTask && props.selectedTask.itemNo === row.no;
  }
}

async function fetchPlanningLineData() {
  if (!fetchingData.value) {
    let inputFocussed = false;
    if (props.project.jobPlanningLines) {
      setRelevantTab();
      inputFocussed = true;
    }
    fetchingData.value = true;

    await bcPersistStore.getDynamicsData({
      urlSegment: `jobs?$filter=systemId eq ${props.project.systemId} & $expand=jobPlanningLines,jobTasks`,
      stateName: "jobs",
      merge: true,
      mergeWithExistingRows: true,
      companyGuid: props.project.companyGuid,
      sortOnFields: ["billToName", "jobDescription"],
      replaceStore: false,
      caseSensitive: false,
    });
    fetchingData.value = false;
    if (!inputFocussed) {
      setRelevantTab();
    }
  }
}

function onLineClicked(row) {
  if (props.inTaskList && row && row.type !== "Head") {
    let quotedLine = false; //quotedPlanningLines.value.find((x) => x.no === row.no );
    if (quotedLine) {
      // console.log("onLineClicked quotedLine", quotedLine);
      emits("lineClicked", quotedLine);
    } else {
      // console.log("onLineClicked row", row);
      emits("lineClicked", row);
    }
    console.log("onLineClicked", quotedLine, row);
    emits("onClose");
  } else if (row?.type === "Head") {
    row.collapse = !row.collapse;
    for (
      let i = displayedData.value.indexOf(row) + 1;
      i < displayedData.value.length;
      i++
    ) {
      if (displayedData.value[i].type !== "Head") {
        displayedData.value[i].hidden = row.collapse;
      } else {
        return;
      }
    }
  }
}

const inputField = ref(null);
watch(inputField, (val, oldVal) => {
  if (val) {
    focusInputField();
  }
});

function focusInputField() {
  if (window.innerWidth >= 1024) {
    inputField.value.focus();
  }
}

const tableBody = ref(null);
watch(tableBody, (val, oldVal) => {
  if (val) {
    scrollToSelected();
  }
});

watch(displayedData, (val, oldVal) => {
  nextTick(() => {
    scrollToSelected();
  });
});

function scrollToSelected() {
  if (!tableBody.value) {
    return;
  }

  let elements = tableBody.value.getElementsByClassName("selectedRow");
  if (elements.length > 0) {
    selectedItemIndex.value = displayedData.value.findIndex((x) => {
      if (props.selectedTask.notQuoted) {
        return x.no === props.selectedTask.itemNo;
      } else {
        return (
          x.no === props.selectedTask.itemNo &&
          x.lineNo === props.selectedTask.jobPlanningLineLineNo
        );
      }
    });
    if (selectedItemIndex.value === -1) {
      selectedItemIndex.value = 0;
      tableBody.value.scrollTop = 0;
    } else {
      elements[0].scrollIntoView();
    }
  } else {
    selectedItemIndex.value = 0;
  }
}

function setRelevantTab() {
  selectedItemIndex.value = -1;
  if (
    props.selectedTask &&
    props.selectedTask.itemNo &&
    projectHasQuotedLines.value
  ) {
    selectedTabIndex.value = props.selectedTask.notQuoted ? 1 : 0;
  } else {
    selectedTabIndex.value = projectHasQuotedLines.value ? 0 : 1;
  }
}

const selectedItemIndex = ref(0);

const visibleIndexes = computed(() => {
  return displayedData.value
    .filter((x) => !x.hidden)
    .map((x) => displayedData.value.indexOf(x));
});

function keyPressed(e) {
  if (e.key === "Escape" && !fetchingData.value) {
    emits("onClose");
    return;
  } else if (e.key === "ArrowDown") {
    e.preventDefault();
    const index = visibleIndexes.value.indexOf(selectedItemIndex.value);
    if (index < visibleIndexes.value.length - 1) {
      selectedItemIndex.value = visibleIndexes.value[index + 1];
    } else {
      selectedItemIndex.value = 0;
    }
  } else if (e.key === "ArrowUp") {
    e.preventDefault();
    const index = visibleIndexes.value.indexOf(selectedItemIndex.value);
    if (selectedItemIndex.value > 0) {
      selectedItemIndex.value = visibleIndexes.value[index - 1];
    } else {
      selectedItemIndex.value =
        visibleIndexes.value[visibleIndexes.value.length - 1];
    }
  } else if (e.key === "Enter") {
    e.preventDefault();
    onLineClicked(displayedData.value[selectedItemIndex.value]);
    return;
  }

  nextTick().then(() => {
    let elements = tableBody.value.getElementsByClassName("arrowSelectedRow");

    if (elements.length > 0) {
      let rect = elements[0].getBoundingClientRect();
      let tableRect = tableBody.value.getBoundingClientRect();

      if (rect.top <= tableRect.top) {
        elements[0].scrollIntoView();
      } else if (rect.bottom >= tableRect.bottom) {
        elements[0].scrollIntoView(false);
      }
    }
  });
}

function overlayPressed(e) {
  if (e.target.id === "overlay") {
    emits("onClose");
  }
}

function toggleFavorite(e, task) {
  e.preventDefault();
  if (userPersist.userSettings.taskFavorites.includes(task)) {
    const index = userPersist.userSettings?.taskFavorites?.indexOf(task);
    if (index > -1) {
      userPersist.userSettings?.taskFavorites?.splice(index, 1);
    }
  } else {
    userPersist.userSettings?.taskFavorites?.push(task);
  }
  bcPersistStore.putResourceSettings({
    taskFavorites: userPersist.userSettings.taskFavorites,
  });
}
onMounted(() => {
  fetchPlanningLineData();
});
</script>
