<template>
  <div ref="scrollDiv" class="flex-grow">
    <table
      class="flex-no-wrap table w-full flex-col divide-y divide-gray-300 border-b-1"
      :class="classes"
    >
      <thead
        class="hidden flex-1 divide-y divide-gray-400 sm:table-header-group"
      >
        <tr
          class="sticky top-0 z-data-table-header h-10 bg-gray-100 text-xs sm:w-full"
        >
          <th
            v-for="(column, headerIndex) in columns"
            :key="column.field"
            scope="col"
            @click="headerClicked(column)"
            class="relative h-full text-gray-900"
            :class="[
              column.headerClass,
              {
                'border-l-gray:300 border-l-4':
                  isValidField && headerIndex === 0,
              },
            ]"
          >
            <slot
              v-if="column.headerSlot"
              :column="column"
              :name="column.headerSlot"
            />
            <div v-else class="flex items-center">
              {{ column.label }}
              <!--              <img class="w-3" v-if="column.icon" :src="column.icon" />-->
            </div>
          </th>
        </tr>
      </thead>
      <tbody
        class="flex-1 divide-y bg-white sm:w-full sm:flex-none"
        ref="tableBody"
      >
        <transition-group name="fade" appear>
          <tr
            v-for="(row, rowIndex) in tableData"
            :key="getKey(row, rowIndex)"
            class="flex-no wrap group/item group relative flex h-auto flex-wrap sm:mb-0 sm:table-row sm:w-full"
            :draggable="draggableRow"
            :class="[
              rowClass,
              {
                rejected: row.status === 'Rejected',
              },
              {
                'border-l-4 border-l-warning':
                  (isValidField && !row[isValidField]) ||
                  (row.status === 'Rejected' && row[isValidField]),
                'border-l-4 border-l-valid':
                  isValidField &&
                  row[isValidField] &&
                  row.status !== 'Rejected',
              },
            ]"
            @dragstart="setDragData($event, row)"
            @dblclick="emits('onDblClick', row)"
          >
            <td
              v-for="(column, colIndex) in columns"
              :key="column.field"
              :class="[
                column.class,
                // {
                //   'border-l-4 border-l-warning':
                //     (isValidField && !row[isValidField] && colIndex === 0) ||
                //     (row.status === 'Rejected' &&
                //       row[isValidField] &&
                //       colIndex === 0),
                //   'border-l-4 border-l-valid':
                //     isValidField &&
                //     row[isValidField] &&
                //     colIndex === 0 &&
                //     row.status !== 'Rejected',
                // },
              ]"
              class="px-2 py-2 align-middle text-gray-900 sm:h-full"
            >
              <!--            <transition appear name="fade">-->
              <!--                updating message-->
              <div
                v-if="colIndex === 0 && row.isDeleting"
                class="absolute top-0 z-table-info flex items-center gap-2 whitespace-nowrap rounded-b-lg bg-gray-400 px-2 font-normal text-white drop-shadow-md"
              >
                <Spinner :size="14" color="white" />
                <div>Deleting ...</div>
              </div>
              <!--            </transition>-->
              <!--            <transition appear name="fade">-->
              <!--                error message-->

              <TogglePanel
                v-if="hasError(colIndex, row, errorField)"
                :message="row[errorField]"
              />
              <!--            <div-->
              <!--              v-if="hasError(colIndex, row, errorField)"-->
              <!--              class="alert-error absolute left-4 right-4 top-0 z-table-info flex items-center justify-between gap-4 overflow-hidden rounded-b-lg py-3 px-4"-->
              <!--            >-->
              <!--              <div class="flex items-center gap-2">-->
              <!--                &lt;!&ndash;                <ExclamationIcon class="w-5" />&ndash;&gt;-->
              <!--                <div class="" v-html="row[errorField]"></div>-->
              <!--              </div>-->

              <!--              <router-link-->
              <!--                :to="{ name: 'Errors' }"-->
              <!--                class="w-1/6 justify-self-end text-right underline"-->
              <!--                >More info</router-link-->
              <!--              >-->
              <!--            </div>-->

              <!--            </transition>-->
              <!--            <transition appear name="fade">-->
              <!--                updating message-->
              <div
                v-if="
                  colIndex === 0 &&
                  !row[errorField] &&
                  row[updatingField] &&
                  !row.isDeleting
                "
                class="absolute top-0 z-table-info flex items-center gap-2 whitespace-nowrap rounded-b-lg bg-gray-400 px-2 font-normal text-white drop-shadow-md"
              >
                <Spinner :size="14" color="white" />
                <div>Saving ...</div>
              </div>
              <!--            </transition>-->
              <slot
                v-if="column.slotName"
                :name="column.slotName"
                :row="row"
                :rowIndex="rowIndex"
                :column="column"
              />
              <template v-else>{{ row[column.field] }}</template>
            </td>
          </tr>
        </transition-group>
      </tbody>
    </table>
    <div
      v-if="
        loadedData.length >= props.maxLoaded &&
        loadedData.length < props.data.length
      "
      class="py-1 text-center font-medium sm:w-full"
    >
      Please use a more specific filter, {{ loadedData.length }} of
      {{ props.data.length }} results shown.
    </div>
  </div>
</template>

<script setup>
import {
  useSlots,
  computed,
  onMounted,
  watch,
  ref,
  nextTick,
  onBeforeUnmount,
} from "vue";
import Sortable from "sortablejs";
import { useUserPersistStore } from "@/stores/userPersist.js";
import { useInfiniteScroll } from "@vueuse/core";
import TogglePanel from "@/components/TogglePanel.vue";

import { useBcPersistStore } from "@/stores/bcPersist.js";
import WebSocketHelper from "@/utils/WebSocketHelper.js";
import SlideButton from "@/components/SlideButton.vue";

const props = defineProps({
  classes: { type: String },
  data: { type: Array, default: [], required: true },
  columns: { type: Array, default: [], required: true },
  itemKey: { type: String, default: "id" },
  errorField: { type: String, default: "errorText" },
  updatingField: { type: String, default: "isUpdating" },
  isValidField: { type: String, default: "" },
  rowClass: { type: String },
  enableDrag: { type: Boolean, default: false },
  draggableRow: { type: Boolean, default: false },
  defaultLoaded: { type: Number, default: 30 },
  loadIncrement: { type: Number, default: 10 },
  maxLoaded: { type: Number, default: 100 },
  headerSlot: { type: Object, default: null },
  filter: { type: Object, default: null },
});

const emits = defineEmits(["onHeaderClicked", "onDblClick", "onFilteredData"]);
const userPersist = useUserPersistStore();

const tableBody = ref(null);
const scrollDiv = ref(null);

const loadedData = ref([]);
let dataLength = 0;

const filteredData = computed(() => {
  const data = props.data.filter((x) => {
    if (!props.filter) {
      return true;
    }
    let valid = true;
    for (const [key, value] of Object.entries(props.filter)) {
      if (value.values) {
        //Multi select filter
        if (!value.values.has(x[key])) {
          valid = false;
        }
      }
    }
    return valid;
  });
  emits("onFilteredData", data);
  return data;
});

useInfiniteScroll(
  scrollDiv,
  () => {
    const length = loadedData.value.length;
    if (length < props.maxLoaded) {
      loadedData.value.push(
        ...filteredData.value.slice(
          length,
          length + Math.min(props.loadIncrement, props.maxLoaded - length)
        )
      );
    }
  },
  { distance: props.loadIncrement }
);

watch(
  () => filteredData.value,
  (value, oldValue) => {
    if (value.length > dataLength) {
      loadedData.value = filteredData.value.slice(
        0,
        Math.max(props.defaultLoaded, loadedData.value.length) + 1
      );
    } else {
      loadedData.value = filteredData.value.slice(
        0,
        Math.max(props.defaultLoaded, loadedData.value.length)
      );
    }
    nextTick(() => {
      loadDataUntilScroll();
    });
    dataLength = value.length;
  },
  { deep: true }
);

const tableData = computed({
  get() {
    if (props.enableDrag) {
      const data = [...loadedData.value];
      // console.log("sort");
      data.sort((a, b) => {
        // console.log(
        //   "userPersist.taskOrder",
        //   userPersist.userSettings.taskOrder.indexOf(a.id)
        // );
        if (
          userPersist.userSettings.taskOrder.indexOf(a.id) > -1 &&
          userPersist.userSettings.taskOrder.indexOf(b.id) > -1
        ) {
          return (
            userPersist.userSettings.taskOrder.indexOf(a.id) -
            userPersist.userSettings.taskOrder.indexOf(b.id)
          );
        } else {
          return 0;
        }
      });
      return data;
    } else {
      return loadedData.value;
      // return props.data;
    }
  },
  set(newValue) {
    // console.log("newValue", newValue);
    userPersist.userSettings.taskOrder = newValue.map((i) => i.id);

    new WebSocketHelper().wsSend({
      command: "changeOrder",
      order: userPersist.userSettings.taskOrder,
    });

    console.log(
      "userPersist.userSettings.taskOrder",
      userPersist.userSettings.taskOrder
    );

    useBcPersistStore().putResourceSettings({
      taskOrder: userPersist.userSettings.taskOrder,
    });
  },
});

function headerClicked(head) {
  if (head.orderField) {
    emits("onHeaderClicked", head);
  }
}

function updateTableOrder() {
  // console.log("updateTableOrder");
  const origSorting = JSON.stringify(userPersist.userSettings?.taskOrder);
  for (let i = 0; i < filteredData.value.length; i++) {
    if (
      filteredData.value[i].id &&
      !userPersist.userSettings?.taskOrder?.includes(filteredData.value[i].id)
    ) {
      if (!userPersist.userSettings?.taskOrder) {
        userPersist.userSettings.taskOrder = [];
      }
      userPersist.userSettings?.taskOrder.push(filteredData.value[i].id);
    }
  }
  // console.log("b4", userPersist.userSettings.taskOrder.length);
  userPersist.userSettings.taskOrder =
    userPersist.userSettings?.taskOrder?.filter((x) =>
      filteredData.value.find((y) => y.id === x)
    );
  if (origSorting !== JSON.stringify(userPersist.userSettings?.taskOrder)) {
    useBcPersistStore().putResourceSettings({
      taskOrder: userPersist.userSettings.taskOrder,
    });
  }
  // console.log("Af", userPersist.userSettings.taskOrder.length);
}

function hasError(colIndex, row, errorField) {
  return (
    colIndex === 0 &&
    !(
      !row[errorField] && !(row.errorDescription && row.status === "Rejected")
    ) &&
    !row.isDeleting
  );
}

// function renderField(column, row) {
//   if (column.template) {
//     const html = h("div", {}, slots[column.template]());
//     // console.log("slots", html);
//     // return slots[column.template]();
//     return html;
//   } else {
//     return row[column.field];
//   }
// }

function getKey(row, i) {
  const r = row[props.itemKey] || Math.random();
  // console.log("key", i, r);
  return r;
}

function initSortable() {
  Sortable.create(tableBody.value, {
    animation: 150,
    ghostClass: "bg-primary-100",
    handle: ".drag-handle",
    // filter: ".drag-disabled",
    onEnd({ newIndex, oldIndex }) {
      const dataRef = tableData.value;
      dataRef.splice(newIndex, 0, ...dataRef.splice(oldIndex, 1));
      tableData.value = dataRef;
    },
  });
}

// watch(
//   () => tableData,
//   (value, oldValue) => {
//     nextTick(() => {
//       loadDataUntilScroll();
//     });
//   },
//   { deep: true }
// );

function loadDataUntilScroll() {
  if (scrollDiv?.value?.clientHeight && tableBody?.value?.clientHeight) {
    if (
      !(scrollDiv.value.clientHeight < scrollDiv.value.scrollHeight) &&
      loadedData.value.length < filteredData.value.length
    ) {
      const missingItemAmount = Math.max(
        Math.ceil(
          (scrollDiv.value.clientHeight - tableBody.value.clientHeight) /
            (tableBody.value.clientHeight / loadedData.value.length)
        ) + 2,
        2
      );
      const length = loadedData.value.length;
      loadedData.value.push(
        ...filteredData.value.slice(length, length + missingItemAmount)
      );
      // console.log(tableBody.value && scrollDiv.value);
      nextTick(() => {
        loadDataUntilScroll();
      });
    }
  }
}

function setDragData(e, id) {
  e.dataTransfer.clearData();
  e.dataTransfer.setData("text/plain", JSON.stringify(id));
}

onMounted(() => {
  dataLength = filteredData.value.length;
  if (props.enableDrag) {
    updateTableOrder();
  }
  initSortable();
  loadedData.value = filteredData.value.slice(0, props.defaultLoaded);
  nextTick(() => {
    loadDataUntilScroll();
    window.addEventListener("resize", loadDataUntilScroll);
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", loadDataUntilScroll);
});
</script>

<style scoped>
/*th:first-child {
  @apply !pl-7;
}*/
</style>
