<template>
  <section
    class="table-tasks-list flex flex-1 flex-col overflow-hidden shadow-md sm:flex sm:rounded-md"
    style="background: #f8f9fa"
  >
    <div
      class="hidden items-center justify-between border-b-1 border-gray-300 bg-white px-6 py-4 text-center sm:block"
    >
      <h2 class="text-left text-xl uppercase text-primary">My tasks list</h2>
    </div>
    <div
      class="flex flex-grow overflow-y-auto overflow-x-hidden sm:block"
      @dragover="$event.preventDefault()"
      @drop="onDrop"
      ref="taskListContainer"
    >
      <transition name="fade" appear mode="out-in">
        <DataTable
          :data="myTasks"
          :columns="columns"
          :enableDrag="true"
          rowClass="sm:p-2 h-auto id-task-tr"
          is-valid-field="isValid"
          v-if="myTasks.length > 0"
        >
          <template #actionsHeader>
            <div class="mr-auto flex flex-1">
              <bryntumCheckbox
                @click="checkboxAll"
                color="b-orange"
                :value="String(selectAllState)"
              ></bryntumCheckbox>
            </div>
          </template>
          <template #record="slotProps">
            <div
              v-if="slotProps.row.status !== 'Rejected'"
              class="mt-1 flex items-center gap-1.5"
            >
              <div
                class="id-task-checkbox pointer-events-auto relative top-1 translate-y-0"
              >
                <bryntumCheckbox
                  color="b-orange"
                  @change="checkboxChecked($event.value, slotProps.row)"
                  :value="String(selectedRows.includes(slotProps.row.id))"
                ></bryntumCheckbox>
              </div>
              <button
                v-if="
                  bcPersistStore.getBaseUnitFromTaskNo(
                    slotProps.row.itemNo,
                    slotProps.row.companyGuid
                  ) === 'HOUR'
                "
                :title="getPlayButtonTitle(slotProps)"
                @click="clickTaskTimer(slotProps)"
                class="but-btn-action but-btn-icon id-task-record pointer-events-auto absolute right-4 m-0 mx-2 mt-4 sm:relative sm:right-auto sm:m-0"
                :class="{
                  active: slotProps.row.timeSheetBeginTime !== NULL_DATE,
                }"
                :disabled="taskIsDisabled(slotProps.row)"
              >
                <!--                <PlayIcon
                                                  v-if="slotProps.row.timeSheetBeginTime === NULL_DATE"
                                                  class="block h-8 w-8"
                                                ></PlayIcon>-->

                <RecordIcon
                  class="w-8 text-gray-400"
                  v-if="slotProps.row.timeSheetBeginTime === NULL_DATE"
                />
                <RecordIcon class="w-8 text-error" v-else />

                <!--                <PauseIcon v-else class="block h-8 w-8"></PauseIcon>-->
              </button>
            </div>
          </template>
          <template #numberAndBillTo="slotProps">
            <div
              :class="[
                {
                  'ml-7': slotProps.row.status === 'Rejected',
                },
              ]"
            >
              <!--            <div v-if="slotProps.row.status === 'Rejected'">-</div>-->
              <div
                class="mb-1 mt-2 flex h-4 items-center gap-1 pr-2 sm:mt-0 sm:p-0"
              >
                <companyFlagIcon
                  :company-guid="slotProps.row.companyGuid"
                ></companyFlagIcon>
                <span>{{ slotProps.row.jobNo }}</span>
              </div>
              <div>
                {{ slotProps.row.billToName }}
              </div>
              <div
                class="opacity-80"
                v-html="replaceBcBreaks(slotProps.row.jobDescription)"
              ></div>
            </div>
          </template>
          <template #projectAndDescription="slotProps">
            <div
              class="id-task-description flex flex-col gap-1 text-sm sm:static sm:w-auto"
            >
              <div
                class="table-tasks-list-input relative cursor-pointer"
                @click="selectPlanningLine(slotProps.row)"
              >
                <div
                  :class="[
                    slotProps.row.itemNo ? 'text-gray-900' : 'text-gray-500',
                    slotProps.row.status === 'Rejected'
                      ? 'text-gray-disabled'
                      : '',
                  ]"
                >
                  {{ getDescriptionForItem(slotProps.row) }}
                </div>
                <div>
                  <ChevronDownIcon
                    class="absolute right-2 top-1/2 ml-1 inline-block h-5 w-5 -translate-y-1/2 transform bg-white text-gray-500 group-hover:text-gray-600 sm:static sm:transform-none"
                    aria-hidden="true"
                    :class="{ hidden: slotProps.row.status === 'Rejected' }"
                    @click="selectPlanningLine(slotProps.row)"
                  />
                </div>
              </div>
              <InputText
                v-model="slotProps.row.timeSheetDescription"
                @update:modelValue="
                  debouncedUpdateFields(slotProps.row, ['timeSheetDescription'])
                "
                type="text"
                maxlength="250"
                :disabled="slotProps.row.status === 'Rejected'"
                placeholder="Your description"
                :tabindex="slotProps.rowIndex * 3 + 3"
              />
            </div>
          </template>

          <template #quantity="slotProps">
            <div
              class="id-task-time id-task-timedate flex flex-col gap-1 sm:static sm:w-32"
            >
              <InputDate
                cls="focus:outline-none w-full "
                v-model="slotProps.row.date"
                @update:modelValue="updateDate(slotProps.row)"
                :disabled="
                  slotProps.row.status === 'Rejected' ||
                  slotProps.row.timeSheetBeginTime !== NULL_DATE
                "
                :tabIndex="slotProps.rowIndex * 3 + 5"
                :min="new Date('25 dec 2022')"
                :max="dayEnd"
              />
              <!--              TODO date Temporary change-->
              <!--              :min="new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30)"-->
              <div
                v-if="
                  bcPersistStore.getBaseUnitFromTaskNo(
                    slotProps.row.itemNo,
                    slotProps.row.companyGuid
                  ) === 'HOUR'
                "
              >
                <InputTime
                  v-show="slotProps.row.timeSheetBeginTime === NULL_DATE"
                  v-model="slotProps.row.quantity"
                  @update:modelValue="
                    debouncedUpdateFields(slotProps.row, ['quantity'])
                  "
                  :disabled="slotProps.row.status === 'Rejected'"
                  :tabindex="slotProps.rowIndex * 3 + 4"
                />
                <TaskTimer
                  v-if="slotProps.row.timeSheetBeginTime !== NULL_DATE"
                  :quantity="slotProps.row.quantity"
                  :beginTime="slotProps.row.timeSheetBeginTime"
                  :nullTime="NULL_DATE"
                />
              </div>

              <div
                v-else
                class="but-input-outer group inline-flex items-center"
              >
                <InputNumber
                  v-model="slotProps.row.quantity"
                  @update:modelValue="
                    debouncedUpdateFields(slotProps.row, ['quantity'])
                  "
                  placeholder="0"
                  class="grow"
                  :tabindex="slotProps.rowIndex * 3 + 4"
                />
                <div class="special-unit px-2 text-right">
                  {{
                    bcPersistStore.getBaseUnitFromTaskNo(
                      slotProps.row.itemNo,
                      slotProps.row.companyGuid
                    )
                  }}
                </div>
              </div>
            </div>
          </template>
        </DataTable>
      </transition>
      <transition name="fade" appear mode="out-in">
        <div
          v-if="
            userPersistStore.userSettings &&
            userPersistStore.userSettings.storedIdleTime > 0
          "
          class="p-4"
        >
          <div
            class="relative flex items-center justify-between overflow-hidden rounded-lg border-2 border-dashed border-gray px-8 py-6 text-lg sm:w-full"
          >
            <div>Add new task here to assign idle time</div>
            <div>
              {{
                quantityToTime(userPersistStore.userSettings?.storedIdleTime)
              }}
            </div>
            <XIcon
              class="absolute right-0 top-0 h-7 w-7 cursor-pointer p-1 text-gray"
              @click="removeStoredIdleTime"
            ></XIcon>
          </div>
        </div>
      </transition>
      <transition name="fade" appear mode="out-in">
        <div
          v-if="myTasks.length === 0"
          class="w-full px-4 py-8 text-center text-sm text-gray-600"
        >
          <p class="sm:hidden">
            From the Project Tab,<br />
            tap twice or tab the "+" button to create a task.
          </p>
          <p class="hidden sm:block">
            From the Project List, drag or click the "+" button to create a
            task.
          </p>
        </div>
      </transition>
    </div>
    <div class="mt-auto">
      <transition appear name="fade">
        <div
          v-if="showUndoDelete"
          class="ml-4 flex w-fit items-center gap-2 bg-primary-500 px-2 text-white"
        >
          <p>Tasks deleted</p>
          <p
            @click="undoDeleteTask"
            class="cursor-pointer font-bold text-primary-200 underline"
          >
            Undo
          </p>
        </div>
      </transition>
      <div class="flex h-14 w-full items-center justify-between gap-4 px-4">
        <button
          class="rounded-md px-2 py-0.5 text-sm font-medium uppercase text-white"
          :class="
            selectedRows.length > 0
              ? 'cursor-pointer bg-primary hover:bg-primary-600'
              : 'cursor-help bg-gray-400'
          "
          @click="deleteSelectedTasks"
          :title="
            selectedRows.length > 0
              ? `Delete the selected tasks`
              : 'Select one or more tasks using the checkbox and then press Delete'
          "
        >
          {{
            selectedRows.length === 0
              ? "Delete"
              : selectedRows.length === 1
                ? `Delete ${selectedRows.length} task`
                : `Delete ${selectedRows.length} tasks`
          }}
        </button>
        <div
          class="flex flex-grow flex-col items-center"
          v-if="isValidTimeShift"
        >
          <span class="z-50 bg-white px-1">Time shift </span>
          <input
            @mousedown="timeShiftStart"
            @touchstart="timeShiftStart"
            @mouseup="timeshiftEnd"
            @touchend="timeshiftEnd"
            @input="timeShiftChange"
            class="my-1.5"
            type="range"
            title="Shift time from one project to another by dragging the slider."
            :disabled="!isValidTimeShift"
            :value="timeShiftValues.firstItem"
            min="0"
            step="1"
            :max="timeShiftValues.total"
          />
          <span>{{ timeShiftDifference }}</span>
        </div>
        <div v-else class="flex flex-col">
          <button
            class="cursor-help rounded-md bg-gray-400 px-2 py-0.5 text-sm font-medium uppercase text-white"
            title="Select 2 tasks to shift time from one project to another by dragging the slider."
          >
            Time Shift
          </button>
        </div>
        <div
          @dragover="$event.preventDefault()"
          @drop="onDrop($event, false)"
          class="ml-auto text-right text-base font-bold"
        >
          Today: {{ quantityToTime(workedHours, false) }}
        </div>
      </div>
    </div>
    <PlanningLinePopup
      v-if="openPlanningLine"
      @onClose="openPlanningLine = null"
      :project="
        bcPersistStore.jobs.find((x) => x.systemId === openPlanningLine.jobId)
      "
      :inTaskList="true"
      :selected-task="openPlanningLine"
      @lineClicked="onItemChange($event, openPlanningLine)"
    />
  </section>
</template>

<style lang="scss">
.special-unit:first-letter {
  @apply lowercase;
  text-transform: uppercase;
}
</style>

<script setup>
import DataTable from "@/components/DataTable.vue";
import InputTime from "@/components/InputTime.vue";
import { useBcPersistStore, NULL_DATE } from "@/stores/bcPersist.js";
import InputText from "@/components/InputText.vue";
import InputDate from "@/components/InputDate.vue";
import InputNumber from "@/components/InputNumber.vue";
import TaskTimer from "@/components/TaskTimer.vue";
import { useUserPersistStore } from "@/stores/userPersist.js";
import { BryntumCheckbox } from "@bryntum/scheduler-vue-3";
import CompanyFlagIcon from "@/components/CompanyFlagIcon.vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useIdleStore } from "@/stores/idle.js";
import { copyObject, quantityToTime, replaceBcBreaks } from "@/utils/Utils.js";
import {
  XIcon,
  // PauseIcon,
  // PlayIcon,
  ChevronDownIcon,
} from "@heroicons/vue/solid";
import WebSocketHelper from "@/utils/WebSocketHelper.js";
import RecordIcon from "@/components/icons/RecordIcon.vue";
import { useUserStore } from "@/stores/user.js";

const bcPersistStore = useBcPersistStore();
const userPersistStore = useUserPersistStore();
const userStore = useUserStore();
const idleStore = useIdleStore();

const emit = defineEmits(["onDrop", "meh"]);

const dayEnd = computed(() => {
  const dayEnd = new Date();
  dayEnd.setHours(23, 59, 59, 999);
  return dayEnd;
});

const props = defineProps({
  myTasks: {
    type: Array,
    required: true,
  },
});

const columns = [
  {
    field: "",
    label: "Actions",
    slotName: "record",
    headerSlot: "actionsHeader",
    class: "id-task-actions",
  },
  // { field: "id", label: "id" },
  {
    field: "",
    label: "Project",
    slotName: "numberAndBillTo",
    class:
      "sm:w-6/12 xl:w-5/12 pointer-events-none lg:pointer-events-auto drag-handle cursor-move id-task-project w-[calc(100%_-_66px)]",
    headerClass: "",
  },
  {
    field: "timeSheetDescription",
    label: "Activity",
    slotName: "projectAndDescription",
    class:
      "pl-0 pr-2 sm:px-2 ml-14 sm:m-0 sm:w-6/12 xl:w-5/12 w-[calc(50%_-_1.75rem)]",
  },
  // { field: "status", label: "Status" },
  // { field: "isUpdating", label: "Upd" },
  // { field: "@odata.etag", label: "Etag" },
  {
    field: "quantity",
    label: "Time",
    slotName: "quantity",
    class: "px-0 sm:px-2 sm:w-36 w-[calc(50%_-_1.75rem)]",
  },
];

const selectedRows = ref([]);
const selectAllState = computed(() => {
  if (
    selectedRows.value.length ===
    bcPersistStore.myTasks.filter((x) => x.status !== "Rejected").length
  ) {
    return true;
  } else {
    return false;
  }
});

function getPlayButtonTitle(slotProps) {
  if (taskIsDisabled(slotProps.row)) {
    return "You can only start a timer on today's tasks.";
  } else {
    return "Toggle the timer";
  }
}

const openPlanningLine = ref(null);

function selectPlanningLine(row) {
  if (row.status !== "Rejected") {
    openPlanningLine.value = row;
  }
}

const minuteUpdate = ref(0);
setInterval(() => {
  minuteUpdate.value++;
}, 60000);

const isValidTimeShift = computed(() => {
  if (!timeShiftSaving.value && selectedRows.value.length === 2) {
    let returnVal = true;
    for (let i = 0; i < 2; i++) {
      if (
        selectedRowsObj.value[i]?.itemNo &&
        bcPersistStore.getBaseUnitFromTaskNo(
          selectedRowsObj.value[i]?.itemNo,
          selectedRowsObj.value[i]?.companyGuid
        ) !== "HOUR"
      ) {
        returnVal = false;
      }
    }
    return returnVal;
  }
  return false;
});

const workedHours = computed(() => {
  minuteUpdate.value;
  let value = 0;
  const today = new Date().toISOString().substring(0, 10);
  for (let i = 0; i < props.myTasks.length; i++) {
    if (
      props.myTasks[i].date === today &&
      ((props.myTasks[i].itemNo &&
        bcPersistStore.getBaseUnitFromTaskNo(
          props.myTasks[i].itemNo,
          props.myTasks[i].companyGuid
        ) === "HOUR") ||
        !props.myTasks[i].itemNo)
    ) {
      value += props.myTasks[i].quantity;
      if (props.myTasks[i].timeSheetBeginTime !== NULL_DATE) {
        value += getRunningTaskTime(props.myTasks[i].timeSheetBeginTime) / 3600;
      }
    }
  }
  return value;
});

const selectedRowsObj = computed(() => {
  let rows = [];
  for (let i = 0; i < selectedRows.value.length; i++) {
    rows.push(props.myTasks.find((x) => x.id === selectedRows.value[i]));
  }
  return rows;
});

const lastDeletedTasks = ref([]);
const showUndoDelete = ref(false);
let showUndoDeleteTimout = null;
const disableButtons = ref(false);

const timeShiftStartVal = ref(0);
const timeShifting = ref(false);
const timeShiftSaving = ref(false);
let timeShiftRunningTimer = null;
const timeShiftValues = computed(() => {
  let total = 9999999;
  let firstItem = 0;
  if (selectedRows.value.length === 2) {
    total = 0;
    for (let i = 0; i < selectedRows.value.length; i++) {
      const selectedRow = selectedRowsObj.value[i];
      if (selectedRow) {
        if (selectedRow.timeSheetBeginTime !== NULL_DATE) {
          total += selectedRow.quantity * 60;
          total += getRunningTaskTime(selectedRow.timeSheetBeginTime) / 60;
        } else {
          total += selectedRow.quantity * 60;
        }
        if (i === 0) {
          firstItem = Math.round(total);
        }
      }
    }
  }
  total = Math.round(total);
  return { total, firstItem };
});
const timeShiftDifference = computed(() => {
  if (selectedRowsObj.value.length === 2 && timeShifting.value) {
    const val = timeShiftStartVal.value - selectedRowsObj.value[0].quantity;
    let sign = "+";

    if (val < 0) {
      sign = "-";
    }
    return `${sign} ${quantityToTime(Math.abs(val))}`;
  } else {
    return `00:00`;
  }
});

function removeStoredIdleTime() {
  userPersistStore.userSettings.storedIdleTime = 0;
  bcPersistStore.putResourceSettings({ storedIdleTime: 0 }).then(() => {
    new WebSocketHelper().wsSend({
      command: "updateStoredIdleTime",
      idleTime: 0,
    });
  });
}

function timeShiftChange(e) {
  const total = timeShiftValues.value.total;
  selectedRowsObj.value[0].quantity = e.target.value / 60;
  selectedRowsObj.value[1].quantity =
    total / 60 - selectedRowsObj.value[0].quantity;
}

function timeShiftStart() {
  if (selectedRowsObj.value.length === 2) {
    timeShifting.value = true;
    for (let i = 0; i < selectedRows.value.length; i++) {
      if (selectedRowsObj.value[i].timeSheetBeginTime !== NULL_DATE) {
        timeShiftRunningTimer = selectedRowsObj.value[i];
        selectedRowsObj.value[i].quantity =
          getRunningTaskTime(selectedRowsObj.value[i].timeSheetBeginTime) /
            3600 +
          selectedRowsObj.value[i].quantity;
        selectedRowsObj.value[i].timeSheetBeginTime = NULL_DATE;

        if (selectedRowsObj.value[i].quantity > 23.99) {
          selectedRowsObj.value[i].errorText =
            `The max task duration was exceeded and is truncated to 23:59. The remaining ${quantityToTime(
              selectedRowsObj.value[i].quantity - 23.99
            )} will NOT be kept.`;
          useUserPersistStore().pushError({
            message: `${selectedRowsObj.value[i].jobNo} - ${selectedRowsObj.value[i].jobDescription}: ${selectedRowsObj.value[i].errorText}`,
          });
        }
      }
    }
    timeShiftStartVal.value = selectedRowsObj.value[0].quantity;
  }
}

async function timeshiftEnd() {
  const promises = [];
  if (timeShiftRunningTimer) {
    timeShiftRunningTimer.timeSheetBeginTime = new Date().toISOString();
    timeShiftRunningTimer = null;
  }
  timeShiftSaving.value = true;
  for (let i = 0; i < selectedRows.value.length; i++) {
    promises.push(
      debouncedUpdateFields(selectedRowsObj.value[i], [
        "timeSheetBeginTime",
        "quantity",
      ])
    );
  }

  Promise.all(promises).then(() => {
    timeShiftSaving.value = false;
    timeShifting.value = false;
    timeShiftStartVal.value = selectedRowsObj.value[0].quantity;
    // new WebSocketHelper().wsSend({
    //     command: "updateTask",
    //     id: row.id,
    //     companyGuid: row.companyGuid,
    // });
  });
}

function checkboxChecked(value, row) {
  if (value) {
    if (!selectedRows.value.includes(row.id)) {
      selectedRows.value.push(row.id);
    }
  } else {
    selectedRows.value = selectedRows.value.filter((x) => x !== row.id);
  }
}

function checkboxAll() {
  if (selectAllState.value) {
    selectedRows.value = [];
  } else {
    for (let i = 0; i < bcPersistStore.myTasks.length; i++) {
      if (
        !selectedRows.value.includes(bcPersistStore.myTasks[i].id) &&
        bcPersistStore.myTasks[i].status !== "Rejected"
      ) {
        selectedRows.value.push(bcPersistStore.myTasks[i].id);
      }
    }
  }
}

async function deleteSelectedTasks() {
  if (selectedRows.value.length > 0) {
    lastDeletedTasks.value = selectedRowsObj.value;
    for (let i = 0; i < selectedRows.value.length; i++) {
      const row = selectedRowsObj.value[i];
      await deleteTask(row);
    }
    showUndoDelete.value = true;
    clearTimeout(showUndoDeleteTimout);
    showUndoDeleteTimout = setTimeout(() => {
      showUndoDelete.value = false;
    }, 30000);
    selectedRows.value = [];
  }
}

async function onItemChange(e, row) {
  const baseUnit =
    e.baseUnitOfMeasure ||
    bcPersistStore.getBaseUnitFromTaskNo(row.itemNo, row.companyGuid);

  if (baseUnit !== "HOUR" && row.timeSheetBeginTime !== NULL_DATE) {
    console.warn("STOPPING", e, row);
    disableButtons.value = true;
    const stoppedTasks = await bcPersistStore.stopAllTasks().finally(() => {
      disableButtons.value = false;
    });
    stoppedTasks.forEach((stoppedTask) => {
      //find task in myTasks, to be sure we work with the enriched fields
      const task = bcPersistStore.myTasks.find((t) => t.id === stoppedTask.id);
      // console.log("stoppedtasks", task);
      if (task) {
        new WebSocketHelper().wsSend({
          command: "updateTask",
          id: task.id,
          companyGuid: task.companyGuid,
        });
      }
    });
  }

  row.notQuoted = !e.lineNo;
  row.jobPlanningLineLineNo = e.lineNo ? e.lineNo : 0;
  row.jobTaskNo = e.jobTaskNo ? e.jobTaskNo : "";
  row.itemNo = e.no;
  console.log("onItemChange", row);
  debouncedUpdateFields(row, [
    "itemNo",
    "jobTaskNo",
    "jobPlanningLineLineNo",
    "notQuoted",
  ]);
}

async function clickTaskTimer(slotProps) {
  disableButtons.value = true;
  const stop = slotProps.row.timeSheetBeginTime !== NULL_DATE;

  if (
    userPersistStore.enableIdleDetection &&
    !(
      idleStore.idlePermission === "granted" &&
      idleStore.notificationPermission === "granted"
    )
  ) {
    await idleStore.askPermissions();
  }
  slotProps.row.isUpdating = true;
  slotProps.row.errorText = false;

  await bcPersistStore.myTasks
    .filter((x) => x.timeSheetBeginTime !== NULL_DATE)
    .forEach(async (task) => {
      await debouncedUpdateFields(task, ["delay"]);
    });
  const stoppedTasks = await bcPersistStore.stopAllTasks().catch(() => {
    slotProps.row.isUpdating = false;
  });
  stoppedTasks.forEach((stoppedTask) => {
    //find task in myTasks, to be sure we work with the enriched fields
    const task = bcPersistStore.myTasks.find((t) => t?.id === stoppedTask?.id);
    // console.log("stoppedtasks", task);
    if (task) {
      new WebSocketHelper().wsSend({
        command: "updateTask",
        id: task.id,
        companyGuid: task.companyGuid,
      });
    }
  });

  if (stop) {
    //Stop
    if (idleStore.idlePermission === "granted") {
      idleStore.stopIdleDetector();
    }
  } else {
    //Start
    if (idleStore.idlePermission === "granted") {
      await idleStore.startIdleDetector();
    }
    slotProps.row.timeSheetBeginTime = new Date().toISOString();
    await debouncedUpdateFields(slotProps.row, ["timeSheetBeginTime"]);
  }
  slotProps.row.isUpdating = false;
  disableButtons.value = false;
  updateDocumentTitle();
  // console.log("slotProps.row", slotProps.row);
}

async function deleteTask(row) {
  // console.log("deleteTask", row);
  row.errorText = false;
  row.isDeleting = true;
  if (row.status === "Submitted") {
    row.status = "Rejected";
    await updateFields(row, ["status"]);
  }
  bcPersistStore
    .deleteDynamicsData({
      companyGuid: row.companyGuid,
      urlSegment: "timeRegistrationEntries",
      id: row.id,
      stateName: "myTasks",
    })
    .catch((e) => {
      row.errorText = "Error while deleting task.";
      lastDeletedTasks.value = [];
      showUndoDelete.value = false;
    })
    .finally(() => {
      row.isDeleting = false;
      new WebSocketHelper().wsSend({
        command: "deleteTask",
        id: row.id,
        companyGuid: row.companyGuid,
      });
    });
}

async function undoDeleteTask() {
  clearTimeout(showUndoDeleteTimout);
  // console.log("lastDeletedTasks", lastDeletedTasks);
  showUndoDelete.value = false;
  for (let i = 0; i < lastDeletedTasks.value.length; i++) {
    const data = {
      date: lastDeletedTasks.value[i].date,
      jobNo: lastDeletedTasks.value[i].jobNo,
      jobTaskNo: lastDeletedTasks.value[i].jobTaskNo,
      jobPlanningLineLineNo: lastDeletedTasks.value[i].jobPlanningLineLineNo,
      resourceNo: lastDeletedTasks.value[i].resourceNo,
      quantity: lastDeletedTasks.value[i].quantity,
      timeSheetDescription: lastDeletedTasks.value[i].timeSheetDescription,
      itemNo: lastDeletedTasks.value[i].itemNo,
      workType: lastDeletedTasks.value[i].workType,
      status: lastDeletedTasks.value[i].status,
      timeSheetBeginTime: lastDeletedTasks.value[i].timeSheetBeginTime,
      notQuoted: lastDeletedTasks.value[i].notQuoted,
    };

    await bcPersistStore
      .postDynamicsData({
        urlSegment: "timeRegistrationEntries",
        companyGuid: lastDeletedTasks.value[i].companyGuid,
        data,
        stateName: "myTasks",
      })
      .then((r) => {
        const task = bcPersistStore.myTasks.find((t) => t.id === r.id);
        bcPersistStore.injectMissingTaskFields(task);
        bcPersistStore.validateTaskFields(task);
        new WebSocketHelper().wsSend({
          command: "updateTask",
          id: task.id,
          companyGuid: task.companyGuid,
        });
      })
      .catch((e) => {
        useUserPersistStore().pushError(e);
      })
      .finally(() => {});
  }
  lastDeletedTasks.value = [];
}

async function debouncedUpdateFields(row, fieldNames) {
  if (!userStore.fieldUpdateBuffer?.length > 0) userStore.savingCount++;
  const bufferDelay = 1000;
  const bufferedValue = userStore.fieldUpdateBuffer.find(
    (x) => x.row.id === row.id
  );

  async function processCallback(id) {
    const bufferedValue = userStore.fieldUpdateBuffer.find(
      (x) => x.row.id === id
    );
    if (bufferedValue.row.isUpdating) {
      debouncedUpdateFields(bufferedValue.row, bufferedValue.fieldNames);
      return;
    }

    userStore.fieldUpdateBuffer = userStore.fieldUpdateBuffer.filter(
      (x) => x.row.id !== id
    );
    bufferedValue.fieldNames = bufferedValue.fieldNames.filter(
      (x) => x !== "delay"
    );
    if (bufferedValue.fieldNames.length > 0) {
      await updateFields(bufferedValue.row, bufferedValue.fieldNames);
    }
    userStore.savingCount--;
  }

  if (bufferedValue) {
    clearTimeout(bufferedValue.callback);
    bufferedValue.fieldNames.push(
      ...fieldNames.filter((x) => !bufferedValue.fieldNames.includes(x))
    );
    const callback = setTimeout(() => {
      processCallback(row.id);
    }, bufferDelay);
    bufferedValue.callback = callback;
  } else {
    const callback = setTimeout(() => {
      processCallback(row.id);
    }, bufferDelay);
    userStore.fieldUpdateBuffer.push({ row, fieldNames, callback });
  }
}

async function updateFields(row, fieldNames) {
  // console.log("updateFields", fieldNames, row);

  row.isUpdating = true;
  row.errorText = false;

  const origStatus = row.status;
  const isValid = bcPersistStore.validateTaskFields(row);

  row.status = isValid ? "Submitted" : "Open";

  if (!fieldNames.includes("status")) {
    fieldNames.push("status");
  }

  return bcPersistStore
    .updateDynamicsData({
      companyGuid: row.companyGuid,
      urlSegment: "timeRegistrationEntries",
      id: row.id,
      stateName: "myTasks",
      fieldNames,
      fieldValues: fieldNames.map((f) => row[f]),
    })
    .catch((e) => {
      let errorText;
      if (
        e?.response?.data?.error?.message?.includes(
          "Status must be Open or Rejected"
        )
      ) {
        errorText = `No edit possible because this task is already processed by Business Central. Please refresh.`;
      } else if (
        e?.response?.data?.error?.message?.includes(
          "as it is already completed"
        )
      ) {
        const job = bcPersistStore.jobs.find((x) => x.systemId === row.jobId);
        errorText = `No edit possible because the project has been closed. Contact ${
          job?.projectManager || "your project manager"
        } to resolve your issue.`;
      } else {
        errorText = `Business Central error while updating ${fieldNames.join(
          " & "
        )}. Your changes might be lost.`;
      }
      if (typeof e === "string") {
        errorText = e;
      } else {
        e.message = { message: errorText };
      }
      userPersistStore.pushError(e);
      row.errorText = errorText;
    })
    .finally(() => {
      // console.log("updateField done");
      row.isUpdating = false;
      //validate
      // console.log("changed fields:", fieldNames);
      new WebSocketHelper().wsSend({
        command: "updateTask",
        id: row.id,
        companyGuid: row.companyGuid,
      });
    });
}

async function updateDate(row) {
  row.isUpdating = true;
  row.errorText = false;

  const oldData = copyObject(row);

  const bufferedValue = userStore.fieldUpdateBuffer.find(
    (x) => x.row.id === row.id
  );
  if (bufferedValue) {
    clearTimeout(bufferedValue.callback);
    userStore.fieldUpdateBuffer = userStore.fieldUpdateBuffer.filter(
      (x) => x.row.id !== id
    );
  }
  const sortIndex = userPersistStore.userSettings.taskOrder.indexOf(oldData.id);

  if (row.status === "Submitted") {
    await bcPersistStore.updateDynamicsData({
      companyGuid: row.companyGuid,
      urlSegment: "timeRegistrationEntries",
      id: row.id,
      stateName: "myTasks",
      fieldNames: ["status"],
      fieldValues: ["Open"],
    });
  }
  // console.log(userPersistStore.userSettings.taskOrder);
  // console.log("new date", row);
  bcPersistStore
    .deleteDynamicsData({
      companyGuid: row.companyGuid,
      urlSegment: "timeRegistrationEntries",
      id: row.id,
      stateName: "myTasks",
    })
    .then(() => {
      new WebSocketHelper().wsSend({
        command: "deleteTask",
        id: row.id,
        companyGuid: row.companyGuid,
      });

      const newData = {
        date: oldData.date,
        jobNo: oldData.jobNo,
        jobTaskNo: oldData.jobTaskNo,
        jobPlanningLineLineNo: oldData.jobPlanningLineLineNo,
        resourceNo: oldData.resourceNo,
        quantity: oldData.quantity,
        timeSheetDescription: oldData.timeSheetDescription,
        itemNo: oldData.itemNo,
        workType: oldData.workType,
        status: oldData.status,
        timeSheetBeginTime: oldData.timeSheetBeginTime,
        notQuoted: oldData.notQuoted,
      };

      bcPersistStore
        .postDynamicsData({
          urlSegment: "timeRegistrationEntries",
          companyGuid: oldData.companyGuid,
          data: newData,
          stateName: "myTasks",
        })
        .then((r) => {
          new WebSocketHelper().wsSend({
            command: "addTask",
            id: r.id,
            companyGuid: r.companyGuid,
          });

          const newTask = bcPersistStore.myTasks.find((t) => t.id === r.id);
          bcPersistStore.injectMissingTaskFields(newTask);
          bcPersistStore.validateTaskFields(newTask);

          userPersistStore.userSettings.taskOrder.splice(
            userPersistStore.userSettings.taskOrder.length - 1,
            1
          );
          userPersistStore.userSettings.taskOrder.splice(sortIndex, 0, r.id);
          // console.log(userPersistStore.userSettings.taskOrder);
        })
        .catch((error) => {
          userPersistStore.pushError(error);
        })
        .finally(() => {
          //newTask.isAdding = false;
        });
    })
    .catch(() => {
      row.errorText = "Error while deleting old task.";
    })
    .finally(() => {
      // console.log("updateField done");
      row.isUpdating = false;
    });
}

//Returns how long the task has been running since last save (in seconds)
function getRunningTaskTime(beginTime) {
  return Math.round(
    (new Date().getTime() - new Date(beginTime).getTime()) / 1000
  );
}

function taskIsDisabled(row) {
  const today = new Date().toISOString().substring(0, 10);
  return (
    (row.date !== today && row.timeSheetBeginTime === NULL_DATE) ||
    disableButtons.value
  );
}

const taskListContainer = ref(null);
watch(
  () => props.myTasks.length,
  (value, oldValue) => {
    if (value > oldValue) {
      nextTick(() => {
        taskListContainer.value.scrollTop =
          taskListContainer.value.scrollHeight;
      });
    }
  }
);

function getDescriptionForItem(task) {
  if (task.itemNo) {
    if (task.notQuoted) {
      const item = bcPersistStore.getItemForItemNo(
        task.itemNo,
        task.companyGuid
      );
      if (item && item.description) {
        return item.description;
      }
      return task.itemNo;
    } else {
      const myJob = bcPersistStore?.jobs.find((j) => j.systemId === task.jobId);
      if (!myJob) return "No job found";

      const myPlaningLine = myJob?.jobPlanningLines
        ? myJob?.jobPlanningLines.find(
            (pl) =>
              pl.jobTaskNo === task.jobTaskNo &&
              pl.lineNo === task.jobPlanningLineLineNo
          )
        : false;
      if (!myPlaningLine) return "No planning line found";
      return myPlaningLine.description;
      //return `${task?.itemNo} `;
    }
  }
  return "Select activity";
}

function onDrop(e, job = true) {
  e.preventDefault();
  try {
    const dropData = JSON.parse(e.dataTransfer.getData("text/plain"));
    if (dropData === "meh" && !job) {
      emit("meh");
    } else if (dropData.systemId) {
      emit("onDrop", dropData);
    }
  } catch (e) {
    // console.log("Invalid drop!");
  }
}

function updateDocumentTitle() {
  const row = props.myTasks.find((i) => i.timeSheetBeginTime !== NULL_DATE);
  // console.log("row", row);
  // const favicon = document.head.getElementsByClassName("favicon")[0];
  if (row) {
    // favicon.setAttribute("href", "/timesheet-record.png");
    if (document.title.includes("⚪")) {
      document.title = `🔴 ${row.billToName} - ${row.jobDescription}`;
    } else {
      document.title = `⚪ ${row.billToName} - ${row.jobDescription}`;
    }
  } else {
    // favicon.setAttribute("href", "/timesheet.png");
    document.title = "B·U·T HR";
  }
}

onMounted(() => {
  bcPersistStore.myTasks.forEach((task) => {
    if (!task.notQuoted) {
      const myJob = bcPersistStore?.jobs.find((j) => j.systemId === task.jobId);
      if (
        !myJob?.jobPlanningLines?.find(
          (pl) =>
            pl.jobTaskNo === task.jobTaskNo &&
            pl.lineNo === task.jobPlanningLineLineNo
        )
      ) {
        bcPersistStore.getDynamicsData({
          urlSegment: `jobs?$filter=systemId eq ${task.jobId} & $expand=jobPlanningLines,jobTasks`,
          stateName: "jobs",
          merge: true,
          mergeWithExistingRows: true,
          companyGuid: task.companyGuid,
          sortOnFields: ["billToName", "jobDescription"],
          replaceStore: false,
          caseSensitive: false,
        });
      }
    }

    //
    const isValid = bcPersistStore.validateTaskFields(task);

    //revalidate incoming tasks for valid status
    if (isValid && task.status === "Open") {
      task.status = "Submitted";
      updateFields(task, ["status"]);
    }
    if (!isValid && task.status === "Submitted") {
      task.status = "Open";
      updateFields(task, ["status"]);
    }
  });
});

clearInterval(window.iidDocTitle);
window.iidDocTitle = setInterval(() => {
  updateDocumentTitle();
}, 1000);
</script>
