import { defineStore } from "pinia";
import * as msal from "@azure/msal-browser";
import { dynamicsRequest, msalConfig, graphRequest } from "../msalConfig";
import axios from "axios";
import { useUserPersistStore } from "@/stores/userPersist";
import { useUserStore } from "@/stores/user.js";
import * as Sentry from "@sentry/vue";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    myMSALObj: null,
    user: null,
    userPic: null,
    accountId: "",
    signInType: "popup",
  }),
  getters: {
    isAuthenticated(state) {
      // console.log("isAuthenticated");
      const currentAcc = state.myMSALObj?.getAccountByHomeId(state.accountId);
      return !!currentAcc;
    },
    userInitials(state) {
      if (state.user?.name) {
        const allFirstChars = state.user.name.split(" ").map((s) => s[0]);
        if (allFirstChars.length > 2) {
          return allFirstChars[0] + allFirstChars[allFirstChars.length - 1];
        } else {
          return allFirstChars.join("");
        }
      } else {
        return "?";
      }
    },
  },
  actions: {
    async initAuth() {
      // console.log("initAuth");
      this.myMSALObj = new msal.PublicClientApplication(msalConfig);
      await this.myMSALObj.initialize();
      await this.myMSALObj
        .handleRedirectPromise()
        .then(async (data) => {
          await this.handleResponse(data);
        })
        .catch((error) => {
          console.error(error);
          useUserPersistStore().pushError({
            message: "Error while initializing authorization",
            error,
          });
        });
    },

    async signIn() {
      if (this.signInType === "popup") {
        return this.myMSALObj
          .loginPopup(dynamicsRequest)
          .then(async (data) => {
            await this.handleResponse(data);
          })
          .catch(function (error) {
            console.log(error);
            useUserPersistStore().pushError({
              message: "Error while logging in.",
              error,
            });
          });
      } else if (this.signInType === "redirect") {
        return this.myMSALObj.loginRedirect(dynamicsRequest);
      }
    },
    signOut() {
      const logoutRequest = {
        account: this.myMSALObj.getAccountByHomeId(this.accountId),
        mainWindowRedirectUri: document.location.origin,
      };

      if (this.signInType === "popup") {
        this.myMSALObj.logoutPopup(logoutRequest).then(() => {
          window.location.reload();
        });
      } else {
        this.myMSALObj.logoutRedirect(logoutRequest);
      }
    },

    async getTokenPopup({ request = dynamicsRequest }) {
      // console.log("getTokenPopup");
      return await this.myMSALObj
        .acquireTokenSilent(request)
        .catch(async (error) => {
          console.log("silent token acquisition fails.");
          if (error instanceof msal.InteractionRequiredAuthError) {
            console.log("acquiring token using popup");
            return this.myMSALObj.acquireTokenPopup(request).catch((error) => {
              console.error(error);
              useUserPersistStore().pushError({
                message: "Error while acquiring token popup",
                error,
              });
            });
          } else {
            console.error(error);
            useUserPersistStore().pushError({
              message:
                "Error while getting token popup (Bad response from microsoft)",
              error,
            });
          }
        });
    },

    async getProfilePicture() {
      const userStore = useUserStore();
      if (this.isAuthenticated && !userStore.profilePictureLoaded) {
        const response = await this.getTokenPopup({
          request: graphRequest,
        }).catch((error) => {
          console.log("error", error);
          useUserPersistStore().pushError({
            message: "Error while getting token popup for profile picture",
            error,
          });
        });

        let url = "https://graph.microsoft.com/v1.0/me/photos/64x64/$value";
        await axios
          .get(url, {
            headers: {
              "Content-Type": "image/jpg",
              Authorization: "Bearer " + response.accessToken, //the token is a variable which holds the token
              "Data-Access-Intent": "ReadOnly",
            },
            responseType: "blob",
            data: {},
          })
          .then((res) => {
            const reader = new window.FileReader();
            reader.readAsDataURL(res.data);
            reader.onload = () => {
              this.userPic = reader.result;
              userStore.profilePictureLoaded = true;
            };
          })
          .catch((error) => {
            if (error.response.status === 404) {
              console.log("No profile picture set for user");
              userStore.profilePictureLoaded = true;
            } else {
              useUserPersistStore().pushError({
                message: "Error while getting profile picture",
                error,
              });
            }
          });
      }
    },

    async handleResponse(resp) {
      // console.log("handleResponse", resp);
      if (resp !== null) {
        this.user = resp.account;
        this.accountId = resp.account.homeAccountId;
        this.myMSALObj.setActiveAccount(resp.account);
        // console.log("got response");
      } else {
        // need to call getAccount here?
        const currentAccounts = this.myMSALObj.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
          const request = {
            scopes: ["User.Read"],
          };
          try {
            const loginResponse = await this.myMSALObj.ssoSilent(request);
            const activeAccount = loginResponse.account;
            this.user = activeAccount;
            this.accountId = activeAccount.homeAccountId;
            this.myMSALObj.setActiveAccount(activeAccount);
            console.log("%c SSO login success!", "color: #00FF00");
          } catch (err) {
            console.log("SSO Failed", err);
            return;
          }
        } else if (currentAccounts.length > 1) {
          // Add choose account code here
          useUserPersistStore().pushError({
            message: "Too many accounts: " + JSON.stringify(currentAccounts),
          });
        } else if (currentAccounts.length === 1) {
          const activeAccount = currentAccounts[0];
          this.user = activeAccount;
          this.accountId = activeAccount.homeAccountId;
          this.myMSALObj.setActiveAccount(activeAccount);
          // console.log("handleResponse: ", activeAccount);
        }
      }
      if (this.isAuthenticated) {
        Sentry.setUser({ username: this.user.name, email: this.user.username });
        await this.getProfilePicture();
      }
    },
  },
});
