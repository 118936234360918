<template>
  <div class="flex w-full items-center justify-center">
    <div class="sm:rounded-lg sm:bg-white p-16 sm:shadow-2xl">
      <div
        class="mb-8 text-2xl font-semibold text-black text-center flex items-center flex-col"
      >
        Welcome to the
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 87.1 20"
          xml:space="preserve"
          class="w-20 mt-3 mb-2"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" x="0" y="0" width="87.1" height="34.9" />
            </defs>
            <clipPath id="SVGID_2_">
              <use xlink:href="#SVGID_1_" overflow="visible" />
            </clipPath>
            <path
              clip-path="url(#SVGID_2_)"
              fill="#1E1B1E"
              d="M6.2,11v4.2H10c0.9,0,1.6-0.2,2.1-0.5c0.5-0.3,0.7-0.9,0.7-1.5
		c0-0.7-0.2-1.2-0.7-1.6C11.6,11.2,10.9,11,10,11H6.2z M6.2,3.9v4h3.4c0.8,0,1.4-0.2,1.8-0.5C11.8,7,12,6.5,12,5.9
		c0-0.7-0.2-1.1-0.6-1.5c-0.4-0.3-1-0.5-1.8-0.5H6.2z M0,19V0h11.8c1.4,0,2.4,0.1,3.1,0.2c0.6,0.1,1.2,0.3,1.7,0.6
		c0.7,0.4,1.2,0.9,1.5,1.5c0.3,0.7,0.5,1.4,0.5,2.3c0,1.1-0.3,2-0.9,2.6c-0.6,0.7-1.6,1.2-2.9,1.6c1.6,0.4,2.8,1,3.5,1.8
		c0.7,0.8,1.1,1.9,1.1,3.2c0,1.7-0.5,3-1.5,3.8c-1,0.9-2.5,1.3-4.5,1.3H0z"
            />
            <path
              clip-path="url(#SVGID_2_)"
              fill="#1E1B1E"
              d="M33.8,0h6.3v10.8c0,1.6,0.4,2.7,1.1,3.5c0.7,0.8,1.8,1.1,3.3,1.1
		c1.5,0,2.6-0.4,3.3-1.2c0.7-0.8,1.1-1.9,1.1-3.5V0h6.1v10.7c0,3-0.9,5.1-2.6,6.5c-1.7,1.4-4.4,2.1-8.2,2.1c-3.7,0-6.4-0.7-8-2
		c-1.7-1.4-2.5-3.5-2.5-6.5V0z"
            />
          </g>
          <g>
            <path
              fill="#1E1B1E"
              d="M0,19V0h11.8c1.4,0,2.4,0.1,3.1,0.2c0.6,0.1,1.2,0.3,1.7,0.6c0.7,0.4,1.2,0.9,1.5,1.5
		c0.3,0.7,0.5,1.4,0.5,2.3c0,1.1-0.3,2-0.9,2.6C17,7.9,16,8.5,14.7,8.9c1.6,0.4,2.8,1,3.5,1.8c0.7,0.8,1.1,1.9,1.1,3.2
		c0,1.7-0.5,3-1.5,3.8c-1,0.9-2.5,1.3-4.4,1.3H0z M6.2,3.9v4h3.3c0.8,0,1.4-0.2,1.8-0.5c0.4-0.3,0.6-0.8,0.6-1.5
		c0-0.7-0.2-1.1-0.6-1.5c-0.4-0.3-1-0.5-1.8-0.5H6.2z M6.2,11v4.1H10c0.9,0,1.6-0.2,2.1-0.5c0.5-0.3,0.7-0.9,0.7-1.5
		c0-0.7-0.2-1.2-0.7-1.5C11.6,11.2,10.9,11,10,11H6.2z"
            />
            <path
              fill="#1E1B1E"
              d="M33.7,0H40v10.8c0,1.6,0.4,2.7,1.1,3.5c0.7,0.8,1.8,1.1,3.3,1.1c1.5,0,2.6-0.4,3.3-1.2
		c0.7-0.8,1.1-1.9,1.1-3.5V0H55v10.7c0,3-0.9,5.1-2.6,6.5c-1.7,1.4-4.4,2.1-8.2,2.1c-3.7,0-6.4-0.7-8-2c-1.7-1.4-2.5-3.5-2.5-6.5V0z
		"
            />
            <path
              fill="#1E1B1E"
              d="M73.4,19V4.2h-7.1V0h20.6v4.2h-7.1V19H73.4z"
            />
          </g>
          <path
            fill="#C1001F"
            d="M26.6,13.1c1.6,0,2.8-1.3,2.8-2.9c0-1.6-1.3-2.9-2.8-2.9c-1.6,0-2.9,1.3-2.9,2.9
	C23.7,11.8,25,13.1,26.6,13.1z"
          />
          <path
            fill="#C1001F"
            d="M62.8,13.1c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9
	C60,11.8,61.3,13.1,62.8,13.1z"
          />
        </svg>
        <div>HR App</div>
      </div>
      <div v-if="!authStore.isAuthenticated">
        <p>Please log in with your Microsoft account.</p>
        <button
          @click="login()"
          class="btn mt-4 bg-primary uppercase text-white"
        >
          Log in
        </button>
      </div>
      <div v-else class="flex items-center gap-4">
        <p class="text-lg">Loading data ...</p>
        <Spinner :size="16" color="black" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/auth.js";
import { useRouter } from "vue-router";
import Spinner from "@/components/Spinner.vue";

const router = useRouter();
const authStore = useAuthStore();

async function login() {
  await authStore.signIn();
  router.push({ name: "Home" });
}
</script>
