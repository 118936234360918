<template>
  <div
    class="flex max-h-full w-full flex-grow grid-cols-1 flex-col sm:grid sm:gap-2 lg:gap-6 xl:grid-cols-2"
    v-if="userStore.projectsAreLoaded">
    <div
      class="flex w-full items-center border-gray-200 bg-primary-700 sm:hidden sm:border-b-1">
      <div
        @click="selectedTab = 0"
        :class="selectedTab === 0 ? 'border-white' : 'border-transparent'"
        class="text-md w-1/2 cursor-pointer border-b-4 pb-4 text-center font-bold uppercase text-white">
        Projects
      </div>
      <div
        @click="selectedTab = 1"
        :class="selectedTab === 1 ? 'border-white' : 'border-transparent'"
        class="text-md w-1/2 cursor-pointer border-b-4 pb-4 text-center font-bold uppercase text-white">
        Tasks
      </div>
    </div>
    <ProjectList
      class="sm:flex"
      :class="{ hidden: selectedTab === 1 }"
      :projects="bcPersistStore?.jobs"
      @onAddClick="onAddProjectClick"></ProjectList>
    <MyTasksList
      :class="{ hidden: selectedTab === 0 }"
      class="sm:flex"
      :myTasks="bcPersistStore?.myTasks"
      @onDrop="onAddProjectClick"
      @meh="meh" />
    <img
      v-if="startMeh"
      class="w-50 absolute bottom-0 right-0 max-w-3xl"
      src="/meh/something.gif"
      alt="" />
  </div>
  <transition name="fade">
    <div
      v-if="!userStore.projectsAreLoaded"
      class="absolute left-0 top-0 flex h-full w-full">
      <div
        class="flex flex-grow items-center justify-center gap-3 p-4 text-center text-gray-500">
        <spinner color="#CCC" :size="16" />
        <p>Loading ...</p>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { NULL_DATE, useBcPersistStore } from "@/stores/bcPersist";
import ProjectList from "@/components/ProjectList.vue";
import MyTasksList from "@/components/MyTasksList.vue";
import { useUserPersistStore } from "@/stores/userPersist.js";
import { onMounted, ref } from "vue";
import Spinner from "@/components/Spinner.vue";
import WebSocketHelper from "@/utils/WebSocketHelper.js";
import { useUserStore } from "@/stores/user.js";
import { useRouter } from "vue-router";
import * as Sentry from "@sentry/vue";

const bcPersistStore = useBcPersistStore();
const router = useRouter();
// const userPersist = useUserPersistStore();
const selectedTab = ref(0);
// const loadingJobsDone = ref(bcPersistStore.jobs.length > 0);
const userStore = useUserStore();
const startMeh = ref(false);

function meh() {
  if (!startMeh.value) {
    let audio = new Audio("/meh/Beeee.wav");
    audio.play();
    startMeh.value = true;
    setTimeout(() => {
      audio.pause();
      audio.currentTime = 0;
      startMeh.value = false;
    }, 11200);
  }
}

async function onAddProjectClick(payload) {
  // console.log("onAddProjectClick", payload.row);
  if (payload.isAdding) {
    return;
  }
  payload.isAdding = true;

  const userPersist = useUserPersistStore();

  const data = {
    date: new Date().toISOString().substring(0, 10),
    jobNo: payload.no,
    // jobTaskNo: "M0020", //todo: pick from list / get from metadata
    jobPlanningLineLineNo: "0", //todo: this is now reqired but should be calculated by BC?
    resourceNo: userPersist.linkedResource.no,
    quantity: Math.round(userPersist.userSettings.storedIdleTime * 100) / 100,
    timesheetDescription: ``,
    itemNo: "",
    workType: "", //todo: get from metadata
    status: "Open",
    // status: "Submitted",
    timeSheetBeginTime: NULL_DATE,
    notQuoted: true,
  };

  payload.errorText = false;
  bcPersistStore
    .postDynamicsData({
      urlSegment: "timeRegistrationEntries",
      companyGuid: payload.companyGuid,
      data,
      stateName: "myTasks",
    })
    .then((r) => {
      const task = bcPersistStore.myTasks.find((t) => t.id === r.id);
      bcPersistStore.injectMissingTaskFields(task);
      if (userPersist.userSettings.storedIdleTime !== 0) {
        userPersist.userSettings.storedIdleTime = 0;
        bcPersistStore.putResourceSettings({ storedIdleTime: 0 }).then((e) => {
          new WebSocketHelper().wsSend({
            command: "updateStoredIdleTime",
            idleTime: 0,
          });
        });
      }
      if (!userPersist.userSettings.taskOrder.includes(task.id)) {
        userPersist.userSettings.taskOrder.push(task.id);
      }
      new WebSocketHelper().wsSend({
        command: "addTask",
        id: task.id,
        companyGuid: task.companyGuid,
      });
    })
    .catch((e) => {
      Sentry.captureException(e);
      payload.errorText = "Error while adding task.";
    })
    .finally(() => {
      selectedTab.value = 1;
      payload.isAdding = false;
    });
}

onMounted(() => {
  if (
    bcPersistStore.myTasks.length > 0 ||
    router.currentRoute.value.params.jobNo
  ) {
    selectedTab.value = 1;
  }

  if (router.currentRoute.value.params.jobNo) {
    const job = bcPersistStore.jobs.find(
      (x) => x.no === router.currentRoute.value.params.jobNo
    );
    console.log(job);
    if (job) {
      onAddProjectClick(job);
    }
    router.replace("/open-tasks");
  }
});
</script>
