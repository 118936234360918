<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Popover class="relative" v-slot="{ open }">
    <PopoverButton
      :class="[
        open ? 'text-gray-900' : 'text-gray-500',
        'flex max-w-xs items-center rounded-md bg-primary-700 text-sm focus:outline-none lg:p-1',
      ]"
    >
      <!-- avatar-->
      <img
        v-if="authStore.isAuthenticated && authStore.userPic"
        class="h-6 w-6 rounded-full"
        :src="authStore.userPic"
        alt=""
      />
      <div
        v-if="authStore.isAuthenticated && !authStore.userPic"
        class="h-7 w-7 rounded-full bg-white py-1 text-center text-primary"
      >
        {{ authStore.userInitials }}
      </div>

      <CogIcon class="h-6 w-6 rounded-full" v-if="!authStore.isAuthenticated"
        >Log In
      </CogIcon>
      <ChevronDownIcon
        :class="[
          open ? 'rotate-180 duration-300' : 'rotate-0 duration-300',
          'ml-2 h-5 w-5 text-white group-hover:text-gray-300',
        ]"
        aria-hidden="true"
      />
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <PopoverPanel
        class="absolute right-0 z-10 z-user-menu mt-3 w-80 transform px-2 sm:px-0"
      >
        <div
          class="z-user-menu rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div
            v-if="authStore.isAuthenticated"
            class="relative grid gap-2 bg-white px-5 py-6 text-gray-600 sm:gap-4 sm:p-8"
          >
            <div class="flex items-center gap-2">
              <UserIcon class="h-6 w-6 flex-shrink-0 text-gray-400" />

              <p class="whitespace-nowrap text-2xl font-light text-primary-500">
                {{ authStore.user?.name }}
              </p>
            </div>
            <div class="flex items-center gap-2">
              <AtSymbolIcon class="h-6 w-6 flex-shrink-0 text-gray-400" />
              <p>{{ authStore.user?.username }}</p>
            </div>
            <div class="z-10 flex flex-col gap-1">
              <div class="flex items-center gap-2">
                <LinkIcon class="h-6 w-6 flex-shrink-0 text-gray-400" />
                <p>Linked Business Central resource:</p>
              </div>

              <div
                class="ml-6 pt-2"
                v-if="
                  userPersistStore?.actualResource?.timeSheetUserType?.toLowerCase() ===
                  'admin'
                "
              >
                <DropdownComponent
                  :items="bcPersistStore.activeResources"
                  :modelValue="userPersistStore.linkedResource"
                  @update:modelValue="changeResource"
                  :label-attributes="['name', 'companyDisplayName']"
                  no-selection-label="Select a resource"
                />
              </div>
              <p class="ml-8 font-bold" v-else>
                {{ userPersistStore.linkedResource?.name }}
              </p>
            </div>
            <p class="ml-6">
              <span class="font-semibold">Timesheet user type:</span>
              {{ userPersistStore.linkedResource.timeSheetUserType }}
            </p>
            <p class="ml-6">
              <span class="font-semibold">Approval admin:</span>
              {{ hrStore.isAbsenceApprovalByPlanner ? "Yes" : "No" }}
            </p>
            <p class="ml-6">
              <span class="font-semibold">Absence approval admin:</span>
              {{ hrStore.isAbsenceApprovalByPlanner ? "Yes" : "No" }}
            </p>
            <div class="mt-2 flex items-center gap-2">
              <ClockIcon class="h-6 w-6 flex-shrink-0 text-gray-400" />
              <p>Idle detection</p>
              <BryntumSlideToggle
                :height="20"
                :checked="userPersistStore.enableIdleDetection"
                @change="setIdleDetection($event.value)"
                color="b-orange"
                cls="ml-auto"
              ></BryntumSlideToggle>
            </div>
            <div class="flex items-center gap-2">
              <BellIcon class="h-6 w-6 flex-shrink-0 text-gray-400" />
              <p>Idle notifications</p>
              <BryntumSlideToggle
                :height="20"
                :checked="userPersistStore.idleNotifications"
                @change="setIdleNotifications($event.value)"
                color="b-orange"
                cls="ml-auto"
              ></BryntumSlideToggle>
            </div>
            <div class="flex items-center gap-2">
              <CalendarIcon class="h-6 w-6 flex-shrink-0 text-gray-400" />
              <p>Vertical planning</p>
              <BryntumSlideToggle
                :height="20"
                :checked="userPersistStore.userSettings.verticalCalendar"
                @change="setVerticalCalendar($event.value)"
                color="b-orange"
                cls="ml-auto"
              ></BryntumSlideToggle>
            </div>
          </div>
          <div
            class="space-y-6 bg-gray-50 px-5 py-5 sm:flex sm:space-x-10 sm:space-y-0 sm:px-8"
          >
            <div class="flow-root" v-if="!authStore.isAuthenticated">
              <button
                @click="authStore.signIn()"
                class="-m-3 flex items-center rounded-md p-3 font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100"
              >
                <UserIcon
                  class="h-6 w-6 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span class="ml-3 whitespace-nowrap">Log in</span>
              </button>
            </div>
            <div class="flow-root" v-if="authStore.isAuthenticated">
              <button
                @click="authStore.signOut()"
                class="-m-3 flex items-center rounded-md p-3 font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100"
              >
                <UserIcon
                  class="h-6 w-6 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span class="ml-3 whitespace-nowrap">Log out</span>
              </button>
            </div>
            <div class="absolute bottom-2 right-2 text-xs text-gray-500">
              version {{ $buildVersion }}
            </div>
            <!--            <div class="flow-root">-->
            <!--              <button-->
            <!--                class="-m-3 flex items-center rounded-md p-3 font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100"-->
            <!--              >-->
            <!--                <CogIcon-->
            <!--                  class="h-6 w-6 flex-shrink-0 text-gray-400"-->
            <!--                  aria-hidden="true"-->
            <!--                />-->
            <!--                <span class="ml-3 whitespace-nowrap">Settings</span>-->
            <!--              </button>-->
            <!--            </div>-->
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
// import { ref } from "vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import {
  UserIcon,
  CogIcon,
  AtSymbolIcon,
  LinkIcon,
  ClockIcon,
  CalendarIcon,
  BellIcon,
} from "@heroicons/vue/solid";
import { useAuthStore } from "../stores/auth";
// import DropdownComponent from "@/components/DropdownComponent.vue";
import { useBcPersistStore } from "@/stores/bcPersist.js";
import {
  DEFAULT_USER_SETTINGS,
  useUserPersistStore,
} from "@/stores/userPersist.js";
import { BryntumSlideToggle } from "@bryntum/scheduler-vue-3";
import { useIdleStore } from "@/stores/idle.js";
import { useUserStore } from "@/stores/user.js";
import { useHRStore } from "@/stores/hr";
import router from "@/router/router.js";
import { nextTick } from "vue";

const authStore = useAuthStore();
const bcPersistStore = useBcPersistStore();
const hrStore = useHRStore();
const userPersistStore = useUserPersistStore();
const idleStore = useIdleStore();

function setIdleDetection(enable) {
  userPersistStore.enableIdleDetection = enable;
  if (enable) {
    if (idleStore.checkIdleDetectionSupport()) {
      idleStore.askPermissions();
    }
  } else {
    userPersistStore.enableIdleDetection = false;
  }
}
function setVerticalCalendar(enable) {
  userPersistStore.userSettings.verticalCalendar = enable;
  bcPersistStore.putResourceSettings({
    verticalCalendar: userPersistStore.userSettings.verticalCalendar,
  });
  if (router.currentRoute.value.name === "Planning") {
    useUserStore().updateKey++;
  }
}

function setIdleNotifications(enable) {
  userPersistStore.idleNotifications = enable;
}

function changeResource(resource) {
  userPersistStore.linkedResource = resource;
  userPersistStore.userSettings = DEFAULT_USER_SETTINGS;
  nextTick(() => {
    location.reload();
  });
}
</script>
