import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      resourcesAreLoaded: false,
      projectsAreLoaded: false,
      jobsAreLoaded: false,
      resourcesAreLoading: false,
      profilePictureLoaded: false,
      routerLoading: false,
      updateKey: 0,
      loadingCount: 0,
      savingCount: 0,
      eventChangeBuffer: [],
      fieldUpdateBuffer: [],
      committedTasksLowerDate: new Date().toISOString().substring(0, 10),
      committedTasksUpperDate: new Date().toISOString().substring(0, 10),
      wsConnected: false,
    };
  },
  getters: {},
  actions: {},
});
