<template>
  <div class="input-time-wrapper relative">
    <input
      ref="inputEl"
      type="tel"
      class="input-time table-tasks-list-input input-time focus:ring-gray-400"
      :class="$attrs.disabled ? 'text-gray-disabled' : ''"
      :value="formattedTime"
      @change="onChange($event.target.value)"
      v-bind="$attrs"
      v-mask="[negativeOrPositiveSign + '##:##']"
      @keydown="onKeyDown"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed, ref } from "vue";
import { debounce, quantityToTime, timeToQuantity } from "@/utils/Utils.js";
import { mask } from "vue-the-mask";
import { useUserPersistStore } from "@/stores/userPersist";

const inputEl = ref();
const vMask = mask;
const props = defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue"]);
const isAdmin =
  useUserPersistStore().linkedResource.timeSheetUserType.toLowerCase() ===
  "admin";
let signFromPropsValue = props.modelValue >= 0 ? "+" : "-";
if (!isAdmin && signFromPropsValue === "+") {
  signFromPropsValue = "";
}
const negativeOrPositiveSign = ref(signFromPropsValue);

const debouncedEmit = debounce((value) => {
  value = Math.min(23.98, value);
  console.log("emit", value);
  emits("update:modelValue", value);
}, 200);

const formattedTime = computed({
  get() {
    // console.log("props.modelValue", props.modelValue);
    return quantityToTime(props.modelValue);
  },
  set(value) {
    if (value) {
      debouncedEmit(timeToQuantity(value));
      // emits("update:modelValue", value);
    }
  },
});

function onChange(value) {
  //remove the + sign
  value = value.split("+").join("");
  // console.log("change", value);
  if (!value) {
    value = "00:00";
  }
  console.log("onChange", value, timeToQuantity(value));
  debouncedEmit(timeToQuantity(value));
  // emits("update:modelValue", timeToQuantity(value));
}

function onKeyDown(e) {
  if (isAdmin && ["+", "-"].includes(e.key)) {
    negativeOrPositiveSign.value = e.key;
    e.preventDefault = true;
    console.log("Gert: formattedTime.value:", e.target.value);

    let newValue = e.target.value;
    newValue = newValue.split("-").join("");
    newValue = newValue.split("+").join("");
    newValue = negativeOrPositiveSign.value + newValue;
    onChange(newValue);
  }
}
</script>
