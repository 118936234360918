import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router/router";

import "./css/index.scss";
import Notifications from "@kyvg/vue3-notification";

import { DateHelper } from "@bryntum/scheduler";
import version from "@/../version.json";

const storedVersion = localStorage.getItem("but.ts.build.version");
if (storedVersion === "") {
  localStorage.setItem("but.ts.build.version", version.version);
} else if (storedVersion !== version.version) {
  console.warn(
    `New build version! (${version.version}) Old cookies will be cleared.`
  );
  localStorage.clear();
  localStorage.setItem("but.ts.build.version", version.version);
}

const app = createApp(App);
app.config.globalProperties.$buildVersion = version.version;
// //TODO DISABLE IN BUILD!
// app.config.performance = true;

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
  // store.currentRoute = markRaw(router.currentRoute);
});

Sentry.init({
  app,
  dsn: "https://3f1a3b8a6f2682bdda193cfaba3dd558@o4506200783978496.ingest.sentry.io/4505640054816768",
  environment: import.meta.env.MODE,
  enabled: process.env.NODE_ENV !== "development",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(pinia);
app.use(Notifications);

app.use(router);
app.mount("#app");

DateHelper.locale = "en-NL"; //will be overridden with the users locale once logged in //en-CH, en-AE,en-SG

// setTimeout(() => {
//   useAuthStore()
//     .initAuth()
//     .then(() => {
//       console.log("init auth done");
//     });
// }, 500);
