<template>
  <div class="inline-flex h-3 w-3 flex-shrink-0 drop-shadow">
    <img :src="flagIcon" :alt="`flag icon ${companyName}`" class="h-3 w-3" />
  </div>
</template>

<script>
// use normal <script> block to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed } from "vue";
import { useBcPersistStore } from "@/stores/bcPersist.js";
import flagBE from "@/assets/flags/be.svg";
import flagEA from "@/assets/flags/ae.svg";
import flagSG from "@/assets/flags/sg.svg";
import flagCH from "@/assets/flags/ch.svg";

const props = defineProps(["companyGuid"]);
const bcPersistStore = useBcPersistStore();

const companyName = computed(() => {
  return bcPersistStore.companies.find((x) => x.id === props.companyGuid)?.name;
});

const flagIcon = computed(() => {
  switch (companyName.value) {
    case "BUT NV (BELGIUM)":
      return flagBE;
    case "BUT NV (UAE)":
      return flagEA;
    case "BUT PTE LTD":
      return flagSG;
    case "BUT.CH SA":
      return flagCH;
    default:
      return flagBE;
  }
});
</script>
