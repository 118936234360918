<template>
  <div
    class="absolute bottom-20 left-1/2 z-reload-prompt flex h-10 w-80 -translate-x-1/2 items-center justify-between rounded-lg bg-warning p-2 text-white shadow-md"
    v-if="needRefresh">
    <span>New version available!</span>
    <div
      @click="reloadWorker()"
      class="cursor-pointer p-1 font-medium underline underline-offset-2">
      Reload
    </div>
  </div>
</template>

<script setup>
import { useRegisterSW } from "virtual:pwa-register/vue";
import { useIdleStore } from "@/stores/idle";
const idleStore = useIdleStore();

const { needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    if (r) {
      idleStore.swRegistration = r;
    }
  },
});

function reloadWorker() {
  updateServiceWorker();
  needRefresh.value = false;
}
</script>

<style scoped></style>
