import { defineStore } from "pinia";
import { useAuthStore } from "./auth";
import axios from "axios";
import { msalConfig } from "../msalConfig";
import { useStorage } from "@vueuse/core";
import { useUserPersistStore } from "@/stores/userPersist.js";
import { notify } from "@kyvg/vue3-notification";
import { useIdleStore } from "@/stores/idle.js";
import { debounce, quantityToTime } from "@/utils/Utils.js";
import { useUserStore } from "@/stores/user.js";
import { bcEnvironment } from "@/../version.json";
import WebSocketHelper from "@/utils/WebSocketHelper.js";
import axiosRetry from "axios-retry";
import * as Sentry from "@sentry/vue";

// axiosRetry(axios, { retries: 4, retryDelay: axiosRetry.exponentialDelay });
axiosRetry(axios, {
  retries: 4,
  retryDelay: () => 1500,
  retryCondition: (error) => {
    if (error.response?.status === 404) {
      return false;
    }
    return true;
  },
  onRetry: (retryCount, error, requestConfig) => {
    console.log(
      "Axios-retry",
      "retryCount",
      retryCount,
      "error",
      error,
      "requestConfig",
      requestConfig
    );
  },
});

export const NULL_DATE = "1970-01-01T00:00:00Z";

const debouncedPutResourceSettings = debounce(() => {
  useBcPersistStore().putResourceSettingsAfterDebounce();
}, 2500);

export const useBcPersistStore = defineStore({
  id: "bcPersist",
  state: () =>
    useStorage(
      `timesheet-bcPersist-${bcEnvironment}`,
      {
        resources: [],
        companies: [],
        jobs: [],
        myTasks: [],
        committedTasks: [],
        items: [],
        scheduleEvents: [],
        lastJobFetchDate: NULL_DATE,
        lastRecourseFetchDate: NULL_DATE,
        lastProjectFavCleanDate: 0,
        lastCleanJobFetch: 0,
        lastCleanResourceFetch: 0,
        NULL_DATE: "1970-01-01T00:00:00Z",
        resourceChangeBuffer: null,
        holidays: [],
      },
      null,
      { mergeDefaults: true }
    ),
  getters: {
    getCompanyDisplayNameForGuid() {
      return (companyGuid) => {
        return this.companies.find((c) => c.id === companyGuid).displayName;
      };
    },
    getBaseUnitFromTaskNo() {
      return (itemNo, companyGuid) => {
        if (!companyGuid) {
          // console.log("!!!!!!!!getBaseUnitFromTaskNo: NO companyGuid");
        }
        if (itemNo && companyGuid) {
          const item = this.getItemForItemNo(itemNo, companyGuid);
          if (item && item.baseUnitOfMeasure) {
            return item.baseUnitOfMeasure;
          }
          //TODO give message here
          return itemNo;
        } else return "HOUR";
      };
    },
    getItemForItemNo() {
      return (itemNo, companyGuid) => {
        if (!companyGuid) {
          // console.log("!!!!!!!!getItemForItemNo: NO companyGuid");
        }
        return this.items.find(
          (i) => i.no === itemNo && companyGuid === i.companyGuid
        );
      };
    },
    itemsForCompany() {
      return (companyGuid) => {
        return this.items.filter((i) => i.companyGuid === companyGuid);
      };
    },
    activeTask: (state) => {
      return state.myTasks.find(
        (x) => x.timeSheetBeginTime !== state.NULL_DATE
      );
    },
    selectedPlanningCompany: (state) => {
      const userPersist = useUserPersistStore();
      const companyGuid = state.companies.find(
        (x) => x.name === userPersist.planning.selectedCompany
      )?.id;
      return companyGuid;
    },
    holidaysForCompany: (state) => {
      return state.holidays.filter(
        (x) => x.companyGuid === this?.selectedPlanningCompany
      );
    },
    lastHolidayChangeDate: (state) => {
      return new Date(
        Math.max(
          ...state.holidaysForCompany.map((x) => new Date(x.systemModifiedAt)),
          0
        )
      );
    },
    lastHolidayChangeDateForAllCompanies: (state) => {
      let dates = {};
      state.companies.forEach((company) => {
        dates[company.id] = new Date(
          Math.max(
            ...state.holidays
              .filter((x) => x.companyGuid === company.id)
              .map((x) => new Date(x.systemModifiedAt)),
            0
          )
        );
      });
      return dates;
    },
    activeResources: (state) => {
      return state.resources.filter((u) => u.timeSheetUserType !== "None");
    },
    getResourceNoForEmployeeNo: (state) => {
      return (employeeNo) => {
        // console.log("getResourceNoForEmployeeNo", employeeNo);
        return state.resources.find((r) =>
          r.employeesPTE[0] ? r.employeesPTE[0].no === employeeNo : null
        )?.no;
      };
    },
  },
  actions: {
    async getDynamicsData({
      urlSegment,
      companyGuid,
      stateName,
      merge = true, //if false, no merging with store values
      mergeWithExistingRows = false, //mergeWithExistingRows: replace new data but leave existing data intact (typically used if only 1 row is fetched
      mergeFieldId = "systemId",
    }) {
      useUserStore().loadingCount++;

      return new Promise(async (resolve, reject) => {
        if (!stateName) {
          stateName = urlSegment;
        }

        // if (stateName === "jobs") {
        //   console.log(
        //     "getDynamicsData:",
        //     "stateName",
        //     stateName,
        //     "merge",
        //     merge,
        //     "mergeFieldId",
        //     mergeFieldId,
        //     "mergeWithExistingRows",
        //     mergeWithExistingRows
        //   );
        // }

        const auth = useAuthStore();
        if (auth.isAuthenticated) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            reject(error);
            useUserStore().loadingCount--;
            useUserPersistStore().pushError(error);
            return;
          });

          if (!response) {
            useUserStore().loadingCount--;
            reject("no token response!");
            return;
          }

          const companySlug = companyGuid ? `companies(${companyGuid})/` : "";
          let url = `${msalConfig.endPoints.dynamicsApi}${companySlug}${urlSegment}`;

          await axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${response.accessToken}`, //the token is a variable which holds the token
                "Accept-Language": "en-US",
                "Data-Access-Intent": "ReadOnly",
              },
            })
            .then((res) => {
              // console.info("getDynamicsData", urlSegment, res);

              //restructure data to fit array of data, even if only one row is returned
              if (!res.data?.value) {
                const tmpRec = { ...res.data };
                res.data.value = [];
                res.data.value.push(tmpRec);
              }

              if (res.data?.value) {
                //inject company fields if companyGuid is provided
                if (companyGuid) {
                  res.data?.value.forEach((r) => {
                    r.companyGuid = companyGuid;
                    r.companyDisplayName =
                      this.getCompanyDisplayNameForGuid(companyGuid);
                  });
                }
                //replace new data but leave existing data intact (typically used if only 1 row is fetched
                if (merge && mergeWithExistingRows) {
                  res.data?.value.forEach((newRow) => {
                    const index = this[stateName].findIndex(
                      (oldRow) => oldRow[mergeFieldId] === newRow[mergeFieldId]
                    );
                    if (index > -1) {
                      this[stateName][index] = newRow;
                    } else {
                      this[stateName].push(newRow);
                    }
                  });
                  this[stateName].forEach((oldRow) => {
                    if (!oldRow) {
                      console.log(
                        "mergeWithExistingRows: oldRow is null",
                        stateName
                      );
                    }
                  });
                } else {
                  if (merge) {
                    this[stateName] = res.data?.value;
                    this[stateName].forEach((oldRow) => {
                      if (!oldRow) {
                        console.log("merge: oldRow is null", stateName);
                      }
                    });
                  }
                }
                useUserStore().loadingCount--;
                resolve(res.data?.value);
              } else {
                console.error("No valid return format:", res);
                useUserStore().loadingCount--;
                reject(new Error("No valid return format ..."));
              }
            })
            .catch((error) => {
              if (
                !error?.response?.data?.error?.message.includes(
                  "A filter must be specified. The filter could be for the date, resourceId, resourceNo or id."
                )
              ) {
                Sentry.captureException(error, "Get dynamics data");
                useUserPersistStore().pushError(error);
              }
              useUserStore().loadingCount--;
              reject(error);
            });
        } else {
          const message = "Please log in first.";
          useUserStore().loadingCount--;
          reject(message);
          useUserPersistStore().pushError({ message });
        }
      });
    },

    async postDynamicsData({ urlSegment, companyGuid, stateName, data }) {
      const auth = useAuthStore();
      useUserStore().savingCount++;
      return new Promise(async (resolve, reject) => {
        if (auth.isAuthenticated) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            useUserPersistStore().pushError(error);
            useUserStore().savingCount--;
            reject(error);
          });

          const companySlug = companyGuid ? `companies(${companyGuid})/` : "";
          let url = `${msalConfig.endPoints.dynamicsApi}${companySlug}${urlSegment}`;

          await axios
            .post(url, data, {
              headers: {
                Authorization: "Bearer " + response.accessToken, //the token is a variable which holds the token
                "Accept-Language": "en-US",
              },
            })
            .then((res) => {
              // console.log("result", res.data);
              if (companyGuid) {
                res.data.companyGuid = companyGuid;
                res.data.companyDisplayName =
                  this.getCompanyDisplayNameForGuid(companyGuid);
              }
              this[stateName].push(res.data);
              useUserStore().savingCount--;
              resolve(res.data);
            })
            .catch((error) => {
              Sentry.captureException(error, "Post dynamics data");
              useUserPersistStore().pushError(error);
              useUserStore().savingCount--;
              reject(error);
            });
        } else {
          const message = "Please log in first.";
          useUserStore().savingCount--;
          useUserPersistStore().pushError({ message });
          reject(message);
        }
      });
    },

    async deleteDynamicsData({ urlSegment, companyGuid, id, stateName }) {
      const auth = useAuthStore();
      useUserStore().savingCount++;
      return new Promise(async (resolve, reject) => {
        if (auth.isAuthenticated) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            useUserPersistStore().pushError(error);
            useUserStore().savingCount--;
            reject(error);
          });

          const companySlug = companyGuid ? `companies(${companyGuid})/` : "";
          let url = `${msalConfig.endPoints.dynamicsApi}${companySlug}${urlSegment}(${id})`;

          await axios
            .delete(url, {
              headers: {
                Authorization: "Bearer " + response.accessToken, //the token is a variable which holds the token
                "Accept-Language": "en-US",
              },
            })
            .then((res) => {
              // console.log("result", res.data);
              const index = this[stateName].findIndex((i) => i.id === id);
              if (index > -1) {
                this[stateName].splice(index, 1);
              }
              useUserStore().savingCount--;
              resolve(res.data);
            })
            .catch((error) => {
              Sentry.captureException(error, "Delete dynamics data");
              useUserPersistStore().pushError(error);
              useUserStore().savingCount--;
              reject(error);
            });
        } else {
          const message = "Please log in first.";
          useUserStore().savingCount--;
        }
      });
    },

    async updateDynamicsData({
      urlSegment,
      companyGuid,
      id,
      stateName,
      fieldNames,
      fieldValues,
      keyField,
    }) {
      if (!keyField) {
        keyField = "id";
      }
      const auth = useAuthStore();
      useUserStore().savingCount++;
      return new Promise(async (resolve, reject) => {
        if (auth.isAuthenticated) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            useUserPersistStore().pushError(error);
            useUserStore().savingCount--;
            reject(error);
          });

          const companySlug = companyGuid ? `companies(${companyGuid})/` : "";
          let url = `${msalConfig.endPoints.dynamicsApi}${companySlug}${urlSegment}(${id})`;

          const recordIndex = this[stateName].findIndex(
            (i) => i[keyField] === id
          );

          let etag = "";

          if (recordIndex > -1) {
            //get latest @odata.etag first
            this.getDynamicsData({
              companyGuid: companyGuid,
              urlSegment: `${urlSegment}(${id})?$select=${fieldNames.join(
                ", "
              )}`,
              merge: false,
            })
              .then((r) => {
                etag = r[0]["@odata.etag"];

                const patchData = {};
                fieldNames.forEach((fieldName, index) => {
                  patchData[fieldName] = fieldValues[index];
                });

                if (patchData.quantity) {
                  patchData.quantity =
                    Math.round(patchData.quantity * 100) / 100;
                }

                axios
                  .patch(url, patchData, {
                    headers: {
                      Authorization: "Bearer " + response.accessToken, //the token is a variable which holds the token
                      "Accept-Language": "en-US",
                      "If-Match": etag,
                    },
                  })
                  .then((res) => {
                    // console.log("result", res);

                    if (recordIndex > -1) {
                      fieldNames.forEach((fieldName) => {
                        this[stateName][recordIndex][fieldName] =
                          res.data[fieldName];
                      });
                    }
                    useUserStore().savingCount--;
                    resolve(res.data);
                  })
                  .catch((error) => {
                    useUserPersistStore().pushError(error);
                    //reset changed field
                    // this[stateName][recordIndex][fieldName] = origData;
                    useUserStore().savingCount--;
                    reject(error);
                  });
              })
              .catch((e) => {
                Sentry.captureException(e, "Update dynamics data");
                useUserStore().savingCount--;
                reject(e);
              });
          } else {
            //recordIndex not found
            const message = `Record with provided id ${id} could not be found in ${stateName}. (fields: ${fieldNames?.join(
              ", "
            )} - new values: ${fieldValues?.join(", ")})`;
            useUserStore().loadingCount--;
            reject(message);
            useUserPersistStore().pushError({ message });
          }
        } else {
          const message = "Please log in first.";
          useUserStore().savingCount--;
          reject(message);
          useUserPersistStore().pushError({ message });
        }
      });
    },

    async getDynamicsDataForAllCompanies({
      urlSegment,
      stateName,
      replaceStore = true, //if false, no the store will not be updated
      sortOnFields = [], // array of field names on where to sort on. Empty array if no sorting needed
    }) {
      const allResults = [];
      return new Promise(async (resolve, reject) => {
        const promisesPerCompany = [];
        this.companies.forEach((company) => {
          //exclude test companies
          if (company.displayName.indexOf("B·U·T") > -1) {
            // if (company.displayName.indexOf("B·U·T BELGIUM") > -1) {
            promisesPerCompany.push(
              this.getDynamicsData({
                companyGuid: company.id,
                urlSegment,
                stateName,
                merge: false,
              })
                .then((r) => {
                  allResults.push(...r);
                })
                .catch((e) => {
                  // reject(e);
                })
            );
          }
        });
        useUserStore().loadingCount++;
        Promise.all(promisesPerCompany).then(() => {
          if (sortOnFields && sortOnFields.length > 0) {
            allResults.sort((a, b) => {
              let aProp = "";
              let bProp = "";
              sortOnFields.forEach((field) => {
                if (a[field]) {
                  aProp += a[field];
                }
                if (b[field]) {
                  bProp += b[field];
                }
              });
              if (aProp.localeCompare) {
                return aProp.localeCompare(bProp, "en", {
                  sensitivity: "base",
                  ignorePunctuation: true,
                });
              } else {
                return 0;
              }
            });
          }

          if (replaceStore) {
            this[stateName] = allResults;
          }

          // console.log("******all " + stateName);
          useUserStore().loadingCount--;
          resolve(allResults);
        });
      });
    },

    injectMissingTaskFields(task) {
      const job = this.jobs.find((j) => j.systemId === task.jobId);
      task.jobDescription = job?.jobDescription;
      task.billToName = job?.billToName;
      return !!job;
    },

    stopAllTasks() {
      let activeTasks = this.myTasks.filter(
        (x) => x.timeSheetBeginTime !== NULL_DATE
      );

      const promises = [];
      const idleStore = useIdleStore();

      for (let i = 0; i < activeTasks.length; i++) {
        let startTime = new Date(activeTasks[i].timeSheetBeginTime);
        let workedTime = Math.round(
          (new Date().getTime() - startTime.getTime()) / 1000
        );
        activeTasks[i].quantity = workedTime / 3600 + activeTasks[i].quantity;

        //round up to 2 decimals
        activeTasks[i].quantity =
          Math.round(activeTasks[i].quantity * 100) / 100;

        activeTasks[i].timeSheetBeginTime = NULL_DATE;

        if (activeTasks[i].quantity > 23.99) {
          activeTasks[
            i
          ].errorText = `The max task duration was exceeded and is truncated to 23:59. The remaining ${quantityToTime(
            activeTasks[i].quantity - 23.99
          )} will NOT be kept.`;
          useUserPersistStore().pushError({
            message: `${activeTasks[i].jobNo} - ${activeTasks[i].jobDescription}: ${activeTasks[i].errorText}`,
          });
        }

        const origStatus = activeTasks[i].status;
        const isValid = this.validateTaskFields(activeTasks[i]);
        const fieldNames = ["timeSheetBeginTime", "quantity"];
        const fieldValues = [
          activeTasks[i].timeSheetBeginTime,
          Math.min(23.99, activeTasks[i].quantity),
        ];

        activeTasks[i].status = isValid ? "Submitted" : "Open";

        //status changed? --> add status to list of fields to change
        if (activeTasks[i].status !== origStatus) {
          if (!fieldNames.includes("status")) {
            fieldNames.push("status");
            fieldValues.push(activeTasks[i].status);
          }
        }

        promises.push(
          this.updateDynamicsData({
            companyGuid: activeTasks[i].companyGuid,
            urlSegment: "timeRegistrationEntries",
            id: activeTasks[i].id,
            stateName: "myTasks",
            keyField: "id",
            fieldNames: fieldNames,
            fieldValues: fieldValues,
          })

            .catch((e) => {
              notify({
                title: "Error stopping all tasks",
                error: e.message,
                duration: 10000,
              });
            })
            .finally(() => {})
        );
      }
      if (idleStore.controller && !idleStore.signal?.aborted) {
        idleStore.stopIdleDetector();
      }
      return Promise.all(promises);
    },

    validateTaskFields(row) {
      /**
       *         date: oldData.date,
       *         jobNo: oldData.jobNo,
       *         jobTaskNo: oldData.jobTaskNo,
       *         jobPlanningLineLineNo: oldData.jobPlanningLineLineNo,
       *         resourceNo: oldData.resourceNo,
       *         quantity: oldData.quantity,
       *         description: oldData.description,
       *         itemNo: oldData.itemNo,
       *         workType: oldData.workType,
       *         status: oldData.status,
       *         timeSheetBeginTime: oldData.timeSheetBeginTime,
       *         notQuoted: oldData.notQuoted,
       */
      if (row.status === "Rejected") {
        // row.errorDescription = "Rejected";
        row.isValid = false;
      } else if (
        row.date &&
        row.jobNo &&
        row.timeSheetBeginTime === NULL_DATE &&
        row.resourceNo &&
        row.quantity &&
        row.itemNo &&
        row.status
      ) {
        row.isValid = true;
      } else {
        row.isValid = false;
      }
      return row.isValid;
    },

    async putResourceSettings(data, forceSave = true) {
      if (!this.resourceChangeBuffer && forceSave) {
        useUserStore().savingCount++;
      }

      //Periodically cleanup project favorites, only when jobs are loaded (length > 0)
      if (
        new Date(this.lastProjectFavCleanDate) < Date.now() &&
        this.jobs.length > 0
      ) {
        const userPersist = useUserPersistStore();
        let count = userPersist.userSettings.projectFavorites.length;
        userPersist.userSettings.projectFavorites =
          userPersist.userSettings.projectFavorites.filter(
            (x) => this.jobs.findIndex((y) => y.no === x) >= 0
          );
        count = count - userPersist.userSettings.projectFavorites.length;
        if (count > 0) {
          console.log(
            `${count} old favorite(s) cleaned up. Still present: ${userPersist.userSettings.projectFavorites.join(
              ", "
            )}`
          );
          data.projectFavorites = userPersist.userSettings.projectFavorites;
        } else {
          console.log("No old favorites to clean up");
        }
        this.lastProjectFavCleanDate = Date.now() + 60 * 60 * 24 * 30;
      }

      this.resourceChangeBuffer = { ...this.resourceChangeBuffer, ...data };

      debouncedPutResourceSettings();

      return new Promise((resolve) => {
        const me = this;
        function checkBuffer() {
          if (me.resourceChangeBuffer?.length > 0) {
            setTimeout(() => {
              checkBuffer();
            }, 500);
          } else {
            resolve(true);
          }
        }
        //Initial debounce wait
        setTimeout(() => {
          checkBuffer();
        }, 5000);
      });
    },

    async putResourceSettingsAfterDebounce() {
      const userPersist = useUserPersistStore();
      const url = userPersist.linkedResource["settings@odata.mediaEditLink"];
      await this.setBlobData(
        url,
        this.resourceChangeBuffer,
        `resources? $filter=no eq '${userPersist.linkedResource.no}'`
      )
        .then((r) => {
          userPersist.userSettings = { ...userPersist.userSettings, ...r };
          new WebSocketHelper().wsSend({
            command: "updateUserSettings",
            changes: this.resourceChangeBuffer,
          });
          this.resourceChangeBuffer = null;
        })
        .finally(() => {
          useUserStore().savingCount--;
        });
    },

    async putResourceSettingsWithoutDebounce(data, forceSave = true) {
      const userPersist = useUserPersistStore();
      const url = userPersist.linkedResource["settings@odata.mediaEditLink"];

      if (forceSave) {
        useUserStore().savingCount++;
      }
      await this.setBlobData(
        url,
        this.data,
        `resources? $filter=no eq '${userPersist.linkedResource.no}'`,
        0,
        forceSave
      )
        .then((r) => {
          userPersist.userSettings = { ...userPersist.userSettings, ...r };
          new WebSocketHelper().wsSend({
            command: "updateUserSettings",
            changes: this.resourceChangeBuffer,
          });
        })
        .finally(() => {
          if (forceSave) {
            useUserStore().savingCount--;
          }
        });
    },

    async getResourceSettings() {
      const userPersist = useUserPersistStore();
      return this.getBlobData(
        userPersist.linkedResource["settings@odata.mediaReadLink"]
      ).then((r) => {
        userPersist.userSettings = { ...userPersist.userSettings, ...r };
      });
    },

    async getBlobData(url) {
      const user = useUserStore();
      const auth = useAuthStore();
      const userPersist = useUserPersistStore();
      user.loadingCount++;

      return new Promise(async (resolve, reject) => {
        if (auth.isAuthenticated) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            userPersist.pushError(error);
            user.loadingCount--;
            reject(error);
          });
          if (!response) {
            reject("Token response failed!");
            return;
          }

          await axios
            .get(url, {
              headers: {
                Authorization: "Bearer " + response.accessToken, //the token is a variable which holds the token
                "Accept-Language": "en-US",
                "Data-Access-Intent": "ReadOnly",
              },
            })
            .then((r) => {
              user.loadingCount--;
              resolve(r.data);
            })
            .catch((error) => {
              userPersist.pushError({
                message: "Error while getting resource settings",
                error,
              });
              user.loadingCount--;
              reject("Error while getting resource settings");
            });
        } else {
          const message = "Please log in first.";
          useUserPersistStore().pushError({ message });
          user.loadingCount--;
          reject(message);
        }
      });
    },

    async setBlobData(
      url,
      newData,
      etagUrlSegment,
      callback = 0,
      forceSave = true
    ) {
      const user = useUserStore();
      if (forceSave) {
        user.savingCount++;
      }
      const auth = useAuthStore();
      const userPersist = useUserPersistStore();
      return new Promise(async (resolve, reject) => {
        if (auth.isAuthenticated && userPersist.linkedResource) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            userPersist.pushError(error);
            if (forceSave) {
              user.savingCount--;
            }
            reject(error);
          });
          if (!response) {
            reject("Token response failed!");
            return;
          }

          let etag = "";

          await this.getDynamicsData({
            companyGuid: userPersist.linkedResource.companyGuid,
            urlSegment: etagUrlSegment,
          }).then((r) => {
            etag = r[0]["@odata.etag"];
          });

          if (!etag) {
            const message = "etag call failed";
            useUserPersistStore().pushError({ message });
            if (forceSave) {
              user.savingCount--;
            }
            reject(message);
            return;
          }

          let currentData;

          await axios
            .get(url, {
              headers: {
                Authorization: "Bearer " + response.accessToken, //the token is a variable which holds the token
                "Accept-Language": "en-US",
                "Data-Access-Intent": "ReadOnly",
              },
            })
            .then((r) => {
              currentData = r.data;
            })
            .catch((error) => {
              userPersist.pushError({
                message: "Error while getting resource settings",
                error,
              });
              if (forceSave) {
                user.savingCount--;
              }
              reject(error);
            });

          const combinedData = {
            ...currentData,
            ...newData,
          };

          await axios
            .put(url, combinedData, {
              headers: {
                Authorization: "Bearer " + response.accessToken, //the token is a variable which holds the token
                "Accept-Language": "en-US",
                "Content-Type": "application/json",
                "If-Match": etag,
              },
            })
            .then(() => {
              if (forceSave) {
                user.savingCount--;
              }
              resolve(combinedData);
            })
            .catch((error) => {
              console.log(error);
              if (forceSave) {
                user.savingCount--;
              }
              const retryStatuses = [504, 409, 400];
              if (
                retryStatuses.includes(error.response?.status) &&
                callback < 3
              ) {
                if (error.response.status === 409) {
                  console.warn(`"Blob" save conflict! Retry ${1 + callback}/3`);
                  this.setBlobData(
                    url,
                    newData,
                    etagUrlSegment,
                    callback + 1,
                    forceSave
                  );
                } else if (error.response.status === 504) {
                  console.warn(`"Blob" save timeout! Retry ${1 + callback}/3`);
                  this.setBlobData(
                    url,
                    newData,
                    etagUrlSegment,
                    callback + 1,
                    forceSave
                  );
                } else if (
                  error.response.data.error === "Internal_ServerError"
                ) {
                  console.warn(
                    `"Blob" save server error! Retry ${1 + callback}/3`
                  );
                  this.setBlobData(
                    url,
                    newData,
                    etagUrlSegment,
                    callback + 1,
                    forceSave
                  );
                } else {
                  userPersist.pushError({
                    message: "Error while saving resource settings: " + error,
                    error,
                  });
                  if (error.response?.status !== 404) {
                    Sentry.captureException(error, "UserSettings");
                  }
                  reject("Error while saving resource settings: " + error);
                }
              } else {
                userPersist.pushError({
                  message: "Error while saving resource settings: " + error,
                  error,
                });
                reject("Error while saving resource settings: " + error);
              }
            });
        } else {
          if (auth.isAuthenticated) {
            const message = "Can't save user settings without a resource!";
            userPersist.pushError({ message });
          } else {
            const message = "Please log in first.";
            userPersist.pushError({ message });
          }
          if (forceSave) {
            user.savingCount--;
          }
        }
      });
    },

    /**
     *
     * @param companyGuid optional, else the selectedPlanningCompany is used
     */
    async getHolidays(companyGuid) {
      const segment = (
        companyGuid
          ? this.lastHolidayChangeDateForAllCompanies[companyGuid]
          : this.lastHolidayChangeDate
      )
        ? `baseCalendarChanges?$filter=systemModifiedAt ge ${this.lastHolidayChangeDate.toISOString()}`
        : `baseCalendarChanges`;
      await this.getDynamicsData({
        companyGuid: companyGuid || this.selectedPlanningCompany,
        urlSegment: segment,
        stateName: "holidays",
        merge: true,
        mergeWithExistingRows: true,
        mergeFieldId: "systemId",
      });
    },

    async getHolidaysForAllCompanies() {
      let promises = [];
      this.companies.forEach((company) => {
        promises.push(this.getHolidays(company.id));
      });
      await Promise.all(promises).then(() => {
        // console.log("Done with all holidays");
        // console.log(this.lastHolidayChangeDateForAllCompanies);
      });
    },

    async getScheduleEvents(beginDate, endDate) {
      return await this.getDynamicsData({
        companyGuid: this.selectedPlanningCompany,
        urlSegment: `scheduleEvents?$filter=endDate ge ${beginDate.toISOString()} AND startDate le ${endDate.toISOString()}`,
        stateName: "scheduleEvents",
        merge: true,
      });
    },

    async postScheduleEvents(event) {
      if (
        useUserPersistStore().linkedResource.timeSheetUserType.toLowerCase() ===
          "admin" ||
        (useUserPersistStore().actualResource.no === event.resourceNo &&
          event.type === "remote")
      ) {
        return this.postDynamicsData({
          companyGuid: this.selectedPlanningCompany,
          urlSegment: "scheduleEvents",
          stateName: "scheduleEvents",
          data: event,
        });
      } else {
        console.error("You have no authorisation to edit scheduleEvents");
      }
    },

    async patchScheduleEvents(event) {
      if (
        useUserPersistStore().linkedResource.timeSheetUserType.toLowerCase() ===
          "admin" ||
        (useUserPersistStore().actualResource.no === event.resourceNo &&
          event.type === "remote")
      ) {
        return this.updateDynamicsData({
          companyGuid: this.selectedPlanningCompany,
          urlSegment: "scheduleEvents",
          stateName: "scheduleEvents",
          id: event.eventId,
          fieldNames: Object.keys(event),
          fieldValues: Object.values(event),
          keyField: "eventId",
        });
      }
    },

    async deleteScheduleEvents(event) {
      if (
        useUserPersistStore().linkedResource.timeSheetUserType.toLowerCase() ===
          "admin" ||
        (useUserPersistStore().actualResource.no === event.resourceNo &&
          event.type === "remote")
      ) {
        return this.deleteDynamicsData({
          companyGuid: this.selectedPlanningCompany,
          urlSegment: "scheduleEvents",
          stateName: "scheduleEvents",
          id: event.eventId,
        });
      }
    },

    async getNativeBCData({ companyGuid, urlSegment, returnType = "json" }) {
      const auth = useAuthStore();

      return new Promise(async (resolve, reject) => {
        if (auth.isAuthenticated) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            reject(error);
            useUserStore().loadingCount--;
            useUserPersistStore().pushError(error);
          });

          const companySlug = companyGuid ? `companies(${companyGuid})/` : "";
          let apiPrefix = `https://api.businesscentral.dynamics.com/v2.0/${bcEnvironment}/api/v2.0/`;
          let url = `${apiPrefix}${companySlug}${urlSegment}`;

          await axios
            .get(url, {
              responseType: returnType,
              headers: {
                Authorization: `Bearer ${response.accessToken}`, //the token is a variable which holds the token
                "Accept-Language": "en-US",
                "Data-Access-Intent": "ReadOnly",
              },
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        } else {
          reject("not authenticated");
        }
      });
    },

    async getButApiData({ companyGuid, urlSegment }) {
      const auth = useAuthStore();

      return new Promise(async (resolve, reject) => {
        if (auth.isAuthenticated) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            reject(error);
            useUserStore().loadingCount--;
            useUserPersistStore().pushError(error);
          });

          const companySlug = companyGuid ? `companies(${companyGuid})/` : "";
          let apiPrefix = `https://api.businesscentral.dynamics.com/v2.0/${bcEnvironment}/api/BUT/customapi/v1.0/`;
          let url = `${apiPrefix}${companySlug}${urlSegment}`;

          await axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${response.accessToken}`, //the token is a variable which holds the token
                "Accept-Language": "en-US",
                "Data-Access-Intent": "ReadOnly",
              },
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if (error.response?.status !== 404) {
                Sentry.captureException(error, "ButApi");
              }
              console.log(error);
              reject(error);
            });
        } else {
          reject("not authenticated");
        }
      });
    },

    async postButApiData({ companyGuid, urlSegment, data }) {
      const auth = useAuthStore();
      useUserStore().savingCount++;
      return new Promise(async (resolve, reject) => {
        if (auth.isAuthenticated) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            useUserPersistStore().pushError(error);
            useUserStore().savingCount--;
            reject(error);
          });

          const companySlug = companyGuid ? `companies(${companyGuid})/` : "";
          let apiPrefix = `https://api.businesscentral.dynamics.com/v2.0/${bcEnvironment}/api/BUT/customapi/v1.0/`;
          let url = `${apiPrefix}${companySlug}${urlSegment}`;

          await axios
            .post(url, data, {
              headers: {
                Authorization: `Bearer ${response.accessToken}`, //the token is a variable which holds the token
                "Accept-Language": "en-US",
              },
            })
            .then((res) => {
              useUserStore().savingCount--;
              resolve(res.data);
            })
            .catch((error) => {
              Sentry.captureException(error, "ButApi");
              useUserPersistStore().pushError(error);
              useUserStore().savingCount--;
              console.log(error);
              reject(error);
            });
        } else {
          useUserStore().savingCount--;
          reject("not authenticated");
        }
      });
    },

    async updateButApiData({ companyGuid, urlSegment, data }) {
      const auth = useAuthStore();
      useUserStore().savingCount++;
      return new Promise(async (resolve, reject) => {
        if (!data.id) {
          useUserStore().savingCount--;
          reject("not authenticated");
          return;
        }
        if (auth.isAuthenticated) {
          const response = await auth.getTokenPopup({}).catch((error) => {
            useUserPersistStore().pushError(error);
            useUserStore().savingCount--;
            reject(error);
          });

          const companySlug = companyGuid ? `companies(${companyGuid})/` : "";
          let apiPrefix = `https://api.businesscentral.dynamics.com/v2.0/${bcEnvironment}/api/BUT/customapi/v1.0/`;
          let url = `${apiPrefix}${companySlug}${urlSegment}(${data.id})`;
          let etag = "";

          await this.getButApiData({
            companyGuid: companyGuid,
            urlSegment: `${urlSegment}(${data.id})?$select=${
              Object.keys(data)[0]
            }`,
            merge: false,
          })
            .then((r) => {
              etag = r.data["@odata.etag"];
            })
            .catch((e) => {
              useUserPersistStore().pushError(e);
              useUserStore().savingCount--;
              reject(e);
            });

          if (!etag) {
            const message = "etag call failed";
            useUserPersistStore().pushError({ message });
            useUserStore().savingCount--;
            reject(message);
            return;
          }

          await axios
            .patch(url, data, {
              headers: {
                Authorization: `Bearer ${response.accessToken}`, //the token is a variable which holds the token
                "Accept-Language": "en-US",
                "If-Match": etag,
              },
            })
            .then((res) => {
              useUserStore().savingCount--;
              resolve(res.data);
            })
            .catch((error) => {
              useUserStore().savingCount--;
              useUserPersistStore().pushError(error);
              reject(error);
            });
        } else {
          useUserStore().savingCount--;
          reject("not authenticated");
        }
      });
    },
  },
});
