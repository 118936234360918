<template>
  <input
    type="text"
    class="but-input table-tasks-list-input focus:ring-gray-400"
    :class="$attrs.disabled ? 'text-gray-disabled' : ''"
    :value="modelValue"
    @input="onChange($event.target.value)"
    v-bind="$attrs"
  />
</template>

<script>
// use normal <script> block to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { useAttrs } from "vue";
import { debounce } from "@/utils/Utils.js";

defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue"]);
const attrs = useAttrs();

function onChange(value) {
  // console.log("onchange", value);
  if (attrs.type === "number") {
    value = parseFloat(value);
  }
  debouncedEmit(value);
}

const debouncedEmit = debounce((value) => {
  // console.log("debouncedEmit", value);
  emits("update:modelValue", value);
}, 1000);
</script>
