import { createRouter, createWebHistory } from "vue-router";
import OpenTasksView from "../views/OpenTasksView.vue";
import LoginView from "../views/LoginView.vue";
// import WipView from "@/views/WipView.vue";
import { useAuthStore } from "@/stores/auth";
import {
  CalendarIcon,
  CheckCircleIcon,
  ClockIcon,
  KeyIcon,
  ExclamationIcon,
  TableIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/vue/outline";

import { NULL_DATE, useBcPersistStore } from "@/stores/bcPersist.js";
import { useUserPersistStore } from "@/stores/userPersist.js";
import { useUserStore } from "@/stores/user.js";
import { useIdleStore } from "@/stores/idle.js";
import { useHRStore } from "@/stores/hr";
import { wait } from "@/utils/Utils.js";
import { DateHelper } from "@bryntum/scheduler";
import WebSocketHelper from "@/utils/WebSocketHelper.js";
import { computed } from "vue";

const startTime = new Date();

const timeTillNextDay =
  24 - startTime.getHours() + (60 - startTime.getMinutes()) / 60;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      redirect: (to) => {
        return { name: "Open Tasks" };
      },
    },
    {
      path: "/login",
      name: "Login",
      component: LoginView,
      meta: {
        loginRequired: false,
      },
    },
    {
      path: "/open-tasks/:jobNo?",
      name: "Open Tasks",
      component: OpenTasksView,
      meta: {
        icon: ClockIcon,
        isMainMenu: true,
        loginRequired: true,
      },
    },
    {
      path: "/committed-tasks",
      name: "Committed Tasks",
      component: () => import("@/views/CommittedTaskView.vue"),
      meta: {
        icon: CheckCircleIcon,
        isMainMenu: true,
        loginRequired: true,
      },
    },
    // {
    //   path: "/projects",
    //   name: "Projects",
    //   component: WipView,
    //   meta: {
    //     icon: DocumentTextIcon,
    //     isMainMenu: true,
    //     loginRequired: true,
    //   },
    // },
    {
      path: "/planning",
      name: "Planning",
      component: () => import("@/views/PlanningView.vue"),
      meta: {
        icon: CalendarIcon,
        isMainMenu: true,
        loginRequired: true,
      },
    },
    {
      path: "/hours-overview",
      name: "Hours Overview",
      component: () => import("@/views/HoursReportView.vue"),
      meta: {
        icon: TableIcon,
        isMainMenu: true,
        loginRequired: true,
      },
    },
    {
      path: "/hr",
      name: "HR",
      component: () => import("@/views/HRView.vue"),
      meta: {
        icon: UserIcon,
        isMainMenu: true,
        loginRequired: true,
      },
      children: [
        {
          name: "Absences",
          path: "absences",
          component: () => import("@/views/hr/Absences.vue"),
        },
        {
          name: "Overtime",
          path: "overtime",
          component: () => import("@/views/hr/Overtime.vue"),
        },
        {
          name: "Approvals",
          path: "approvals",
          component: () => import("@/views/hr/Approvals.vue"),
        },
      ],
    },
    {
      path: "/whoiswho",
      name: "Who Is Who",
      component: () => import("@/components/WhoIsWho.vue"),
      meta: {
        icon: UsersIcon,
        isMainMenu: true,
        loginRequired: true,
      },
    },
    {
      path: "/errors",
      name: "Errors",
      component: () => import("@/views/ErrorsView.vue"),
      meta: {
        icon: ExclamationIcon,
        isMainMenu: false,
        loginRequired: false,
      },
    },
    {
      path: "/admin",
      name: "Admin",
      component: import("@/views/AdminView.vue"),
      meta: {
        icon: KeyIcon,
        isMainMenu: false,
        loginRequired: true,
      },
    },
    // catch all redirect to home page
    // { path: "/:pathMatch(.*)*", redirect: "/" },
  ],
});

function setLocaleForCompany(company) {
  let countryCode = "BE";
  if (company.includes("BELGIUM")) {
    countryCode = "BE";
  } else if (company.includes("SINGAPORE")) {
    countryCode = "SG";
  } else if (company.includes("SWITZERLAND")) {
    countryCode = "CH";
  } else if (company.includes("UAE")) {
    countryCode = "AE";
  }
  const locale = `en-${countryCode}`;
  try {
    DateHelper.locale = locale;
  } catch (e) {
    useUserPersistStore().pushError({
      message: `Error loading locale ${locale}.`,
    });
  }
}

async function loadCompaniesAndResources() {
  console.log("loadCompaniesAndResources");
  const userStore = useUserStore();
  const bcPersistStore = useBcPersistStore();
  const userPersistStore = useUserPersistStore();
  const authStore = useAuthStore();

  if (!userStore.resourcesAreLoaded && !userStore.resourcesAreLoading) {
    userStore.resourcesAreLoading = true;
    userStore.resourcesAreLoaded = false;

    await bcPersistStore.getDynamicsData({
      urlSegment: "companies",
      stateName: "companies",
      merge: true,
    });
    let urlSegment = "";

    if (bcPersistStore.lastCleanResourceFetch < new Date() - 2628000) {
      //Monthly clean fetch
      bcPersistStore.lastCleanResourceFetch = NULL_DATE;
    }

    if (bcPersistStore.lastRecourseFetchDate === NULL_DATE) {
      urlSegment = ``;
      bcPersistStore.lastCleanResourceFetch = new Date();
      bcPersistStore.resources = [];
    } else {
      urlSegment = `and systemModifiedAt gt ${bcPersistStore.lastRecourseFetchDate}`;
    }

    const promises = [];
    for (let i = 0; i < bcPersistStore.companies.length; i++) {
      promises.push(
        bcPersistStore.getDynamicsData({
          urlSegment: `resources?&$filter=company eq '${bcPersistStore.companies[i].name}' ${urlSegment} &$orderby=name asc&$expand=employeesPTE`,
          companyGuid: bcPersistStore.companies[i].id,
          merge: true, //if false, no merging with store values
          mergeWithExistingRows: true, //mergeWithExistingRows: replace new data but leave existing data intact (typically used if only 1 row is fetched
          mergeFieldId: "systemId",
          stateName: "resources",
          replaceStore: false,
          sortOnFields: ["name"],
        })
      );
    }
    await Promise.all(promises);
    bcPersistStore.lastRecourseFetchDate = ((d) =>
      new Date(d.setDate(d.getDate() - 1)))(new Date()).toISOString();
    const resource = bcPersistStore.resources.find(
      (r) =>
        r.userAuthenticationEmail.toLowerCase() ===
        authStore.user.username.toLowerCase()
    );

    if (resource) {
      userPersistStore.actualResource = resource;
      if (
        !(
          userPersistStore.linkedResource &&
          resource.timeSheetUserType.toLowerCase() === "admin"
        )
      ) {
        userPersistStore.linkedResource = resource;
      } else {
        const newReference = bcPersistStore.resources.find(
          (x) => x.no === userPersistStore.linkedResource.no
        );
        if (newReference) {
          userPersistStore.linkedResource = newReference;
        }
      }
      //temp set to normal user
      //userPersistStore.linkedResource.timeSheetUserType = "user"; ///temp gst

      if (!userPersistStore.planning.selectedCompany) {
        userPersistStore.planning.selectedCompany = resource.company;
      }

      // console.log("linked:", resource);
      setLocaleForCompany(resource.company);
      await bcPersistStore.getResourceSettings();

      // console.log(userPersistStore.userSettings.verticalCalendar);
      if (userPersistStore.userSettings.verticalCalendar === null) {
        userPersistStore.userSettings.verticalCalendar =
          resource.company === "BUT NV (UAE)";
        bcPersistStore.putResourceSettings({
          verticalCalendar: userPersistStore.userSettings.verticalCalendar,
        });
      }

      console.log(
        "👤 *** userPersistStore.linkedResource ***",
        userPersistStore.linkedResource
      );
    } else {
      userPersistStore.pushError({
        message: `Unable to link the logged-in user "${authStore.user.username}" to an existing Business Central Resource. Make sure to link a Windows user name in the Resources Card in BC.`,
      });
    }

    userStore.resourcesAreLoaded = true;
    userStore.resourcesAreLoading = false;
  }
}

export async function refreshBcData() {
  const userStore = useUserStore();
  const hrStore = useHRStore();

  userStore.resourcesAreLoaded = false;
  userStore.projectsAreLoaded = false;

  useBcPersistStore().lastJobFetchDate = NULL_DATE;
  useBcPersistStore().lastRecourseFetchDate = NULL_DATE;
  await loadCompaniesAndResources();
  await loadProjectsAndTasks();
  userStore.loadingCount = 0;
  userStore.savingCount = 0;
  hrStore.initializeHR();
  if (router.currentRoute.value.name === "Planning") {
    userStore.updateKey++;
  }
}

async function loadProjectsAndTasks() {
  const bcPersistStore = useBcPersistStore();
  const userPersistStore = useUserPersistStore();
  const idleStore = useIdleStore();
  const userStore = useUserStore();

  userStore.jobsAreLoaded = false;
  let urlSegment = "";

  console.log(
    "bcPersistStore.lastJobFetchDate",
    bcPersistStore.lastJobFetchDate
  );
  if (bcPersistStore.lastCleanJobFetch < new Date() - 2628000) {
    //Monthly clean fetch
    bcPersistStore.lastJobFetchDate = NULL_DATE;
  }

  if (bcPersistStore.lastJobFetchDate === NULL_DATE) {
    // console.log("Clean fetch");
    urlSegment = `jobs?$filter=status eq 'Open'`;
    bcPersistStore.lastCleanJobFetch = new Date();
    bcPersistStore.jobs = [];
  } else {
    urlSegment = `jobs?$filter=systemModifiedAt gt ${bcPersistStore.lastJobFetchDate}`;
  }

  const jobData = await bcPersistStore.getDynamicsDataForAllCompanies({
    urlSegment: urlSegment,
    stateName: "jobs",
    // sortOnFields: ["billToName", "jobDescription"],
    // sortOnFields: ["startingDate"],
    //sortOnFields: ["jobDescription"],
    replaceStore: false,
    caseSensitive: false,
  });

  console.log("nr of fetched jobs:", jobData.length);
  let storeCopy = JSON.parse(JSON.stringify(bcPersistStore.jobs));
  for (let i = 0; i < jobData.length; i++) {
    let index = storeCopy.findIndex((x) => x.no === jobData[i].no);
    if (index === -1) {
      storeCopy.push(jobData[i]);
    } else {
      storeCopy[index] = jobData[i];
    }
  }

  if (bcPersistStore.lastJobFetchDate !== NULL_DATE || jobData.length > 0) {
    bcPersistStore.lastJobFetchDate = ((d) =>
      new Date(d.setDate(d.getDate() - 1)))(new Date()).toISOString();
    //.substring(0, 10); //todo: full date GERT
  }

  //clear earlier errors
  storeCopy.forEach((job) => {
    job.errorText = false;
    job.isAdding = false;
  });
  bcPersistStore.jobs = storeCopy;

  userStore.jobsAreLoaded = true;

  if (userPersistStore?.linkedResource?.systemId) {
    // let today = new Date();
    // let year = today.getFullYear();
    // let month = today.getMonth() + 1;
    // let day = today.getDate();
    // or date eq ${year}-${month}-${day}
    await bcPersistStore.getDynamicsDataForAllCompanies({
      // urlSegment: `timeRegistrationEntries?$filter=resourceId eq ${userPersistStore?.linkedResource?.systemId}`,
      urlSegment: `timeRegistrationEntries?$filter=resourceNo eq '${userPersistStore?.linkedResource?.no}' and status ne 'approved' &orderby=id`,
      // urlSegment: `timeRegistrationEntries?$filter=resourceId eq ${userPersistStore?.linkedResource?.systemId} and status ne 'approved'&orderby=id`,
      // urlSegment: `timeRegistrationEntries?$filter=date eq '2022-09-28'&orderby=id`,
      stateName: "myTasks",
      replaceStore: true,
      sortOnFields: ["id"],
      //sortOnFields: ["billToName", "jobDescription"], --> does not work because these fields are injected
    });

    const companiesInTaskList = new Map();

    bcPersistStore.myTasks.forEach((task) => {
      if (task.errorDescription) {
        const message = `<p>Error from Business Central. Contact your Project Manager to solve this issue.</p><p class="text-xs">${task.errorDescription}</p>`;
        if (!userPersistStore.errors.find((e) => e.message === message)) {
          userPersistStore.pushError({ message });
        }
        task.errorText = message;
        task.errorDescription = "";
      }
      bcPersistStore.injectMissingTaskFields(task);

      // MOVED TO MyTasksList.vue
      // const isValid = bcPersistStore.validateTaskFields(task);
      //
      // if (isValid && task.status === "Open") {
      //   task.status = "Submitted";
      //   console.log("SET TO submitted", task);
      // }

      //for all companies in my task list, keep all different jobs to use later for getting job details per company

      if (!companiesInTaskList.has(task.companyGuid)) {
        companiesInTaskList.set(task.companyGuid, new Map());
      }
      companiesInTaskList.get(task.companyGuid).set(task.jobNo, task);
    });
    // console.log("companiesInTaskList", companiesInTaskList);

    //get job details for all jobs used in the current tasks, per company

    companiesInTaskList.forEach((company) => {
      const jobNos = [];
      let companyGuid;
      company.forEach((job) => {
        // console.log("jobNo", job.companyGuid, job.jobNo);
        jobNos.push(`no eq '${job.jobNo}'`);
        companyGuid = job.companyGuid;
      });
      //get jobPlanning lines for all jobs that are used in my task list: needed for getting the jobPlanningLine description
      bcPersistStore.getDynamicsData({
        urlSegment: `jobs?$filter=${jobNos.join(
          " or "
        )}&$expand=jobPlanningLines`,
        companyGuid,
        stateName: "jobs",
        merge: true,
        mergeWithExistingRows: true,
      });
    });

    await bcPersistStore.getDynamicsDataForAllCompanies({
      urlSegment: `items`,
      stateName: "items",
      sortOnFields: ["description"],
      replaceStore: true,
    });
    // .then((r) => {
    //   console.log(r.filter((x) => x.no.includes("IT")));
    // });
    await idleStore.startIdleDetectionInitially();
    // console.log("returning");
  } else {
    userPersistStore.pushError({
      message:
        "No resource was linked to the user. Please contact the business central administrator.",
    });
  }
  // await bcPersistStore.getScheduleEvents(new Date());
  useUserStore().projectsAreLoaded = true;
}

setTimeout(
  async () => {
    reloadAfterBCProcess();
  },
  (timeTillNextDay + 1) * 1000 * 60 * 60
);

async function reloadAfterBCProcess() {
  console.log("called");
  await loadProjectsAndTasks();

  setTimeout(
    async () => {
      reloadAfterBCProcess();
    },
    1000 * 60 * 60 * 24
  );
}

router.beforeEach(async (to, from) => {
  const userStore = useUserStore();
  const idleStore = useIdleStore();
  const hrStore = useHRStore();
  idleStore.swRegistration?.update();
  userStore.loadingCount++;
  if (!from.name) {
    userStore.routerLoading = true;
  }

  const authStore = useAuthStore();

  const milliesToWait = startTime.getTime() + 500 - new Date().getTime();
  //
  // // we wait 500ms to be sure the stores are installed:
  // // todo: Is there a less stupid way to do this? :-/
  document.body.style.cursor = "wait";
  if (milliesToWait > 0) {
    await wait(milliesToWait);
  }

  await authStore.initAuth();

  if (authStore.isAuthenticated) {
    new WebSocketHelper(authStore.user.username);
  }

  if (authStore.isAuthenticated && to.name === "Login") {
    document.body.style.cursor = "default";
    useUserStore().loadingCount--;
    return { name: "Home" };
  }

  if (!to.meta.loginRequired) {
    //all good
    document.body.style.cursor = "default";
    useUserStore().loadingCount--;
  } else {
    if (!authStore.isAuthenticated) {
      document.body.style.cursor = "default";
      useUserStore().loadingCount--;
      return { name: "Login" };
    }
    if (!userStore.resourcesAreLoaded) {
      await loadCompaniesAndResources();
    }
    if (!userStore.projectsAreLoaded) {
      await loadProjectsAndTasks();
    }
    if (!hrStore.isInitialized) {
      hrStore.initializeHR();
    }
    if (authStore.isAuthenticated && to.name === "Login") {
      document.body.style.cursor = "default";
      useUserStore().loadingCount--;
      return { name: "Home" };
    }
    document.body.style.cursor = "default";
    useUserStore().loadingCount--;

    if (to.name === "HR") {
      if (hrStore.openApprovals.length > 0) {
        return { name: "Approvals" };
      } else {
        return { name: "Absences" };
      }
    }
  }
  userStore.routerLoading = false;
});

export default router;
