import { DateHelper } from "@bryntum/scheduler";
import { useBcPersistStore } from "@/stores/bcPersist.js";
import { useUserPersistStore } from "@/stores/userPersist.js";
import router from "@/router/router.js";

export const useSchedulerEdit = (scheduler, openPlanningLine) => {
  const bcPersist = useBcPersistStore();
  const userPersist = useUserPersistStore();

  const eventEditPanel = {
    editorConfig: {
      width: "42em",
      modal: true,
      autoClose: true,
      title:
        userPersist.linkedResource.timeSheetUserType.toLowerCase() !== "admin"
          ? "Edit remote work"
          : "Edit event",
    },

    // Add items to the event editor
    items: {
      eventTypeField: {
        type: "combo",
        name: "type",
        label: "Type",
        editable: false,
        flex: "1 1 auto",
        cls: "pb-2",
        weight: -100, // Provided items start at 100, and go up in 100s, so this comes before all
        value: "Task", //default choice
        items: {
          task: "Task",
          freeText: "Free Text",
          leave: "Leave",
          recovery: "Overtime recovery",
          milestone: "Milestone",
          remote: "Work from home",
        },
        listeners: {
          change(e) {
            initFields(e.value);
          },
        },
      },

      nameField: {
        type: "text",
        label: "Title",
        name: "name",
        editable:
          userPersist.linkedResource.timeSheetUserType.toLowerCase() ===
          "admin",
        // validateFilter: false,
        // allowNew: true,
        readOnly:
          userPersist.linkedResource.timeSheetUserType.toLowerCase() !==
          "admin",
        required: true,
        autoExpand: true,
        placeholder: "Fill in a title",
        weight: 130,
      },

      jobField: {
        type: "combo",
        label: "Job Task",
        name: "job",
        editable: true,
        filterOperator: "*",
        required: true,
        autoExpand: true,
        valueField: "no",
        displayField: "label",
        placeholder: "Select a Job",
        weight: 130,
        // listItemTpl: (item) =>
        //   xss`<div class="color-box b-sch-${item.value}"></div><div>${item.text}</div>`,
        listeners: {
          focusout(e) {
            // setRelevantType();
          },
        },
        items: (function () {
          const r = bcPersist.jobs.filter((i) => i.status === "Open");
          // console.log(r);
          return r.map((i) => {
            const obj = {
              no: i.no,
              label: `${i.no} - ${i.billToName} - ${i.jobDescription}`,
              jobDescription: i.jobDescription,
              endingDate: i.endingDate,
              billToName: i.billToName,
            };
            // console.log(obj);
            return obj;
          });
        })(),
        primaryFilter: {
          filterBy(record) {
            if (this.value == null) {
              return true;
            }
            const searchTerm = this.value?.toLowerCase();
            const searchWords = searchTerm
              .split(" ")
              .filter((word) => word.trim() !== "");

            // Split the record into an array of space-separated words
            const recordWords = record.data.label
              ?.toLowerCase()
              .split(" ")
              .filter((word) => word.trim() !== "");
            // Check if all searchWords are present in recordWords
            return searchWords.every((word) =>
              recordWords.find((x) => x.includes(word))
            );
          },
        },
      },

      remarkField: {
        type: "text",
        label: "Remark",
        name: "scheduleRemark",
        editable: true,
        required: false,
        autoExpand: true,
        placeholder: "(optional)",
        weight: 130,
      },

      resourceField: {
        multiSelect: false,
      },
    },
  };

  const eventMenu = {
    processItems({ eventRecord, items }) {
      items.split.hidden = scheduler.value.instance.value.readOnly;
      items.copyEvent.hidden = scheduler.value.instance.value.readOnly;

      if (
        eventRecord.type === "task" &&
        bcPersist.jobs.find((x) => x.no === eventRecord.job)
      ) {
        items.addOpenTask = {
          text: "Add open task",
          icon: "b-fa b-fa-fw b-fa-clock",
          // weight: 110,
          onItem({ eventRecord }) {
            router.push({
              name: "Open Tasks",
              params: { jobNo: eventRecord.job },
            });
          },
        };

        items.details = {
          text: "Details",
          icon: "b-fa b-fa-fw b-fa-info",
          // weight: 110,
          onItem({ eventRecord }) {
            openPlanningLine.value = bcPersist.jobs.find(
              (x) => x.no === eventRecord.job
            ).systemId;
          },
        };
      }
    },
    items: {
      // Add extra items shown for each event
      split: {
        text: "Split event",
        icon: "b-fa b-fa-fw b-fa-cut",
        weight: 520,
        onItem({ eventRecord }) {
          eventRecord.split();
        },
      },
      copyEvent: {
        text: "Copy event",
        icon: "b-fa b-fa-fw b-fa-copy",
        weight: 110,
        onItem({ eventRecord, source }) {
          source.copyEvents([eventRecord]);
        },
      },

      // Edit a built-in item
      // editEvent: {
      //   text: "Update",
      // },

      // Hide a built-in item
      // deleteEvent: false,
    },
  };

  function initFields(type, milestone) {
    // console.log("initFields", type, milestone);
    const eventEdit = scheduler.value.instance.value.eventEdit;
    const eventRecord = eventEdit.eventRecord;

    if (type === "task") {
      eventEdit.nameField.hide();
      eventEdit.jobField.show();
      eventEdit.endDateField.show();
      eventEdit.endTimeField.show();

      // eventEdit.jobField.value = eventRecord.jobNo;

      setTimeout(() => {
        if (window.innerWidth >= 1024) {
          console.log("focus");
          eventEdit.jobField.focus();
        }
        if (eventEdit.jobField.value) {
          //close picker if a job is already selected
          eventEdit.jobField.hidePicker();
        }
      }, 200);
    } else if (type === "milestone") {
      eventEdit.nameField.show();
      eventEdit.jobField.hide();
      eventEdit.endDateField.hide();
      eventEdit.endTimeField.hide();

      setTimeout(() => {
        if (window.innerWidth >= 1024) {
          eventEdit.nameField.focus();
        }
      }, 200);
    } else {
      eventEdit.nameField.show();
      eventEdit.jobField.hide();
      eventEdit.endDateField.show();
      eventEdit.endTimeField.show();
      if (type === "leave" && eventEdit.nameField.value === "") {
        eventEdit.nameField.value = "Leave";
      }
      if (type === "recovery" && eventEdit.nameField.value === "") {
        eventEdit.nameField.value = "Overtime recovery";
      }
      if (type === "remote" && eventEdit.nameField.value === "") {
        eventEdit.nameField.value = "Work from home";
      }
      setTimeout(() => {
        if (window.innerWidth >= 1024) {
          eventEdit.nameField.focus();
        }
      }, 200);
    }
  }

  const listeners = {
    // beforeEventEdit: ({ eventRecord }) => {
    //   //we don't want to end a date on midnight because this will set the date to the next day, so we subtract an hour and set it back before saving
    //   // if (eventRecord.endDate.getHours() === 0) {
    //   //   console.log("old", eventRecord.endDate);
    //   //   eventRecord.endDate.setHours(eventRecord.endDate.getHours() - 1);
    //   //   console.log("new", eventRecord.endDate);
    //   // }
    //   // eventRecord.beginBatch();
    //   // eventRecord.startDate = convertToNearestHalfDay(eventRecord.startDate);
    //   // eventRecord.endDate = convertToNearestHalfDay(eventRecord.endDate);
    //   // eventRecord.endBatch();
    // },
    //
    eventEditbeforeSetRecord: (e) => {
      if (e?.record?.type === "task" && !e.record.data.job) {
        initFields("task");
      }
    },
    beforeEventSave: async ({ eventRecord, values }) => {
      const eventEdit = scheduler.value.instance.value.eventEdit;

      scheduler.value.instance.value.suspendRefresh();
      if (values.type === "milestone") {
        values.endDate = values.startDate;
        eventRecord.endDate = eventRecord.startDate;
      }

      if (!values.type && eventEdit.eventTypeField.value) {
        values.type = eventEdit.eventTypeField.value;
      }
      eventRecord.renderData = getRenderData(values);

      scheduler.value.instance.value.resumeRefresh(true);
      // console.log("--> values to save:", values);
    },
  };

  function getRenderData(event) {
    const renderData = {};
    renderData.eventStyle = "plain";
    switch (event.type.split(" ")[0]) {
      case "task":
        if (event.job) {
          const record = bcPersist.jobs.find((x) => x.no === event.job);
          if (record) {
            record.endingDate === "0001-01-01"
              ? (renderData.eventColor = "#188429")
              : (renderData.eventColor =
                  Date.parse(record.endingDate) + 86400000 > Date.now()
                    ? "#279EC9"
                    : "#ED1C24");
          } else {
            renderData.icon = "b-fa b-fa-check-to-slot";
            renderData.eventColor = "#238EB575";
          }
          renderData.name = record
            ? `${record.billToName}<br>${record.jobDescription}`
            : `${event.job}<br>Closed`;
        } else {
          renderData.name = "Select a job";
        }
        break;
      case "freeText":
        renderData.eventColor = "#FFAB30";
        break;
      case "leave":
        renderData.eventColor = "#A4BCB6";
        break;
      case "recovery":
        renderData.eventColor = "#A4BCB6";
        break;
      case "remote":
        renderData.eventStyle = "line";
        renderData.eventColor = "#757575";
        // renderData.textColor = "#5C5C5C";
        break;
      default:
        renderData.eventColor = "#279FC9";
        break;
    }
    if (!renderData.name) {
      renderData.name = event.name;
    }
    return renderData;
  }

  function setRelevantType() {
    const eventEdit = scheduler.value.instance.value.eventEdit;
    if (
      eventEdit.eventTypeField.value === "task" &&
      !eventEdit.jobField.isValid
    ) {
      if (eventEdit.jobField.lastQuery?.toLowerCase() === "leave") {
        eventEdit.eventTypeField.value = "leave";
      } else {
        eventEdit.eventTypeField.value = "freeText";
        eventEdit.nameField.value = eventEdit.jobField.lastQuery;
      }
    }
  }

  return { eventEditPanel, eventMenu, listeners, getRenderData };
};
