<template>
  <section
    class="table-project-list relative flex flex-1 flex-col overflow-hidden shadow-md sm:flex sm:rounded-md"
    style="background: #f8f9fa"
  >
    <div
      class="flex items-center justify-between border-b-1 border-gray-300 bg-gray-100 px-4 py-4 text-center sm:bg-white sm:px-6"
    >
      <h2
        class="hidden select-none text-left text-xl uppercase text-primary sm:flex"
      >
        Project li<span draggable="true" @dragstart="setDragData($event, 'meh')"
          >s</span
        >t
      </h2>

      <div
        class="text-md flex w-full items-center justify-between rounded-md border-1 border-gray-300 bg-white sm:w-auto sm:pr-2"
      >
        <input
          class="but-input w-36 border-0 bg-transparent py-1 text-sm text-gray-800 outline-0 focus:ring-0 sm:w-auto"
          :value="filterString"
          @input="setFilterString"
          ref="filterInput"
          type="search"
          placeholder="Filter"
        />

        <div class="but-toggle-holder flex items-center gap-1">
          <div class="mb-1 mr-2">Show last used only</div>
          <BryntumSlideToggle
            :checked="userPersistStore.showLastCommitted"
            @change="lastCommittedChange"
            color="b-orange"
          ></BryntumSlideToggle>
        </div>
      </div>
    </div>

    <DataTable
      :data="filteredProjects"
      :columns="columns"
      rowClass="sm:pr-x pr-14"
      :draggableRow="true"
      @onHeaderClicked="headerClicked"
      itemKey="systemId"
      class="overflow-y-auto"
      @onDblClick="emit('onAddClick', $event)"
    >
      <template #orderHeader="slotProps">
        <div class="flex items-center">
          {{ slotProps.column.label }}
          <ArrowUp
            class="ml-2 mt-1 w-5"
            v-if="slotProps.column.order === 'asc'"
          />
          <ArrowDown
            class="ml-2 mt-1 w-5"
            v-else-if="slotProps.column.order === 'desc'"
          />
        </div>
      </template>
      <template #project="slotProps">
        <div class="flex items-center gap-2">
          <div class="hidden h-5 w-5 text-bryntum sm:block" title="Favorite">
            <StarIcon
              class="cursor-pointer"
              :class="
                userPersistStore.userSettings.projectFavorites.includes(
                  slotProps.row.no
                )
                  ? 'group-hover:-600 text-bryntum'
                  : 'text-transparent group-hover:text-gray-400'
              "
              @click="toggleFavorite(slotProps.row.no)"
            />
          </div>
          <CompanyFlagIcon
            :company-guid="slotProps.row.companyGuid"
          ></CompanyFlagIcon>
          <div
            @click="copy(slotProps.row.no)"
            class="relative cursor-pointer whitespace-nowrap"
          >
            <span>{{ slotProps.row.no }}</span>
            <div
              v-if="copied && text === slotProps.row.no"
              class="absolute left-16 top-1/2 z-10 -translate-y-1/2 rounded-md bg-primary p-1 font-bold text-white"
            >
              Project Nr. Copied
            </div>
          </div>
          <div class="block h-5 w-5 text-bryntum sm:hidden" title="Favorite">
            <StarIcon
              class="cursor-pointer"
              :class="
                userPersistStore.userSettings.projectFavorites.includes(
                  slotProps.row.no
                )
                  ? 'group-hover:-600 text-bryntum'
                  : 'text-transparent group-hover:text-gray-400'
              "
              @click="toggleFavorite(slotProps.row.no)"
            />
          </div>
        </div>
      </template>
      <template #jobDescription="slotProps">
        <div v-html="replaceBcBreaks(slotProps.row.jobDescription)"></div>
      </template>
      <template #addButton="slotProps">
        <div class="w-auto">
          <button
            v-if="!slotProps.row.isAdding"
            @click="emit('onAddClick', slotProps.row)"
            class="but-btn-action but-btn-icon flex gap-1.5 text-center opacity-20 grayscale transition-all disabled:bg-gray hover:disabled:bg-gray group-hover/item:opacity-100 group-hover/item:grayscale-0"
            :disabled="slotProps.row.isAdding"
            title="Add to My Tasks List"
          >
            <PlusIcon class="block h-8 w-8 text-white sm:h-6 sm:w-6"></PlusIcon>
          </button>
          <button
            @click="emit('onAddClick', slotProps.row)"
            class="but-btn-action but-btn-icon flex gap-1.5 text-center opacity-60"
            :disabled="slotProps.row.isAdding"
            v-if="slotProps.row.isAdding"
            title="Add to My Tasks List"
          >
            <Spinner class="hidden sm:block" :size="24" color="white" />
          </button>
        </div>
      </template>
      <template #planningLine="slotProps">
        <InformationCircleIcon
          class="my-auto hidden h-5 w-5 flex-shrink-0 cursor-pointer text-gray-500 group-hover:text-gray-500 sm:block sm:text-transparent"
          aria-hidden="true"
          title="Show details"
          @click="openPlanningLine = slotProps.row.systemId"
        />
      </template>
    </DataTable>

    <PlanningLinePopup
      v-if="openPlanningLine"
      :project="
        useBcPersistStore().jobs.find((x) => x.systemId === openPlanningLine)
      "
      @onClose="openPlanningLine = null"
    />
    <!--    <div class="cursor-pointer" @click="foo">TEST</div>-->
  </section>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import DataTable from "@/components/DataTable.vue";
import Spinner from "@/components/Spinner.vue";
import CompanyFlagIcon from "@/components/CompanyFlagIcon.vue";
import { useUserPersistStore } from "@/stores/userPersist.js";
import {
  PlusIcon,
  InformationCircleIcon,
  StarIcon,
} from "@heroicons/vue/solid";
import { useBcPersistStore } from "@/stores/bcPersist.js";
import { replaceBcBreaks, debounce } from "@/utils/Utils.js";
import { BryntumSlideToggle } from "@bryntum/scheduler-vue-3";
import ArrowDown from "@/components/icons/ArrowDown.vue";
import ArrowUp from "@/components/icons/ArrowUp.vue";
import { useClipboard } from "@vueuse/core";

const props = defineProps({
  projects: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["onAddClick"]);
const userPersistStore = useUserPersistStore();
const bcPersistStore = useBcPersistStore();

const { copy, copied, text } = useClipboard();

const openPlanningLine = ref(null);
const filterString = ref("");
let noResultString = "";

function setFilterString(e) {
  filterString.value = e.target.value;
  debouncedFilterSet(filterString.value);
}

const debouncedFilterSet = debounce((value) => {
  userPersistStore.filterString = value;
}, 500);

const lastCommittedProjects = ref([]);

function lastCommittedChange(e) {
  userPersistStore.showLastCommitted = e.value;
  if (e.value) {
    fetchLastCommittedProjects();
  }
}

let committedFetched = false;

function fetchLastCommittedProjects() {
  let now = new Date();
  const lowerDate = new Date(now.setDate(now.getDate() - 30))
    .toISOString()
    .substring(0, 10);
  const upperDate = new Date().toISOString().substring(0, 10);
  // console.log(lowerDate, upperDate);

  bcPersistStore
    .getDynamicsDataForAllCompanies({
      urlSegment: `timeRegistrationEntries?$filter=resourceNo eq '${userPersistStore?.linkedResource?.no}' and date ge ${lowerDate} and date le ${upperDate} &$select=jobId &orderby=date`,
    })
    .then((r) => {
      committedFetched = true;
      lastCommittedProjects.value = [...new Set(r.map((x) => x.jobId))];
    });
}

function findObjectWithStrings(object, searchArray) {
  let isMatch = true;
  for (let j = 0; j < searchArray.length; j++) {
    const searchString = searchArray[j];
    let hasString = false;

    for (let key in object) {
      if (object.hasOwnProperty(key) && typeof object[key] === "string") {
        if (object[key].toLowerCase().includes(searchString.toLowerCase())) {
          hasString = true;
          break;
        }
      }
    }

    if (!hasString) {
      isMatch = false;
      break;
    }
  }

  return isMatch;
}

const visibleColumns = computed(() => {
  return columns.value.map((column) => column.field).filter((x) => x !== "");
});

const filteredProjects = computed(() => {
  let res = props.projects.filter((x) =>
    userPersistStore.showLastCommitted
      ? x.status === "Open" && lastCommittedProjects.value.includes(x.systemId)
      : x.status === "Open"
  );

  if (
    userPersistStore.filterString &&
    userPersistStore.filterString.length > 0
  ) {
    const searchWords = userPersistStore.filterString.toUpperCase().split(" ");

    res = res.filter((x) => {
      const filtered = Object.keys(x)
        .filter((key) => visibleColumns.value.includes(key))
        .reduce((obj, key) => {
          obj[key] = x[key];
          return obj;
        }, {});
      return (
        findObjectWithStrings(filtered, searchWords) ||
        userPersistStore.userSettings.projectFavorites.includes(x.no)
      );
    });
  }

  if (userPersistStore.sortingFields.length > 0) {
    res.sort(compareValues(userPersistStore.sortingFields));
  } else {
    res.sort(
      compareValues([
        { field: "startingDate", order: "desc" },
        { field: "no", order: "asc" },
      ])
    );
  }
  // console.log(
  //   "res:",
  //   res,
  //   " - projectFavorites.length:",
  //   userPersistStore.userSettings.projectFavorites,
  //   " - noResultString:",
  //   noResultString
  // );
  //if the only fetched projects are the favorites, disable showLastCommitted
  if (
    userPersistStore.showLastCommitted &&
    res.every((project) =>
      userPersistStore.userSettings.projectFavorites.includes(project.no)
    )
  ) {
    console.log("only favorites");
    //disable showLastCommitted
    userPersistStore.showLastCommitted = false;
    fetchLastCommittedProjects();
  }
  if (
    res.length === userPersistStore.userSettings.projectFavorites.length &&
    noResultString !== userPersistStore.filterString
  ) {
    console.log("NO matches");
    fetchLastCommittedProjects();
    noResultString = userPersistStore.filterString;
  }
  return res;
});

const columns = ref([
  {
    field: "no",
    label: "Nr.",
    slotName: "project",
    class: "pb-0 pl-2 sm:pb-2 sm:pl-8 sm:left-auto mt-1 sm:mt-0 pr-2",
    headerClass: "table-head min-w-20",
    headerSlot: "orderHeader",
    order: "",
    orderField: true,
  },
  {
    field: "billToName",
    label: "Bill To",
    class:
      "py-0 pl-4 sm:py-2 flex-grow w-full sm:flex-grow-0 sm:w-1/3 sm:left-auto mt-1 sm:mt-0",
    headerClass: "table-head w-1/3",
    headerSlot: "orderHeader",
    order: "",
    orderField: true,
  },
  {
    field: "jobDescription",
    label: "Job Description",
    slotName: "jobDescription",
    class:
      "pt-0 pl-4 sm:py-2 sm:w-full sm:left-auto mb-1 sm:mb-0 mt-1 sm:mt-0 text-gray-600 sm:text-gray-900",
    headerClass: "table-head sm:w-full",
    headerSlot: "orderHeader",
    order: "",
    orderField: true,
  },
  // { field: "status", label: "Status" },
  {
    slotName: "addButton",
    field: "",
    label: "",
    class:
      "absolute sm:static right-2 top-1/2 sm:transform-none -translate-y-1/2 h-auto z-20",
  },
  {
    slotName: "planningLine",
    field: "",
    label: "",
    class: "hidden sm:table-cell",
  },
]);

setOrderInitially();

function setOrderInitially() {
  for (let i = 0; i < userPersistStore.sortingFields.length; i++) {
    columns.value.find(
      (x) => x.field === userPersistStore.sortingFields[i].field
    ).order = userPersistStore.sortingFields[i].order;
  }
}

function headerClicked(clickedField) {
  let sortingField = userPersistStore.sortingFields.find(
    (x) => x.field === clickedField.field
  );
  if (!sortingField) {
    userPersistStore.sortingFields.push({
      field: clickedField.field,
      order: "asc",
    });
    clickedField.order = "asc";
  } else {
    switch (sortingField.order) {
      case "":
        sortingField.order = "asc";
        clickedField.order = "asc";
        break;
      case "asc":
        sortingField.order = "desc";
        clickedField.order = "desc";
        break;
      case "desc":
        userPersistStore.sortingFields = userPersistStore.sortingFields.filter(
          (x) => x.field !== clickedField.field
        );
        clickedField.order = "";
        break;
    }
  }
}

function compareValues(keys) {
  return function innerSort(a, b) {
    if (keys.length <= 0) {
      return 0;
    }
    const varA =
      typeof a[keys[0].field] === "string"
        ? a[keys[0].field].toUpperCase()
        : a[keys[0].field];
    const varB =
      typeof b[keys[0].field] === "string"
        ? b[keys[0].field].toUpperCase()
        : b[keys[0].field];
    const favA = userPersistStore.userSettings.projectFavorites.indexOf(a.no);
    const favB = userPersistStore.userSettings.projectFavorites.indexOf(b.no);

    let comparison = 0;

    if (favA < favB) {
      if (favA === -1) {
        comparison = keys[0].order === "desc" ? -1 : 1;
      } else {
        comparison = keys[0].order === "desc" ? 1 : -1;
      }
    } else if (favA > favB) {
      if (favB === -1) {
        comparison = keys[0].order === "desc" ? 1 : -1;
      } else {
        comparison = keys[0].order === "desc" ? -1 : 1;
      }
    }

    if (varA > varB && comparison === 0) {
      comparison = 1;
    } else if (varA < varB && comparison === 0) {
      comparison = -1;
    } else if (keys.length > 1 && comparison === 0) {
      comparison = compareValues(keys.slice(1))(a, b);
    }
    return keys[0].order === "desc" ? comparison * -1 : comparison;
  };
}

const filterInput = ref(null);

function toggleFavorite(project) {
  if (userPersistStore.userSettings.projectFavorites.includes(project)) {
    const index =
      userPersistStore.userSettings?.projectFavorites?.indexOf(project);
    if (index > -1) {
      userPersistStore.userSettings?.projectFavorites?.splice(index, 1);
    }
  } else {
    userPersistStore.userSettings?.projectFavorites?.push(project);
  }
  bcPersistStore.putResourceSettings({
    projectFavorites: userPersistStore.userSettings.projectFavorites,
  });
}

function setDragData(e, id) {
  e.dataTransfer.clearData();
  e.dataTransfer.setData("text/plain", JSON.stringify(id));
}

onMounted(() => {
  if (!committedFetched) {
    fetchLastCommittedProjects();
  }
  filterString.value = userPersistStore.filterString;
  if (window.innerWidth >= 1024) {
    filterInput.value.focus();
  }
});
</script>

<style>
.but-toggle-holder .b-slidetoggle input:checked + .b-slidetoggle-toggle {
  @apply bg-primary-500;
}
</style>
