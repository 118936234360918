<template>
  <input
    :value="modelValue"
    @input="onChange($event.target.value)"
    @focus="selectAllOnZero($event.target)"
    type="number"
    class="but-input-inner !pr-1"
  />
</template>

<script setup>
import { debounce } from "@/utils/Utils.js";

defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue"]);

function onChange(value) {
  // console.log("onchange", value);
  value = parseFloat(value);
  debouncedEmit(value);
}

function selectAllOnZero(e) {
  if (e.value === "0") {
    e.select();
  }
}

const debouncedEmit = debounce((value) => {
  // console.log("debouncedEmit", value);
  emits("update:modelValue", value);
}, 1000);
</script>
