<template>
  <div
    v-if="waitASecondDone && idleStore.userIdle && bcPersistStore?.activeTask"
    class="idle-overlay absolute left-0 top-0 z-idle-overlay flex h-full w-full bg-gray-transparent">
    <div
      class="m-auto flex w-11/12 flex-col items-center rounded-xl bg-white px-8 pb-6 pt-8 text-xl lg:w-128 lg:px-14 lg:pb-6 lg:pt-12">
      <span class="font-medium"
        >You were away for
        {{ secondsToFormattedTime(idleStore.idleTime) }}</span
      >
      <span class="my-3 text-xs font-thin text-gray-600"
        >Last activity:
        {{ secondsToFormattedDateTime(idleStore.idleStart) }}</span
      >

      <span class="mt-4 text-sm font-medium"
        >What should be done with the time you were away?</span
      >

      <BryntumCombo
        :items="dropdownItems"
        placeholder="Tasks"
        :editable="false"
        :pickerWidth="Math.min(600, theWindow.innerWidth)"
        displayField="displayValue"
        valueField="id"
        @change="idleStore.selectedIdleTask = $event.value"
        :value="bcPersistStore.activeTask.id"
        cls="mt-2 ml-0 w-full font-bold mb-3 z-idle-overlay"
        listCls="dropdown-override---" />

      <div v-if="!idleStore.idleTimeSaving" class="my-3 flex">
        <button
          class="btn btn-popup mx-1 bg-primary text-white"
          @click="idleStore.keepIdleTime">
          Keep
        </button>
        <button
          class="btn btn-popup mx-1 bg-primary text-white"
          @click="idleStore.removeIdleTime">
          Remove
        </button>
      </div>
      <div v-else class="my-3 flex items-center gap-4">
        <Spinner :size="25" color="black" />
        <span>Saving ...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useIdleStore } from "@/stores/idle";
import { ref, computed } from "vue";
import {
  secondsToFormattedTime,
  secondsToFormattedDateTime,
} from "@/utils/Utils.js";
import { NULL_DATE, useBcPersistStore } from "@/stores/bcPersist";
import { BryntumCombo } from "@bryntum/scheduler-vue-3";

const idleStore = useIdleStore();
const bcPersistStore = useBcPersistStore();
const theWindow = window;

//we need the below to make sure the tasks are already injected with project names and bill to names
const waitASecondDone = ref(false);
setTimeout(() => {
  waitASecondDone.value = true;
}, 2000);

const dropdownItems = computed(() => {
  const today = new Date().toISOString().slice(0, 10);
  const items = idleStore.tasksWithDisplayValue
    .slice(0)
    .filter((x) => x.date === today || x.timeSheetBeginTime !== NULL_DATE);
  console.log("items", items);
  items.push({ displayValue: "Add New Task", id: "NEW_TASK" });
  return items;
});
</script>

<style scoped>
.btn-popup {
  min-width: 150px;
}
</style>
