<template>
  <div
    class="absolute left-0 top-0 z-permission-overlay flex h-full w-full bg-gray-transparent"
  >
    <div
      class="fon m-auto flex flex-col items-center rounded-2xl bg-white p-14"
    >
      <slot />
    </div>
  </div>
</template>

<script setup></script>

<style scoped></style>
