import { DomHelper, Toast } from "@bryntum/scheduler";
import { v4 as uuidv4 } from "uuid";
import { useBcPersistStore } from "@/stores/bcPersist.js";
import { useSchedulerEdit } from "@/composables/schedulerEdit.js";
import { useUserPersistStore } from "@/stores/userPersist.js";
import { useIdleStore } from "@/stores/idle.js";
import { bcEnvironment } from "@/../version.json";
import { useUserStore } from "@/stores/user";

/**
 * WebSocketHelper class to support scheduler data sending and receiving via WebSocket.
 * Subscribes to Scheduler events and sends them via WebSocket to server.
 * Receives messages from server and updates scheduler events and resources based on received data.
 */
export default class WebSocketHelper {
  //region Constructor

  /**
   * Constructs WebSocketHelper class for scheduler instance
   * @param userName
   */
  constructor(userName) {
    if (WebSocketHelper._instance) {
      return WebSocketHelper._instance;
    }
    WebSocketHelper._instance = this;

    this.bcPersistStore = useBcPersistStore();
    this.userPersistStore = useUserPersistStore();
    this.idleStore = useIdleStore();
    this.userStore = useUserStore();

    this._ignoreChange = false;
    this._protocol = window.location.protocol === "https" ? "wss://" : "ws://";

    // LIVE: "wss://websocket.timesheet.butspace.com"
    // DEV: "wss://dev.websocket.timesheet.butspace.com"
    // LOCAL: "ws://172.25.2.149:3003"

    this._host =
      bcEnvironment === "LIVE"
        ? `wss://timesheet-websocket.azurewebsites.net`
        : // ? `wss://websocket.timesheet.butspace.com`
          `ws://localhost:3003`;
    //  `wss://timesheet-websocket.azurewebsites.net`;
    this._userNameGeneric = userName;
    this._userName = userName + "__" + uuidv4();
    this._debug = false;
    this._scheduler = null;
    this._multipleInstances = false;
    this._individualCommands = [
      "updateHearthBeat",
      "addTask",
      "updateTask",
      "deleteTask",
      "keepIdleTime",
      "removeIdleTime",
      "updateStoredIdleTime",
      "changeOrder",
      "updateUserSettings",
    ];

    this.setConnectedState(false);
    if (bcEnvironment === "LIVE") {
      this.openWebsocket();
    }
  }

  //endregion

  //region Getters and setters

  /**
   * WebSocket server host name and port
   * @returns {string} host name and port
   */
  get host() {
    return this._host;
  }

  set host(value) {
    this._host = value;
  }

  /**
   * WebSocket user name
   * @returns {String} user name
   */
  get userName() {
    return this._userName;
  }

  set userName(value) {
    this._userName = value;
  }

  get scheduler() {
    return this._scheduler;
  }

  set scheduler(value) {
    this._scheduler = value;
  }

  /**
   * WebSocket state
   * @returns {string} WebSocket state
   */
  get state() {
    return this._socket ? this._socket.readyState : null;
  }

  //endregion

  //region WebSocket methods

  /**
   * Send a command to the server
   * @param {Object} data Accepts an object that will be transmitted as a JSON string
   */
  wsSend(data) {
    const socket = this._socket;
    //Only send individual event when multiple instances available
    if (
      this._individualCommands.includes(data.command) &&
      this._multipleInstances !== true
    ) {
      return;
    }
    if (socket && socket.readyState === WebSocket.OPEN) {
      if (!data.userName) {
        data.userName = this.userName;
      }
      const json = JSON.stringify(data);
      this.debugLog(`>>> ${json}`);
      socket.send(json);
      // // For debug and testing purposes
      // if (this._scheduler) {
      //   this._scheduler.trigger("wsSend", { data });
      // }
    }
  }

  /**
   * Processes received data
   * @data {Object} data JSON data object
   */
  async wsReceive(data) {
    const me = this;

    if (data.command !== "dragEvent") {
      console.log("WSReceive", data);
    }

    const scheduler = me._scheduler?.value?.instance?.value;
    const eventRecord = data.id
      ? scheduler?.eventStore?.getById(data.id)
      : null;

    let readOnly = true;

    if (me.userPersistStore.actualResource) {
      readOnly = !(
        me.userPersistStore.linkedResource.timeSheetUserType.toLowerCase() ===
          "admin" ||
        (data.resourceId === me.userPersistStore.linkedResource.no &&
          data.record?.type === "remote") ||
        eventRecord?.type === "remote"
      );
    } else {
      console.log("No actual resource found");
    }

    if (data.record) {
      data.record.readOnly = readOnly;
      data.record.draggable = !readOnly;
      data.record.resizable = !readOnly;
      console.log(data.record);
    }

    switch (data.command) {
      // User has connected to the server
      case "hello":
        console.log(`${data.userName} just connected`);
        break;

      // User has disconnected from the server
      case "bye":
        console.log(`${data.userName} disconnected`);
        break;

      case "users":
        if (
          data.users.filter((x) => x?.includes(me._userNameGeneric)).length > 1
        ) {
          me._multipleInstances = true;
        } else {
          me._multipleInstances = false;
        }
        //WebSocketHelper.showOnlineUsers(data.users);
        break;

      // Updating an event (the record), reflect changes
      case "updateEvent":
        if (scheduler && eventRecord) {
          // Allow dragging & resizing that was disabled by other user performing some operation

          Object.keys(data.changes).forEach((key) => {
            if (key.endsWith("Date")) {
              data.changes[key] = new Date(data.changes[key]);
            }
          });

          scheduler.eventStore.beginBatch();
          Object.assign(eventRecord, data.changes);
          eventRecord.readOnly = readOnly;
          eventRecord.draggable = !readOnly;
          eventRecord.resizable = !readOnly;
          await scheduler.eventStore.project.commitAsync();
          eventRecord.clearChanges(true, false);
          scheduler.eventStore.endBatch(true);
        }
        break;

      // Removing an event
      case "removeEvent":
        if (scheduler) {
          scheduler.suspendRefresh();
          scheduler.eventStore.beginBatch();
          console.log("idToDelete", data.record.id);
          scheduler.eventStore.remove([data.record.id]);
          await scheduler.eventStore.project.commitAsync();
          scheduler.eventStore.removed.remove(data.record.id);
          scheduler.eventStore.endBatch(true);
          scheduler.resumeRefresh(true);
        }
        break;

      // Adding an event
      case "addEvent":
        if (scheduler && !eventRecord) {
          const eventToSave = {
            eventId: data.record.id.slice(-36),
            resourceNo: data.resourceId,
            name: data.record.name,
            startDate: data.record.startDate,
            endDate: data.record.endDate,
            type: data.record.type,
            jobNo: data.record.job,
            scheduleRemark: data.record.scheduleRemark,
            json: data.record.json,
          };

          let eventToAdd = {
            id: data.record.id,
            startDate: new Date(data.record.startDate),
            endDate: new Date(data.record.endDate),
            name: data.record.name,
            resourceId: data.resourceId,
            type: data.record.type,
            job: data.record.job,
            scheduleRemark: data.record.scheduleRemark,
            json: data.record.json,
            renderData: useSchedulerEdit().getRenderData(data.record),
            readOnly:
              this.userPersistStore.linkedResource.timeSheetUserType.toLowerCase() !==
                "admin" &&
              (data.resourceId !== this.userPersistStore.actualResource.no ||
                data.record.type !== "remote"),
          };

          this.bcPersistStore.scheduleEvents.push(eventToSave);

          scheduler.suspendRefresh();
          scheduler.eventStore.beginBatch();
          scheduler.eventStore.add(eventToAdd);
          await scheduler.eventStore.project.commitAsync();
          scheduler.eventStore.added.remove(data.record.id);
          scheduler.eventStore
            .getById(eventToAdd.id)
            ?.clearChanges(true, false);
          scheduler.resumeRefresh(true);
          scheduler.eventStore.endBatch();
        }
        break;

      // Dragging or resizing, move the local element to match ongoing operation
      case "dragEvent":
      case "resizeEvent": {
        if (scheduler && eventRecord) {
          const element = scheduler.getElementFromEventRecord(eventRecord),
            startDate = new Date(data.startDate),
            startX = scheduler.getCoordinateFromDate(startDate);

          if (!element) {
            break;
          }

          element.dataset.userName = data.userName;

          // Prevent dragging & resizing while other user is performing an action on the event
          scheduler.eventStore.beginBatch();
          // eventRecord.draggable = false;
          // eventRecord.resizeable = false;

          // eventRecord.readOnly = readOnly;

          await scheduler.eventStore.project.commitAsync();
          scheduler.eventStore.endBatch();

          if (element) {
            // Dragging, match position
            if (data.command === "dragEvent") {
              element.classList.add("b-remote-drag");
              DomHelper.setTopLeft(
                element.parentElement,
                data.newY * scheduler.rowHeight,
                startX
              );
            }

            // Resizing, match position + size
            if (data.command === "resizeEvent") {
              element.classList.add(`b-remote-resize-${data.edge}`);

              const endDate = new Date(data.endDate),
                endX = scheduler.getCoordinateFromDate(endDate);

              DomHelper.setTranslateX(element.parentElement, startX);
              element.parentElement.style.width = `${Math.abs(
                endX - startX
              )}px`;
            }
          }
        }
        break;
      }

      case "addTask":
        {
          // Toast.show(`${data.command}:  ${data.id}`);
          await this.bcPersistStore.getDynamicsData({
            companyGuid: data.companyGuid,
            urlSegment: `timeRegistrationEntries?$filter=id eq ${data.id}`,
            stateName: "myTasks",
            merge: true,
            mergeFieldId: "id",
            mergeWithExistingRows: true,
          });
          const task = this.bcPersistStore.myTasks.find(
            (t) => t.id === data.id
          );
          if (task) {
            if (
              !this.bcPersistStore.jobs.find((i) => i.systemId === task.jobId)
            ) {
              await this.bcPersistStore.getDynamicsData({
                urlSegment: `jobs?$filter=systemId eq ${task.jobId}`,
                stateName: "jobs",
                merge: true,
                mergeWithExistingRows: true,
                companyGuid: task.companyGuid,
                replaceStore: false,
                caseSensitive: false,
              });
            }
            this.bcPersistStore.injectMissingTaskFields(task);
            this.bcPersistStore.validateTaskFields(task);
            await this.bcPersistStore.getResourceSettings();
          }
        }
        break;

      case "updateTask":
        // Toast.show(`${data.command}:  ${data.id}`);
        const tasks = await this.bcPersistStore.getDynamicsData({
          companyGuid: data.companyGuid,
          urlSegment: `timeRegistrationEntries?$filter=id eq ${data.id}`,
          stateName: "myTasks",
          merge: true,
          mergeFieldId: "id",
          mergeWithExistingRows: true,
        });
        const task = this.bcPersistStore.myTasks.find((t) => t.id === data.id);
        if (task) {
          if (!task.notQuoted) {
            const myJob = this.bcPersistStore?.jobs.find(
              (j) => j.systemId === task.jobId
            );
            if (
              !myJob?.jobPlanningLines?.find(
                (pl) =>
                  pl.jobTaskNo === task.jobTaskNo &&
                  pl.lineNo === task.jobPlanningLineLineNo
              )
            ) {
              await this.bcPersistStore.getDynamicsData({
                urlSegment: `jobs?$filter=systemId eq ${task.jobId} & $expand=jobPlanningLines,jobTasks`,
                stateName: "jobs",
                merge: true,
                mergeWithExistingRows: true,
                companyGuid: task.companyGuid,
                sortOnFields: ["billToName", "jobDescription"],
                replaceStore: false,
                caseSensitive: false,
              });
            }
          }

          this.bcPersistStore.injectMissingTaskFields(task);
          this.bcPersistStore.validateTaskFields(task);
        }

        break;

      case "deleteTask":
        {
          // Toast.show(`${data.command}:  ${data.id}`);
          const index = this.bcPersistStore.myTasks.findIndex(
            (t) => t.id === data.id
          );
          if (index > -1) {
            this.bcPersistStore.myTasks.splice(index, 1);
          }
        }
        break;

      case "keepIdleTime":
        {
          if (this.idleStore.idleNotification) {
            this.idleStore.idleNotification.close();
          }
          clearTimeout(this.idleStore.timeInterval);
          this.idleStore.userIdle = false;
          this.idleStore.idleStart = 0;
        }
        break;
      case "removeIdleTime":
        {
          // Toast.show(`${data.command}:  ${data.id}`);
          await this.bcPersistStore.getDynamicsData({
            companyGuid: data.companyGuid,
            urlSegment: `timeRegistrationEntries?$filter=id eq ${data.id}`,
            stateName: "myTasks",
            merge: true,
            mergeFieldId: "id",
            mergeWithExistingRows: true,
          });
          const task = this.bcPersistStore.myTasks.find(
            (t) => t.id === data.id
          );
          if (task) {
            this.bcPersistStore.injectMissingTaskFields(task);
            this.bcPersistStore.validateTaskFields(task);
          }
          if (this.idleStore.idleNotification) {
            this.idleStore.idleNotification.close();
          }
          clearTimeout(this.idleStore.timeInterval);
          this.idleStore.userIdle = false;
          this.idleStore.idleStart = 0;
        }
        break;

      case "updateStoredIdleTime":
        {
          this.userPersistStore.userSettings.storedIdleTime = data.idleTime;
        }
        break;

      case "changeOrder":
        // Toast.show(`${data.command}:  ${data.id}`);
        if (data.order) {
          this.userPersistStore.userSettings.taskOrder = data.order;
        }

        break;

      case "updateHearthBeat":
        {
          this.userPersistStore.userSettings.lastActivity = data.lastActivity;
        }
        break;

      case "updateUserSettings":
        {
          this.userPersistStore.userSettings = {
            ...this.userPersistStore.userSettings,
            ...data.changes,
          };
        }
        break;

      default:
        me.debugLog(`Unhandled message command ${data.command}`);
    }
  }

  /**
   * Connect to the server and start listening for messages
   */
  openWebsocket() {
    const me = this;

    if (!me._host || me._host.trim() === "") {
      Toast.show(`Server address can not be empty`);
      return;
    }

    if (!me._userName || me.userName.trim() === "") {
      Toast.show(`User name can not be empty`);
      return;
    }

    const wsHost =
      (/^wss?:\/\//i.test(me._host) ? "" : me._protocol) + me._host;

    // Toast.show(
    //   `<div style="text-align: center">Connecting to<br>${wsHost}</div>`
    // );

    const socket = (me._socket = new WebSocket(wsHost));

    socket.onerror = (e) => {
      console.log("Error connecting to socket server: ", e);
      // Toast.show("Error connecting to socket server");
    };

    // Called when socket is established
    socket.onopen = () => {
      // Toast.show(`Connected to server`);
      me.setConnectedState(true);

      // User login to server
      // Toast.show("Connecting ...");
      me.wsSend({ command: "hello", userName: me._userName });

      // Toast.show("Requesting data ...");
      // me.wsSend({ command: "dataset" });
    };

    socket.onclose = (e) => {
      console.log("closing", e);
      setTimeout(() => {
        this.openWebsocket();
      }, 30000);
      // Toast.show(`Disconnected from socket server`);
      me.setConnectedState(false);
    };

    // Called when a message is received from the server
    socket.onmessage = async (msg) => {
      me._ignoreChange = true;
      try {
        me.wsReceive(JSON.parse(msg.data));

        // await me._scheduler.project.commitAsync();
      } finally {
        me._ignoreChange = false;
      }
    };
  }

  /**
   * Close socket and disconnect from the server
   */
  wsClose() {
    const scheduler = this._scheduler,
      socket = this._socket;

    if (socket) {
      socket.close();
    }
    if (scheduler) {
      scheduler.events = [];
      scheduler.resources = [];
    }
  }

  //endregion

  //region Helper functions

  /**
   * Decode params from window search string.
   */

  getUrlParams() {
    const params = {},
      pairs = window.location.search.substring(1).split("&");

    pairs.forEach((pair) => {
      if (pair !== "") {
        const [key, value = true] = pair.split("=", 2);
        params[key] = value;
      }
    });

    return params;
  }

  /**
   * Get random value from array
   * @param array input values
   * @returns {*} random array value
   */
  static random(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  /**
   * Output console debug log
   * @param txt log text
   */
  debugLog(txt) {
    if (this._debug) {
      console.log(txt);
    }
  }

  /**
   * Sets visibility for elements with the specified css class
   * @param {String} cls css class name
   * @param {Boolean} visible flag
   */
  setVisibility(cls, visible) {
    DomHelper.forEachSelector(document.body, cls, (element) => {
      element.style.display = visible ? "flex" : "none";
    });
  }

  /**
   * Sets visual state for login / logout controls
   * @param {Boolean} connected Connected status
   */
  setConnectedState(connected) {
    this.userStore.wsConnected = connected;
    // const scheduler = this._scheduler;
    // this.setVisibility(".b-login", !connected);
    // this.setVisibility(".b-logout", connected);
    // WebSocketHelper.clearOnlineUsers();
    // if (!connected) {
    //   scheduler.events = [];
    //   Toast.show('<div style="text-align: center">OFFLINE</div>');
    // }
  }

  /**
   * Clears online users
   */
  static clearOnlineUsers() {
    DomHelper.removeEachSelector(document, ".ws-online-user");
  }

  /**
   * Shows online users
   */
  static showOnlineUsers(users) {
    //WebSocketHelper.clearOnlineUsers();
    // console.log("******** Users: ********");
    console.table(users);
    // for (const user of users) {
    //   console.log("user", user);
    // }
  }

  //endregion
}
