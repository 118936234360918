<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100"
      >
        {{
          modelValue
            ? getLabelText(modelValue) || noSelectionLabel
            : noSelectionLabel
        }}
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div
          class="overflow-y-auto py-1"
          style="max-height: calc(100vh - 18rem)"
        >
          <MenuItem v-slot="{ active }" v-for="item in items">
            <button
              @click="updateValue(item)"
              :class="[
                item === modelValue ? 'bg-gray-200 font-bold' : '',
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full px-4 py-2 text-left ',
              ]"
            >
              {{ item ? getLabelText(item) : "" }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";

const props = defineProps({
  items: { type: Array, required: true },
  labelAttributes: { type: Array, default: ["label"] },
  noSelectionLabel: { type: String, default: "No selection made" },
  modelValue: { required: true, default: null },
});

function getLabelText(item) {
  let results = [];
  props.labelAttributes.forEach((labelId) => {
    results.push(item[labelId]);
  });
  return results.length > 0 ? results.join(", ") : "";
}

const emit = defineEmits(["update:modelValue"]);

function updateValue(item) {
  emit("update:modelValue", item);
}
</script>
